<!-- Modal Background -->
<div class="fixed inset-0 w-full h-full overflow-y-auto bg-gray-600 bg-opacity-50 z-50" id="my-modal">
  <!-- Modal Content -->
  <div class="relative w-full p-5 mx-auto bg-white border rounded-md shadow-lg top-10 md:w-1/3">
      <div class="flex justify-end">
        <img class="w-6 h-6 cursor-pointer" title="{{'CANCEL' | translate}}" (click)="close()" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
      </div>
      <div class="mt-3">
          <div class="text-xl font-bold text-center text-gray-900 underline">{{modalTitle}}</div>
          <!-- Modal Body -->
          <div class="flex flex-col p-2 mt-4">
            <div class="flex flex-col w-full mt-2">
              <div class="mb-4">
                  <label class="mb-1 font-bold">{{"AMOUNT_ON_DEPARTMENT" | translate}}</label><br>
                  <input title="{{'AMOUNT_ON_DEPARTMENT' | translate}}" class="w-full p-2 border border-gray-300" id="inputEditProductAmount" [(ngModel)]="result.product.amount" (ngModelChange)="result.product.amount=$event" type="text">
              </div>
              <div class="mb-4">
                <label class="mb-1 font-bold">{{"ANNUAL_CONSUMPTION" | translate}}</label><br>
                <input title="{{'ANNUAL_CONSUMPTION' | translate}}" class="w-full p-2 border border-gray-300" id="inputEditProductAnnual" [(ngModel)]="result.product.annual_consumption" (ngModelChange)="result.product.annual_consumption=$event" type="text">
              </div>
              <div class="mb-4">
                <label class="mb-1 font-bold">{{"MAXIMUM_AMOUNT" | translate}}</label><br>
                <input title="{{'MAXIMUM_AMOUNT' | translate}}" class="w-full p-2 border border-gray-300" id="inputEditProductMaxium" [(ngModel)]="result.product.maximum_amount" (ngModelChange)="result.product.maximum_amount=$event" type="text">
              </div>
              <div class="mb-2">
                <label class="mt-4 mb-1 font-bold">{{"UNIT" | translate}}</label>
                <div class="relative z-40 inline-block w-full text-left bg-white" id="selectRisk" appDropdown>
                  <button type="button" class="flex items-start justify-between w-full p-2 mb-4 border border-gray-400 rounded-md text-black-900">
                      <span>{{product["unit"]}}</span>
                      <svg class="w-7 h-7" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                  </button>
                  <div id="dropdownMenu" class="hidden dropdown-menu" #dropdownMenuInside>
                      <div class="py5" *ngFor="let unit of units" data-value="unit.name" class="dropdown-menu-item"
                          (click)="setUnit(unit)" id="{{ 'inputEditProductUnit' + unit.name }}" tabindex="1">{{ unit.name }} </div>
                  </div>
                </div>
              </div>
              <div class="mb-2">
                <label class="mt-4 mb-1 font-bold">{{"PRODUCT_GROUP" | translate}}</label>
                <div class="flex flex-row">
                  <div class="relative z-20 inline-block w-full text-left" id="selectRisk" appDropdown>
                    <button type="button" class="flex items-start justify-between w-full p-2 mb-4 border border-gray-400 rounded-md text-black-900">
                        <span>{{product.group_name}}</span>
                        <svg class="w-7 h-7" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                    <div id="dropdownMenu" class="hidden dropdown-menu" #dropdownMenuInside>
                        <div class="py5" *ngFor="let group of groups" data-value="group.name" class="dropdown-menu-item"
                            (click)="setGroup(group)" id="{{ 'inputEditProductGroup' + group.name }}" tabindex="1">{{ group.name }} </div>
                    </div>
                  </div>
                  <button title="Add group" (click)="addGroup()" class="mb-4 button-green">
                    <svg class="cursor-pointer h-7 w-7" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"/>
                        <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
                    </svg>
                  </button>
                </div>
              </div>
              <div *ngIf="subscribesToFootprint" class="mb-2">
                <label class="mb-1 font-bold">{{"FOOTPRINT_TO" | translate}}</label><br>
                <div class="flex flex-row">
                  <div class="flex items-center mt-2 mr-4 space-x-2">
                    <span class="mr-1 text-sm">{{"WATER" | translate}}</span>
                    <input title="{{'WATER' | translate}}" type="checkbox" id="inputEditProductFootprintWater" name="o_footprint_water" [(ngModel)]="product.o_footprint_water" (ngModelChange)="product.o_footprint_water=$event">
                  </div>
                  <div class="flex items-center mt-2 mr-4 space-x-2">
                    <span class="mr-1 text-sm">{{"AIR" | translate}}</span>
                    <input title="{{'AIR' | translate}}" type="checkbox" id="inputEditProductFootprintAir" name="o_footprint_air" [(ngModel)]="product.o_footprint_air" (ngModelChange)="product.o_footprint_air=$event">
                  </div>
                  <div class="flex items-center mt-2 mr-4 space-x-2">
                    <span class="mr-1 text-sm">{{"PRODUCT" | translate}}</span>
                    <input title="{{'PRODUCT' | translate}}" type="checkbox" id="inputEditProductFootprintProduct" name="o_footprint_product" [(ngModel)]="product.o_footprint_product" (ngModelChange)="product.o_footprint_product=$event">
                  </div>
                  <div class="flex items-center mt-2 mr-4 space-x-2">
                    <span class="mr-1 text-sm">{{"WASTE" | translate}}</span>
                    <input title="{{'WASTE' | translate}}" type="checkbox" id="inputEditProductFootprintWaste" name="o_footprint_waste" [(ngModel)]="product.o_footprint_waste" (ngModelChange)="product.o_footprint_waste=$event">
                  </div>
                </div>
                <textarea title="Comments" rows="2" maxlength="200" class="w-full p-2 mt-2 border border-gray-300"
                  [(ngModel)]="result.product.o_footprint_comment" (ngModelChange)="result.product.o_footprint_comment=$event"
                  name="o_footprint_comment" id="inputEditProductFootprintText"></textarea>
              </div>
              <div class="mb-2">
                <label class="mb-1 font-bold">{{"DEPARTMENT_INFO" | translate}}</label><br>
                <textarea title="{{'DEPARTMENT_INFO' | translate}}" rows="2" maxlength="200" class="w-full p-2 mt-2 border border-gray-300"
                  [(ngModel)]="result.product.department_info" (ngModelChange)="result.product.department_info=$event"
                  name="department_info" id="inputEditProductInfoText"></textarea>
              </div>
            </div>
            <div class="flex flex-row justify-center mt-4 mb-4">
              <button class="mr-2 button-red" title="{{'REMOVE' | translate}}" (click)="remove()">
                  <img title="icon" src="images/icons/trash-alt-svgrepo-com.svg" class="w-6 h-6">
                  <span class="text-lg">{{ 'REMOVE' | translate }}</span>
              </button>
              <button class="mr-2 button-green-save" title="{{'SAVE' | translate}}" (click)="save()">
                  <img title="icon" src="images/icons/save-svgrepo-com.svg" class="w-6 h-6">
                  <span class="text-lg">{{ 'SAVE' | translate }}</span>
              </button>
              <button *ngIf="product.replaced_by" class="button-blue" title="{{'EXCHANGE_TO' | translate}}" (click)="change()">
                <svg class="w-6 h-6 mr-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 10L21 7M21 7L18 4M21 7H7M6 14L3 17M3 17L6 20M3 17H17" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              <span class="text-lg">{{"EXCHANGE_TO" | translate}}{{'...'}}</span>
            </button>
            </div>
            <div *ngIf="replace" class="flex flex-col w-full p-2 mt-4 border border-gray-300 bg-cdoccontrablue">
              <span class="mb-2 text-lg font-bold">Byter ut produkt till {{replacedProductName}}</span>
              <label class="mt-4">{{"ACTION_PRODUCT" | translate}}:</label>
              <div class="flex flex-row">
                <div class="flex items-center mt-2 mr-4 space-x-2">
                  <span class="mr-1 text-sm">{{"REMOVE" | translate}}:</span>
                  <input title="{{'REMOVE' | translate}}" type="radio" [checked]="replaceproduct == true" (click)="checkitem(true)">
                </div>
                <div class="flex items-center mt-2 mr-4 space-x-2">
                  <span class="mr-1 text-sm">{{"KEEP" | translate}}:</span>
                  <input title="{{'KEEP' | translate}}" type="radio" [checked]="replaceproduct == false" (click)="checkitem(false)">
                </div>
              </div>
              <label class="mt-4">{{"CHOOSE_DEPARTMENTS" | translate}}:</label>
              <div class="flex flex-row flex-wrap">
                <div class="flex items-center w-full mt-2 mr-4 space-x-2">
                  <input title="{{'ALL_DEPARTMENTS' | translate}}" type="checkbox" name="departmentForExchange" (click)="activateordeactivateitem(-1)" (checked)="exchangeDepsItemAll==true" id="exchangeButton1">
                  <label class="mr-1 text-sm">{{'ALL_DEPARTMENTS' | translate}}</label>
                </div>
                <div class="flex flex-wrap w-full">
                  <div *ngFor="let deps of oldproductarray; let i = index" class="flex items-center w-full mt-2 mr-4 space-x-2">
                      <input title="checkbox" [disabled]="selectedDepartment.id==deps.orgid"
                              type="checkbox"
                              name="deps.orgid"
                              (click)="activateordeactivateitem(i)"
                              [checked]="deps.active==true"
                              id="modalSelectSubstitutionButton2">
                      <label class="mr-1 text-sm">{{deps.name}}<span *ngIf="selectedDepartment.id==deps.orgid">{{'CURRENT_DEPARTMENT' | translate}}</span></label>
                  </div>
                </div>
              </div>
              <div class="flex flex-row justify-center mt-4 mb-4">
                <button class="mr-2 button-red" title="{{'REMOVE' | translate}}" (click)="replace=false">
                  <span class="text-lg">{{ 'CANCEL' | translate }}</span>
                </button>
                <button class="mr-2 button-green-save" title="{{'SAVE' | translate}}" (click)="doexchange()">
                    <img title="icon" src="images/icons/save-svgrepo-com.svg" class="w-6 h-6">
                    <span class="text-lg">{{ 'SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </div>
          <!-- Modal Footer -->
          <div class="items-center mt-10">
              <button type="button"
                  class="w-full px-4 py-2 text-base font-medium text-white rounded-md shadow-sm bg-cdoclightblue hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300"
                  (click)="activeModal.close('Close click')">
                  {{"CLOSE" | translate}}
              </button>
          </div>
      </div>
  </div>
</div>

