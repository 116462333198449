import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from "src/app/services/user.service";
import { AuthService } from '../../auth/auth.service';
import { FormBuilder, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-modal-get-qr-department',
  templateUrl: './modal-get-qr-department.component.html',
  styleUrls: ['./modal-get-qr-department.component.css']
})
export class ModalGetQRDepartmentComponent implements OnInit {

  product: any;
  result: any;
  printerqrcode: boolean = false;


  @Input() thisProduct;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  userStaticToken;

  constructor(public activeModal: NgbActiveModal, private formBuilder:FormBuilder,
              private userService:UserService, private authService: AuthService,) { }

  ngOnInit(): void {
    this.product = this.thisProduct;
    this.userService.getCurrentUser('');
          this.userService.getCurrentUserDataListener().pipe(take(1)).subscribe({
            next: userData => {
              userData=JSON.parse(userData);
              if (userData == null) {

              }
              else {
                if(userData.static_token&&userData.static_token!=""){
                  localStorage.setItem('static_token',userData.static_token);
                }else{
                  localStorage.removeItem('static_token');
                }
              }
            },
            error: error => {

            }
          })

    this.result = {
      email: {
        "printerqrcode": false
      }
    };
  }

  togglePrinterqrcodeCheckbox(event) {
    this.printerqrcode = false;
    if (event.target.checked == true)
    {
      this.printerqrcode = true;
    }
  }

  ngOnDestroy(): void {
  }

  save() {
    if(!localStorage.getItem('static_token')||localStorage.getItem('static_token')==''||localStorage.getItem('static_token')==null||localStorage.getItem('static_token')=='null'){
      if(JSON.parse(localStorage.getItem('currentUser')).static_token!=""){
        localStorage.setItem('static_token',JSON.parse(localStorage.getItem('currentUser')).static_token);
      }
    }

    if (this.printerqrcode) {
      this.result.email["printerqrcode"] = true;
      if(!localStorage.getItem('static_token')||localStorage.getItem('static_token')==''||localStorage.getItem('static_token')==null||localStorage.getItem('static_token')=='null'){
        alert('Token saknas för denna användare');
        return;
      }
      this.result.email.static_token=localStorage.getItem('static_token');
      this.result.email.printerqrcode = true;
    }

    this.passEntry.emit(this.result);
    this.activeModal.close(ModalGetQRDepartmentComponent);
  }

  close() {
    this.activeModal.close(ModalGetQRDepartmentComponent);
  }

  logout() {
    this.authService.logout();
  }
}
