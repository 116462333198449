<div class="flex flex-col px-4 max-w-full py-2 mb-4 border border-gray-200">
  <div class="flex flex-row justify-between">
      <button class="px-4 py-2" title="{{'BACK' | translate}}" (click)="gotoControlpanel()">
        <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
          <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
          <path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
        </svg>
      </button>
  </div>
</div>

<div class="container mr-auto px-4 mt-2">
  <ul class="flex flex-col md:flex-row">
    <li class="mr-1">
      <a (click)="setActiveTab(0)" [class.active]="activeTab==0" class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold active:font-bold cursor-pointer">{{"PRODUCTS" | translate}}</a>
    </li>
    <li class="mr-1">
      <a (click)="setActiveTab(1)" [class.active]="activeTab==1" class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"PHONE_CALLS" | translate}}</a>
    </li>
  </ul>
</div>

<div *ngIf="activeTab==0" class="mt-1 px-4 py-2 mb-4">
  <div class="flex flex-col bg-white w-full rounded shadow-lg mb-2 p-7">
    <div class="flex flex-row w-full mt-2">
      <div class="mb-4 mr-20">
          <label class="font-bold mb-1">{{"START_DATE" | translate}}:</label><br>
          <input title="{{'NAME' | translate}}" class="w-full border border-gray-300 p-2" type="date"
          [ngModel]="coordinatorInformation.start_date | date:'yyyy-MM-dd'"
          (ngModelChange)="coordinatorInformation.start_date = $event"
          [ngModelOptions]="{standalone: true}"
          (change)="updateCoordinatorList()">
      </div>
      <div class="mb-4">
        <label class="font-bold mb-1">{{"END_DATE" | translate}}:</label><br>
        <input title="{{'NAME' | translate}}" class="w-full border border-gray-300 p-2" type="date"
        [ngModel]="coordinatorInformation.end_date | date:'yyyy-MM-dd'"
        (ngModelChange)="$event?coordinatorInformation.end_date = $event:null"
        [ngModelOptions]="{standalone: true}"
        (change)="updateCoordinatorList()">
      </div>
    </div>

    <div class="overflow-x-auto ">
      <table class="table-auto bg-white mb-4 w-full" st-safe-src="coordinatortable" class="ui selectable table">
        <thead>
            <tr>
              <th class="table-row-header-no-border" colspan="12">
                  <div class="flex flex-row items-center">
                      <div>
                          <input st-search="" type="search" [(ngModel)]="coordinatorSearch.all" class="prompt"
                              class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 w-full" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                      </div>
                  </div>
              </th>
            </tr>
            <tr>
              <th class="table-row-header sort-header" st-sort="customer">{{"CUSTOMER" | translate}}</th>
              <th class="table-row-header sort-header" st-sort="department">{{"DEPARTMENT" | translate}}</th>
              <th class="table-row-header sort-header" st-sort="supplier">{{"MANUFACTURERS" | translate}}</th>
              <th class="table-row-header sort-header" st-sort="product">{{"PRODUCT" | translate}}</th>
              <th class="table-row-header sort-header" st-sort="revision_date">{{"Revision Date"}}</th>
            </tr>
        </thead>
        <tbody>
          <tr *ngFor="let product of coordinatorProducts
              | filter : coordinatorSearch.all
              | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber,id:'coordzero' };"
              (click)="sareqSelected(product)" >
              <td class="table-row-column">{{product.customer}}</td>
              <td class="table-row-column">{{product.department}}</td>
              <td class="table-row-column">{{product.supplier}}</td>
              <td class="table-row-column">{{product.product}}</td>
              <td class="table-row-column">{{product.revision_date | date:"yyyy/MM/dd"}}</td>
            </tr>
        </tbody>
        <tfoot>
          <tr>
            <td class="p-6" colspan="2">
                <div class="static inline-block text-left" appDropdown>
                    <button type="button" class="button-gray">
                        <span class="text-sm">{{"COORDINATOR" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                        <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                    <div id="dropdownMenu" class="hidden dropdown-menu-static bottom-10" #dropdownMenuInside>
                        <div class="py-1">
                            <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                            <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                            <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                        </div>
                    </div>
                </div>
            </td>
            <td colspan="2">
                <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true" id="coordzero"
                    previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
            </td>
        </tr>
        </tfoot>
      </table>
  </div>
  </div>
</div>

<div *ngIf="activeTab==1" class="mt-1 px-4 py-2 mb-4">
  <div class="flex flex-col bg-white w-full rounded shadow-lg mb-2 p-7">
    <div class="flex flex-row w-full mt-2">
      <div class="mb-4 mr-20">
          <label class="font-bold mb-1">{{"START_DATE" | translate}}:</label><br>
          <input title="{{'NAME' | translate}}" class="w-full border border-gray-300 p-2" type="date"
          [ngModel]="coordinatorInformation.start_date | date:'yyyy-MM-dd'"
          (ngModelChange)="coordinatorInformation.start_date = $event"
          [ngModelOptions]="{standalone: true}"
          (change)="updateCoordinatorCalls()">
      </div>
      <div class="mb-4">
        <label class="font-bold mb-1">{{"END_DATE" | translate}}:</label><br>
        <input title="{{'NAME' | translate}}" class="w-full border border-gray-300 p-2" type="date"
        [ngModel]="coordinatorInformation.end_date | date:'yyyy-MM-dd'"
        (ngModelChange)="$event?coordinatorInformation.end_date = $event:null"
        [ngModelOptions]="{standalone: true}"
        (change)="updateCoordinatorCalls()">
      </div>
    </div>

    <div class="overflow-x-auto ">
      <table class="table-auto bg-white mb-4 w-full" st-safe-src="coordinatortable" class="ui selectable table">
        <thead>
            <tr>
              <th class="table-row-header-no-border" colspan="12">
                  <div class="flex flex-row items-center">
                      <div>
                          <input st-search="" type="search" [(ngModel)]="coordinatorSearch2.all" class="prompt"
                              class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 w-full" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                      </div>
                  </div>
              </th>
            </tr>
            <tr>
              <th class="table-row-header sort-header" st-sort="department">{{"DEPARTMENT" | translate}}</th>
              <th class="table-row-header sort-header" st-sort="contact_person">{{"CONTACT_PERSON" | translate}}</th>
              <th class="table-row-header sort-header" st-sort="resp_consultant">{{"CONSULT" | translate}}</th>
              <th class="table-row-header sort-header" st-sort="phone_number">{{"PHONE_NUMBER" | translate}}</th>
              <th class="table-row-header sort-header" st-sort="email">{{"EMAIL" | translate}}</th>
              <th class="table-row-header sort-header" st-sort="description">{{"DESCRIPTION" | translate}}</th>
              <th class="table-row-header sort-header" st-sort="done">{{"DONE" | translate}}</th>
              <th class="table-row-header sort-header" st-sort="due">{{"DUE_DATE" | translate}}</th>
              <th class="table-row-header sort-header" st-sort="comments">{{"COMMENTS" | translate}}</th>
              <th class="table-row-header sort-header" st-sort="isProtocol">{{"Finns protokoll"}}</th>
            </tr>
        </thead>
        <tbody>
          <tr *ngFor="let product of coordinatorCalls
            | filter : coordinatorSearch2.all
            | paginate: { 'itemsPerPage': itemsPerPage2, 'currentPage': pageNumber2,id:'coordone' };"
            (click)="sareqSelected(product)" >
            <td class="table-row-column">{{product.department}}</td>
            <td class="table-row-column">{{product.contact_person}}</td>
            <td class="table-row-column">{{product.resp_consultant}}</td>
            <td class="table-row-column">{{product.phone_number}}</td>
            <td class="table-row-column">{{product.email}}</td>
            <td class="table-row-column">{{product.description}}</td>
            <td class="table-row-column">{{product.done | date:"yyyy/MM/dd"}}</td>
            <td class="table-row-column">{{product.due | date:"yyyy/MM/dd"}}</td>
            <td class="table-row-column">{{product.comments}}</td>
            <td class="table-row-column">{{product.isProtocol}}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td class="p-6" colspan="2">
                <div class="relative inline-block text-left" appDropdown>
                    <button type="button" class="button-gray">
                        <span class="text-sm">{{"COORDINATOR" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                        <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                    <div id="dropdownMenu" class="hidden dropdown-menu bottom-10" #dropdownMenuInside>
                        <div class="py-1">
                            <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                            <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                            <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                        </div>
                    </div>
                </div>
            </td>
            <td colspan="2">
                <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true" id="coordone"
                    previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
            </td>
        </tr>
        </tfoot>
      </table>
  </div>
  </div>
</div>

