import { Component, OnInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { CoordinatorService } from 'src/app/services/coordinator.service';
import { StaticDataService } from 'src/app/services/static-data.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { take } from 'rxjs/operators';
import * as _ from "lodash";
import Swal from 'sweetalert2/dist/sweetalert2.js';
declare var $: any;

@Component({
  selector: 'app-edit-coordinator',
  templateUrl: './edit-coordinator.component.html',
  styleUrls: ['./edit-coordinator.component.css']
})
export class EditCoordinatorComponent implements OnInit {
  @ViewChildren('autoResizeTextarea') textareas: QueryList<ElementRef>;
  itemsPerPage:number = 10;
  pageNumber:number = 1;
  loadingData: boolean = false;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  listAlldepartments: any;
  sortDirection: any = null;
  sortValue: any = null;
  showSubdepartments: boolean = false;
  showNotStarted: boolean = true;
  showOngoing: boolean = true;
  showDone: boolean = true;
  coordinatorId: any = null;
  displayProducts: any = [];
  coordinatorSubject: any = ({
    name: [''],
    prio: [''],
    status: [''],
    due: new Date(),
    done: new Date(),
    responsible: [''],
    body: [''],
    selectedStatus: [''],
    comment: ['']
  });
  prev_coordinatorSubject: any = ({
    name: [''],
    prio: [''],
    status: [''],
    due: new Date(),
    done: new Date(),
    responsible: [''],
    body: [''],
    selectedStatus: [''],
    comment: ['']
  });
  products: any = [];
  statusName: any = [];
  subscribesToSds: any;
  subscribesToGrb: any;
  availableCoordinatorSubjectStatus: any;
  addChemical:boolean=false;

  coordinatorSearch = {
    all:""
  };

  productAddSearch = {
    all:"",
    name: "",
    supplier_name: ""
  };

  constructor(private authService:AuthService, private utilService: UtilService, private router: Router,
    private translate: TranslateService, private formBuilder:FormBuilder, private userService:UserService,
    private productService: ProductService, private organisationService: OrganisationService,
    private route: ActivatedRoute, private coordinatorService: CoordinatorService,
    private staticDataService: StaticDataService, private uploadService: FileUploadService) { }

  ngOnInit(): void {
    this.route.params.pipe(take(1)).subscribe(params => {
      this.coordinatorId = params['id'];
    });

    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));
    if (this.organisation == null || this.selectedDepartment == null || this.selectedOrganisation == null) {
      this.logout();
    }

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.subscribesToSds = this.getSubscription('subscription_sds');
    this.subscribesToGrb = this.getSubscription('subscription_grb');

    this.loadingData = true;

    this.getAllDepartments();
    this.getProductsMappedToIdAndName();

    setTimeout(() => {
      this.availableCoordinatorSubjectStatus = this.staticDataService.getAvailableCoordinatorSubjectStatus();
      if (this.coordinatorSubject.status == 0) {
        this.statusName = this.availableCoordinatorSubjectStatus[0].name;
      }
      if (this.coordinatorSubject.status == 1) {
        this.statusName = this.availableCoordinatorSubjectStatus[1].name;
      }
      if (this.coordinatorSubject.status == 2) {
        this.statusName = this.availableCoordinatorSubjectStatus[2].name;
      }
      if (this.coordinatorSubject.status == 3) {
        this.statusName = this.availableCoordinatorSubjectStatus[3].name;
      }
      if (this.coordinatorSubject.status == 4) {
        this.statusName = this.availableCoordinatorSubjectStatus[4].name;
      }
    }, 1000);
  }

  // Method to check if two objects are different
  hasPostChanged(): boolean {
    return JSON.stringify(this.coordinatorSubject) != JSON.stringify(this.prev_coordinatorSubject);
  }

  cancel() {
    this.hasPostChanged();
    if (this.hasPostChanged() == true)
    {
      Swal.fire({
        title: this.translate.instant('SAVE') + "?",
        text: this.translate.instant('SAVE_POST'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.translate.instant('NO'),
        confirmButtonText: this.translate.instant('YES')
      }).then((result) => {
        if (result.isConfirmed) {
          this.submit();
        } else {
          if (this.utilService.previousSelectedDepartmentIsValid('coordinator') == true) {
            const previousSelectedDepartment = localStorage.getItem('previousSelectedDepartment');
            if (previousSelectedDepartment) {
              const { department, timestamp, page } = JSON.parse(previousSelectedDepartment);
              this.organisationService.changeDepartmentById(department.id);
              localStorage.setItem('selectedDepartment', JSON.stringify(department));
              setTimeout(() => {
                this.router.navigate(['/coordinator']);
              }, 1000);
            } else {
              localStorage.removeItem('previousSelectedDepartment');
              this.router.navigate(['/coordinator']);
            }
          } else {
            localStorage.removeItem('previousSelectedDepartment');
            this.router.navigate(['/coordinator']);
          }
        }
      })
    }
    else
    {
      if (this.utilService.previousSelectedDepartmentIsValid('coordinator') == true) {
        const previousSelectedDepartment = localStorage.getItem('previousSelectedDepartment');
        if (previousSelectedDepartment) {
          const { department, timestamp, page } = JSON.parse(previousSelectedDepartment);
          this.organisationService.changeDepartmentById(department.id);
          localStorage.setItem('selectedDepartment', JSON.stringify(department));
          setTimeout(() => {
            this.router.navigate(['/coordinator']);
          }, 1000);
        } else {
          localStorage.removeItem('previousSelectedDepartment');
          this.router.navigate(['/coordinator']);
        }
      } else {
        localStorage.removeItem('previousSelectedDepartment');
        this.router.navigate(['/coordinator']);
      }
    }
  }

  getSingleCoordinator() {

    this.coordinatorService.getSingle(this.coordinatorId);
    this.coordinatorService.getCoordinatorDataListener().pipe(take(1)).subscribe({
      next: subject => {
        if (subject == null) {
        }
        else {
          this.coordinatorSubject = subject;
          setTimeout(() => {
            this.textareas.forEach(textarea => {
              this.adjustTextareaHeight({ target: textarea.nativeElement } as Event);
            });
          }, 0);
          if(this.coordinatorSubject.Metadata && this.coordinatorSubject.Metadata.length > 0) {
            for (let i = 0; i < this.coordinatorSubject.Metadata.length; i++) {
              if(this.coordinatorSubject.Metadata[i].filename){
                var encoded = this.coordinatorSubject.Metadata[i].filename;


                var decoded = decodeSwedishChars(encoded);
                this.coordinatorSubject.Metadata[i].filename = decoded;
              }
            }
          }

          for (let i = 0; i < this.coordinatorSubject.products.length; i++) {
            const foundProduct = this.products.find(product => product.sareq_id === this.coordinatorSubject.products[i].id);
            if (foundProduct) {
              this.coordinatorSubject.products[i] = foundProduct;
            }
          }
          this.prev_coordinatorSubject = _.cloneDeep(this.coordinatorSubject);
          this.loadingData = false;
        }
      },
      error: error => {
        this.loadingData = false;
        console.log("Error: ", error);
      }
    })
  }

  getProductsMappedToIdAndName() {

    if (this.selectedDepartment != null) {
      this.productService.AllMoreInformation(this.selectedDepartment.id);
      this.productService.getProductAllSubListener().pipe(take(1)).subscribe({
        next: products => {
          if (products && products.length === 0) {
            this.loadingData = false;
          } else {
            this.products = products;
            this.loadingData = false;
          }
          this.getSingleCoordinator();
        },
        error: error => {
          this.loadingData = false;
          console.log("Error: ", error);
        }
      });
    }
  }

  setStatus(status) {
    this.coordinatorSubject.status = status.id;

    if (this.coordinatorSubject.status == 0) {
      this.statusName = this.availableCoordinatorSubjectStatus[0].name;
    }
    if (this.coordinatorSubject.status == 1) {
      this.statusName = this.availableCoordinatorSubjectStatus[1].name;
    }
    if (this.coordinatorSubject.status == 2) {
      this.statusName = this.availableCoordinatorSubjectStatus[2].name;
    }
    if (this.coordinatorSubject.status == 3) {
      this.statusName = this.availableCoordinatorSubjectStatus[3].name;
    }
    if (this.coordinatorSubject.status == 4) {
      this.statusName = this.availableCoordinatorSubjectStatus[4].name;
    }
  }

  addProduct(product) {
    var i, productExists = false, addToList = true;
    if ( this.coordinatorSubject.products && this.coordinatorSubject.products.length > 0) {
      for (i = 0; i < this.coordinatorSubject.products.length; i += 1) {
        if (this.coordinatorSubject.products[i].sareq_id === product.id) {
          delete product.amount;
          delete product.annual_consumption;
          delete product.maximum_amount;
          delete product.unit;
          delete product.group;
          delete product.isSelected;
          this.coordinatorSubject.products.splice(i, 1);
          addToList = false;
          break;
        }
      }
    }

    if (addToList) {
    // Check if product is already added to organisation
    if ( this.coordinatorSubject.products && this.coordinatorSubject.products.length > 0) {
      for (i = 0; i < this.coordinatorSubject.products.length; i += 1) {
          if (this.coordinatorSubject.products[i].sareq_id === product.id) {
              productExists = true;
              delete product.isSelected;
              break;
          }
      }
    }
    if (productExists) {
        delete product.isSelected;
        Swal.fire(this.translate.instant("PRODUCT_ALREADY_EXISTS"),
                    "\"" + product.name + "\" " + this.translate.instant("PRODUCT_ALREADY_EXISTS"),
                    "error");
                    delete product.isSelected;
    } else {
      product.isSelected=true;
      if ( this.coordinatorSubject.products && this.coordinatorSubject.products.length > 0) {
        this.coordinatorSubject.products.push(product);
      } else {
        this.coordinatorSubject.products = [];
        this.coordinatorSubject.products.push(product);
      }
    }
  }
  }

  removeProduct(product) {
    var i;

    delete product.isSelected;

    for (i = 0; i < this.coordinatorSubject.products.length; i += 1) {
      if (product.id === this.coordinatorSubject.products[i].id) {
        delete product.amount;
        delete product.annual_consumption;
        delete product.maximum_amount;
        delete product.unit;
        delete product.group;
        this.coordinatorSubject.products.splice(i, 1);
        break;
      }
    }
  }

  openSdsInNewWindow(product) {

    var filename = product.name + '.pdf';
    this.productService.getSdsAsTrustedResourceUrl(product.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
        }
        else
        {
          var opened=window.open(pdfContent,"_blank");
          if (this.utilService.isMobileDevice()!=true && opened ) {
            const htmlContent = `
              <html>
                <head>
                  <title>${product.name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
      }
    })
  }

  openGrbInNewWindow(product) {

    var filename = product.name + '.pdf';
    this.productService.getGrbAsTrustedResourceUrl(product.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
        }
        else
        {
          var opened=window.open(pdfContent,"_blank");
          if (this.utilService.isMobileDevice()!=true && opened ) {
            const htmlContent = `
              <html>
                <head>
                  <title>${product.name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
      }
    })
  }

  openFileInNewWindow(file) {
    let fileId = file.file_id;
    if ( file.new && file.new == 'new' ){
      Swal.fire("Error", this.translate.instant("SAVE_POST_FIRST"), "error");
      return;
    }

    this.coordinatorService.getFileAsTrustedResourceUrl(this.coordinatorSubject.id, fileId);
    this.coordinatorService.getCoordinatorDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
        }
        else {
          var opened=window.open(pdfContent,"_blank");
          if (this.utilService.isMobileDevice()!=true && opened ) {
            const htmlContent = `
              <html>
                <head>
                  <title>${this.coordinatorSubject.name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }

  openImageFileInNewWindow(file) {
    let fileId = file.file_id;
    let mimetype = file['mime-type'];
    if ( file.new && file.new == 'new' ){
      Swal.fire("Error", this.translate.instant("SAVE_POST_FIRST"), "error");
      return;
    }

    this.coordinatorService.getImageFileAsTrustedResourceUrl(this.coordinatorSubject.id, fileId, mimetype);
    this.coordinatorService.getCoordinatorDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
        }
        else {
          var opened=window.open(pdfContent,"_blank");
          if (this.utilService.isMobileDevice()!=true && opened ) {
            const htmlContent = `
              <html>
                <head>
                  <title>${this.coordinatorSubject.name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        console.log("Error: ", error);
      }
    })
  }

  incomingfile(event) {
    // this.coordinatorSubject.file_id = event.target.files[0];

    if (event.target.files[0] != null) {
      var image = "";
      if ( event.target.files[0].type.startsWith("image") ){
        image = "yes";
      } else {
        image = "no";
      }
      event.target.files[0]["text"] = event.target.files[0].name+ "|" +"A description of the file";
      var filename = event.target.files[0].name;
      this.uploadService.upload(event.target.files[0]);
      this.uploadService.getFileDataListener().pipe(take(1)).subscribe({
        next: result => {
          if (result.success == false)
          {
            // this.coordinatorSubject.file_id = null;
          }
          else {
            //this.coordinatorSubject.file_id = result.filename;
            if(!this.coordinatorSubject.Metadata||this.coordinatorSubject.Metadata==null){
              this.coordinatorSubject.Metadata = [{'file_id':result.filename,
                'filename':filename,
                'description':'A description of the file',
                'new':'new',
                'image':image}];
            }else{
              this.coordinatorSubject.Metadata.push({'file_id':result.filename,
                'filename':filename,
                'description':'A description of the file',
                'new':'new',
                'image':image});
            }
          }
        },
        error: error => {
          // this.coordinatorSubject.file_id = null;
          console.log("Error: ", error);
        }
      })
    }
  }

  remove() {
    Swal.fire({
      title: this.translate.instant("REMOVE_COORDINATOR_SUBJECT"),
      html: this.translate.instant("REMOVE_COORDINATOR_SUBJECT_WARNING") + " ?",
      showCancelButton: true,
      cancelButtonText: this.translate.instant("NO"),
      confirmButtonText: this.translate.instant("YES"),
    }).then((result) => {
      if (result.isConfirmed) {
        this.coordinatorService.deleteCoordinator(this.coordinatorId);
        this.coordinatorService.getCoordinatorDataListener().pipe(take(1)).subscribe({
          next: subject => {
            if (subject == null) {
              Swal.fire("Error", "generiskt fel");
            }
            else {
              this.router.navigate(['/coordinator']);
            }
          },
          error: error => {
            this.loadingData = false;
            Swal.fire("Error", "generiskt fel");
          }
        })
      }
    });
  }

  submit() {
    this.coordinatorSubject.file_id="";
    if ( this.coordinatorSubject.Metadata && this.coordinatorSubject.Metadata.length > 6)
    {
      Swal.fire("Error", this.translate.instant("MAXIMUM_FILES_STORED1") + "6 " + this.translate.instant("MAXIMUM_FILES_STORED2"));
      return;
    }

    if ( this.coordinatorSubject.Metadata && this.coordinatorSubject.Metadata.some(value => value !== null)){
      let fileIds = this.coordinatorSubject.Metadata.map(file => file.file_id);
      let commaSeparatedFileIds = fileIds.join(',');
      this.coordinatorSubject.file_id = commaSeparatedFileIds;
    }
    this.coordinatorSubject.due = this.checkDate(this.coordinatorSubject.due);
    this.coordinatorSubject.done = this.checkDate(this.coordinatorSubject.done);
    this.coordinatorService.updateCoordinator(this.coordinatorId, this.coordinatorSubject);
    this.coordinatorService.getCoordinatorDataListener().pipe(take(1)).subscribe({
      next: exposure => {
        if ((exposure == null) || (exposure.Status != 'ok')) {
          Swal.fire("Error", "generiskt fel");
        }
        else {

          this.router.navigate(['/coordinator']);
        }
      },
      error: error => {
        this.loadingData = false;
        Swal.fire("Error", "generiskt fel");
      }
    })
  }

  getSubscription(subscription) {
    return (this.utilService.subscribesTo(subscription));
  }

  goToCoordinatorList() {
    this.router.navigate(['/coordinator']);
  }

  getAllDepartments() {
    if (this.organisation) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
        next: departments => {
          if (departments == null) {}
          else {
            this.listAlldepartments = departments;
          }
        },
        error: error => {
          console.log("Error: ", error);
        }
      })
    }
  }

  departFullName(department){
    return this.utilService.departFullName(department, this.listAlldepartments);
  }

  logout() {
    this.authService.logout();
  }

  checkDate(thisdate){
    if(thisdate==''||thisdate==null){
      return null;
    }else{
      return new Date(thisdate).toLocaleDateString('sv');
    }
  }

  openExtSdsInNewWindow(prod) {
    var filename = prod.name + '.pdf';
    this.productService.getExtSdsAsTrustedResourceUrl(prod.id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {}
        else {
          // popup.location.href = pdfContent;
          var opened=window.open(pdfContent,"_blank");
          if (this.utilService.isMobileDevice()!=true && opened ) {
            const htmlContent = `
              <html>
                <head>
                  <title>${prod.name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
        console.log("Error: ", error);
      }
    })
  }

  removeFile(file){
    this.coordinatorSubject.Metadata = this.coordinatorSubject.Metadata.filter(f => f.file_id !== file.file_id);
  }

  adjustTextareaHeight(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto'; // Reset the height
    const extraRowHeight = 24; // Adjust this value based on your line height
    textarea.style.height = `${textarea.scrollHeight + extraRowHeight}px`; // Set the height to the scroll height plus one extra row
  }
}

function decodeSwedishChars(str: string): string {
  /*const swedishMap = {
    '=C3=85': 'Å',
    '=C3=84': 'Ä',
    '=C3=96': 'Ö',
    '=C3=A5': 'å',
    '=C3=A4': 'ä',
    '=C3=B6': 'ö',
  };*/

  /*for (let encoded in swedishMap) {
    let regex = new RegExp(encoded, 'g');
    str = str.replace(regex, swedishMap[encoded]);
  }*/
  str = str.replace(/^=\?UTF-8\?Q\?/, '').replace(/\?=$/, '');

  // Replace = with % for URL encoding
  str = str.replace(/=/g, '%');

  // Decode the quoted-printable part
  str = decodeURIComponent(str);

  // Decode the string twice
  let bytes = [];
  for (let i = 0; i < str.length; i++) {
    bytes.push(str.charCodeAt(i));
  }

  let decoder = new TextDecoder();
  return decoder.decode(new Uint8Array(bytes));
}
