<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
  <div class="loader"></div>
</div>

<div *ngIf="loadingData==false" class="flex flex-col border border-gray-200">
    <button class="px-4 mb-2 mt-2 w-10" title="{{'BACK' | translate}}" (click)="goToProductApplication()">
      <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
          <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
          <path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
      </svg>
    </button>
</div>

<div *ngIf="loadingData==false" class="flex flex-col">
  <span class="text-xl font-bold px-4">{{"PRODUCT_APPLICATION" | translate}} - {{"HISTORY" | translate}}</span>
  <p class="px-4">{{"PRODUCT_APPLICATION_HISTORY_COMMENT" | translate}}</p>
</div>

<div *ngIf="( !loadingData && productApplications )" class="px-4 py-2 mb-20">
    <div class="overflow-x-auto">
        <table class="table-auto w-full bg-white mb-4 border border-gray-400">
            <thead class="bg-white">
                <tr>
                    <th class="table-row-header-no-border" colspan="12">
                        <div class="flex flex-row items-center">
                            <div class="mt-4">
                                <input st-search="" type="search" [(ngModel)]="productapplicationSearch.all" (input)="filterSearchItems()"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 w-full" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                            </div>
                        </div>
                    </th>
                </tr>
                <tr>
                <th class="table-row-header" (click)="sortProductsApplications('name',0)">{{"PRODUCT" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('supplier_name',7)">{{"SUPPLIER" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('department_id',1)">{{"DEPARTMENT" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('created_date',3)">{{"DATE" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('updated_date',4)">{{"LAST_UPDATED" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('updated_by_username',5)">{{"PERFORMED_BY" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('sareq_id',11)">{{"ADDED" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('originator_username',12)">{{"ORIGINATOR" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('comments',13)">{{"COMMENTS" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('status',14)">{{"STATUS" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('approval_comment',15)">{{"COMMENTS_APPROVAL" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('approval_date',6)">{{"APPROVAL_DATE" | translate}}</th>
                </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let productApplication of productApplications | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };let i = index;">
                <tr (mouseover) ="rowindex=i"
                    (mouseleave)="rowindex=null">
                <td class="table-row-column font-bold break-words" (click)="gotoProductApplicationItem(productApplication)" [ngStyle]="{'background-color':rowindex == i ? '#f5f5f5' : '' }">{{productApplication.name}}</td>
                <td class="table-row-column break-words" (click)="gotoProductApplicationItem(productApplication)" [ngStyle]="{'background-color':rowindex == i ? '#f5f5f5' : '' }">{{productApplication.supplier_name}}</td>
                <td class="table-row-column" title="{{departmentsNames}}" (click)="gotoProductApplicationItem(productApplication)" [ngStyle]="{'background-color':rowindex == i ? '#f5f5f5' : '' }">{{departmentsDisplayed}}</td>
                <td class="table-row-column break-words" (click)="gotoProductApplicationItem(productApplication)" [ngStyle]="{'background-color':rowindex == i ? '#f5f5f5' : '' }">{{productApplication.created_date | date:'yyyy-MM-dd'}}</td>
                <td class="table-row-column break-words" (click)="gotoProductApplicationItem(productApplication)" [ngStyle]="{'background-color':rowindex == i ? '#f5f5f5' : '' }">{{productApplication.updated_date | date:'yyyy-MM-dd'}}</td>
                <td class="table-row-column" (click)="gotoProductApplicationItem(productApplication)" [ngStyle]="{'background-color':rowindex == i ? '#f5f5f5' : '' }"
                    tooltips tooltip-smart="true"
                    title="{{productApplication.updated_by_first_name+
                            ' '+productApplication.updated_by_last_name+'\n'+
                            productApplication.updated_by_email+'\n'+
                            productApplication.updated_by_phone}}">
                    {{productApplication.updated_by_username}}</td>
                <td class="table-row-column" [ngStyle]="{'background-color':rowindex == i ? '#f5f5f5' : '' }">{{productApplication.in_organisation=='0'?"Nej":"Ja"}}</td>
                <td class="table-row-column" [ngStyle]="{'background-color':rowindex == i ? '#f5f5f5' : '' }"
                    tooltips tooltip-smart="true"
                    title="{{productApplication.originator_first_name+
                    ' '+productApplication.originator_last_name+'\n'+
                    productApplication.originator_email+'\n'+
                    productApplication.originator_phone}}">
                    {{productApplication.originator_username}}</td>
                <td class="table-row-column break-words" [ngStyle]="{'background-color':rowindex == i ? '#f5f5f5' : '' }" >{{productApplication.comments}}</td>
                <td class="table-row-column" [ngStyle]="{'background-color':rowindex == i ? '#f5f5f5' : '' }">{{getStatus(productApplication.status)}}</td>
                <td class="table-row-column break-words" [ngStyle]="{'background-color':rowindex == i ? '#f5f5f5' : '' }">{{productApplication.approval_comment}}</td>
                <td class="table-row-column" [ngStyle]="{'background-color':rowindex == i ? '#f5f5f5' : '' }">{{productApplication.approval_date | date:'yyyy-MM-dd'}}</td>
                </tr>
            </ng-container>
            </tbody>
            <tfoot>
                <tr>
                    <td class="p-6" colspan="2">
                        <div class="static inline-block text-left" appDropdown>
                            <button type="button" class="button-gray">
                                <span class="text-sm">{{"PRODUCTS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            <div id="dropdownMenu" class="hidden dropdown-menu-static bottom-10" #dropdownMenuInside>
                                <div class="py-1">
                                    <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                    <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                    <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                    <a (click)="itemsPerPage=100;pageNumber=1" id="productListItemsPerPage100" class="dropdown-menu-item" tabindex="4">100</a>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                            previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>
