<div *ngIf="loadingData" class="flex justify-center items-center mt-4 mb-4">
  <div class="loader"></div>
</div>

<div class="flex flex-col px-4 max-w-full py-2 mb-4 border border-gray-200">
  <div class="flex flex-row">
      <button class="px-4 py-2" title="{{'BACK' | translate}}" (click)="gotoControlPanel()">
        <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
          <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
          <path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
        </svg>
      </button>
  </div>
</div>

<div *ngIf="loadingData == false" class="flex flex-col bg-white w-full rounded shadow-lg m-4 mb-20 p-7">
  <div class="overflow-x-auto ">
      <table class="table-auto bg-white mb-4 w-full" st-table="displayedManufacturers" st-safe-src="displayedManufacturers">
        <thead>
          <tr>
            <th class="table-row-header-no-border" colspan="12">
                <div class="flex flex-row items-center">
                    <div>
                        <input st-search="" type="search" [(ngModel)]="transport_un_nbr2" class="prompt"
                            class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 w-full" placeholder="{{'SEARCH_UN_NUMBER' | translate}} ..." />
                    </div>
                </div>
            </th>
          </tr>
          <tr>
            <th class="table-row-header sort-header" (click)="sortItem('transport_un_nbr')" st-sort="transport_un_nbr">{{"UN_NUMBER" | translate}}</th>
            <th class="table-row-header" (click)="sortItem('transport_un_file_id')">{{"DOCUMENT" | translate}}</th>
            <th class="table-row-header">{{"UPLOAD_TRANSPORTINFO" | translate}}</th>
            <th class="table-row-header">{{"REMOVE_TRANSPORTINFO" | translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let product of displayedProducts
            | filterBy :['transport_un_nbr']:transport_un_nbr2
            | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };let i = index">
            <td class="table-row-column">{{product.transport_un_nbr}}</td>
            <td (click)="openTransportInfoInNewWindow(product)" class="table-row-column">
              <img *ngIf="product.transport_un_file_id" class="w-8 h-8 cursor-pointer" title="{{'TRANSPORTDOCUMENT' | translate}}" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" />
            </td>
            <td class="table-row-column">
              <div class="flex flex-row">
                <input [hidden]="product.uploadTransportInfoDocument&&product.transport_un_nbr" title="Upload file" class="button-white mt-2 border border-gray-300 mr-1" type="file"
                      (change)="fileChange($event,product)" placeholder="Upload file" id="upload-file" accept="application/pdf" />
                <button class="button-white" type="button" title="{{'UPLOAD' | translate}}" (click)="uploadTransportInfoFile(product)" [hidden]="!product.uploadTransportInfoDocument">
                      <svg class="w-8 h-8" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8 22.0002H16C18.8284 22.0002 20.2426 22.0002 21.1213 21.1215C22 20.2429 22 18.8286 22 16.0002V15.0002C22 12.1718 22 10.7576 21.1213 9.8789C20.3529
                            9.11051 19.175 9.01406 17 9.00195M7 9.00195C4.82497 9.01406 3.64706 9.11051 2.87868 9.87889C2 10.7576 2 12.1718 2 15.0002L2 16.0002C2 18.8286 2 20.2429 2.87868
                            21.1215C3.17848 21.4213 3.54062 21.6188 4 21.749" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                      </svg>
                </button>
              </div>
            </td>
            <td class="table-row-column">
              <div [hidden]="!product.transport_un_file_id" title="{{'REMOVE' | translate}}" (click)="removeTransportInfoFile(product)">
                <svg class="h-5 w-5 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                  21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                  5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                  3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                  stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
            <tr>
            <td class="p-6" colspan="4">
                <div class="flex flex-row items-center">
                <div class="static inline-block text-left mr-20" appDropdown>
                    <button type="button" class="button-gray">
                        <span class="text-sm">{{"MANUFACTURERS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                        <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                    <div id="dropdownMenu" class="hidden dropdown-menu-static bottom-10" #dropdownMenuInside>
                        <div class="py-1">
                            <a (click)="itemsPerPage=5;pageNumber=1" class="dropdown-menu-item" tabindex="1">5</a>
                            <a (click)="itemsPerPage=10;pageNumber=1" class="dropdown-menu-item" tabindex="2">10</a>
                            <a (click)="itemsPerPage=25;pageNumber=1" class="dropdown-menu-item" tabindex="3">25</a>
                        </div>
                    </div>
                </div>
                <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                    previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                </div>
            </td>
            </tr>
        </tfoot>
      </table>
  </div>
</div>


