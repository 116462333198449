import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { SubstitutionService } from 'src/app/services/substitution.service';
import { AuthService } from 'src/app/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import * as _ from "lodash";
import Swal from 'sweetalert2/dist/sweetalert2.js';
declare var $: any;

@Component({
  selector: "app-substitution",
  templateUrl: "./substitution.component.html",
  styleUrls: ["./substitution.component.css"]
})
export class SubstitutionComponent implements OnInit {
  itemsPerPage: number = 10;
  pageNumber: number = 1;
  loadingData: boolean = false;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  listAlldepartments: any = [];
  sortDirection: any = null;
  sortValue: any = null;
  substitutions: any = [];
  substitution: any = null;
  tempSubstitutions: any = [];
  tempDepSubstitutions: any = [];
  sortItems:any;
  showSubdepartments:boolean=true;
  departMents: any = [];
  status:any=[];
  searchStatus;
  pen:boolean=false;
  product;
  tempWorksteps=[];
  substitutionDBFilter: any;
  substitutionDBFilterName: string = "";
  loadingStates: { [key: number]: boolean } = {};

  substitutionSearch = {
    all: "",
  };

  ngAfterViewInit(): void {
    this.getListStatus();
    $("#substitutiontable").tablesort();
  }

  constructor(
    private translate: TranslateService,
    private utilService: UtilService,
    private organisationService: OrganisationService,
    private authService: AuthService,
    private productService: ProductService,
    private userService: UserService,
    private router: Router,
    private substitutionService: SubstitutionService
  ) {}

  ngOnInit(): void {
    this.loadingData = true;
    this.sortItems=[
      {'name':'sareqname','order':'asc'},
      {'name':'department','order':'asc'},
      {'name':'suppliername','order':'asc'},
      {'name':'statusname','order':'asc'},
      {'name':'subcreateddate','order':'asc'}
    ];
    this.organisation = JSON.parse(localStorage.getItem("rootOrganisation"));
    this.selectedDepartment = JSON.parse(
      localStorage.getItem("selectedDepartment")
    );
    this.selectedOrganisation = JSON.parse(
      localStorage.getItem("selectedOrganisation")
    );

    this.substitutionDBFilter = JSON.parse(localStorage.getItem("substitutionDBFilter"));
    if (this.substitutionDBFilter == 1)
    {
      this.substitutionDBFilterName = this.translate.instant('ONGOING');
    }
    if (this.substitutionDBFilter == 2)
    {
      this.substitutionDBFilterName = this.translate.instant('DONE');
    }
    localStorage.removeItem('substitutionDBFilter');

    if (
      this.organisation == null ||
      this.selectedDepartment == null ||
      this.selectedOrganisation == null
    ) {
      this.logout();
    }

    var data = localStorage.getItem("currentUser");
    localStorage.removeItem('departmentchanged')
    if (data == null || data == undefined) {
      this.logout();
    } else {
      this.currentUser = JSON.parse(data);
    }
    this.getListStatus();
    this.getAllDepartments();
    this.getSubstitutions();

    // Change department
    this.organisationService.departmentChanged.pipe(take(1)).subscribe((msg: any) => {
      this.utilService.departmentChangeClicked(this.router.url);

      if(msg.id!=localStorage.getItem("substitutiondepartment")){
        localStorage.setItem('departmentchanged2','set');
        this.ngOnInit();
      }
    })
  }

  removeFilter() {
    localStorage.removeItem('substitutionDBFilter');
    this.ngOnInit();
  }

  openPDFInNewWindow(substitution, i) {
    this.loadingStates[i] = true;
    this.substitutionService.getPDFAsTrustedResourceUrl(substitution.subid, this.selectedOrganisation.id);
    this.substitutionService.getSubstitutionDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          this.loadingStates[i] = false;
          Swal.fire(this.translate.instant("COULD_NOT_GET_SUB"), this.translate.instant("COULD_NOT_GET_SUB_WARNING"), "error");
        }
        else {
          var opened=window.open(pdfContent,"_blank");
          if (this.utilService.isMobileDevice()!=true && opened ) {
            const htmlContent = `
              <html>
                <head>
                  <title>${substitution.sareqname}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
          this.loadingStates[i] = false;
        }
      },
      error: error => {
        this.loadingStates[i] = false;
        console.log("Error: ", error);
        Swal.fire(this.translate.instant("COULD_NOT_GET_SUB"), this.translate.instant("COULD_NOT_GET_SUB_WARNING"), "error");
      }
    })
  }

  getItemsPerPage() {
    let info = JSON.stringify(
      {
        'userId':this.currentUser.id,
        'orgId':this.organisation.id
      });
    this.userService.getProductListSettings(info);
    this.userService.getCurrentUserDataListener().pipe(take(1)).subscribe({
      next: user => {
        if(!user||user==null){
          if (localStorage.getItem('items_per_page') !== null){
            let itemsPerPage = Number(localStorage.getItem('items_per_page'));
            if (!isNaN(itemsPerPage)) {
              this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
              return;
            } else {
              return;
            }
          } else {
            return;
          }
        } else if(user.items_per_page &&
          localStorage.getItem('items_per_page') === null){
          this.itemsPerPage = user.items_per_page;
          localStorage.setItem('items_per_page', user.items_per_page);
        } else if(localStorage.getItem('items_per_page') !== null){
          let itemsPerPage = Number(localStorage.getItem('items_per_page'));
          if (!isNaN(itemsPerPage)) {
            this.itemsPerPage = Number(localStorage.getItem('items_per_page'));
          } else {

          }
        } else {

        }
      },
      error: error => {
        return;
      }
    })
  }

  editSubstition(substitution) {
    this.pen = true;
    this.loadingData = true;
    localStorage.setItem('previousSelectedDepartment', JSON.stringify({
      department: this.selectedDepartment,
      page: 'substitution',
      timestamp: new Date().getTime()
    }));
    this.substitutionService.getSingle(substitution.subid);
    this.substitutionService.getSubstitutionDataListener().pipe(take(1)).subscribe({
      next: substitution => {
        if (substitution == null) {
          this.loadingData = false;
        }
        else {
            substitution = substitution[0];
            this.substitution=substitution;
            this.loadTableData();

            setTimeout(() => {
              if (substitution.department_id != this.selectedOrganisation.id) {
                this.organisationService.changeDepartmentById(substitution.department_id);
                this.organisationService.departmentChanged.pipe(take(1)).subscribe((msg: any) => {
                  this.utilService.departmentChangeClicked(this.router.url);
                  localStorage.setItem('departmentchanged','set');
                })
              }
              this.substitution.editActivity = true;
              localStorage.setItem('substitution', JSON.stringify(this.substitution));
              localStorage.removeItem('copysubstitution');
              localStorage.removeItem('departmentchanged');
              this.loadingData = false;
              this.router.navigate(['/substitutionedit', this.substitution.id]);
            }, 1000);
        }
      },
      error: error => {
        this.loadingData = false;
      }
    })
  }

  substitutionSelected(substitution) {
    if(this.pen==true){ // Prevents node bubbling
      return;
    }
    if(this.selectedDepartment.id!=substitution.subdepartmentid){
      this.organisationService.changeDepartmentById(substitution.subdepartmentid);
    }

    // Must await for the database call to arrive first

    for(var n=0;n<10000;n++){

    }
    localStorage.removeItem("substitution");
    localStorage.setItem("substitutiondepartment",substitution.subdepartmentid);
    localStorage.setItem("departmentchanged2","set");
    localStorage.removeItem('departmentchanged');
    this.router.navigate(["/substitutionedit", substitution.subid]);

  }

  doPDF(substitution){
    this.substitutionService.createPDFSubstitution(substitution.subid);
    this.substitutionService
      .getCreatePDFUpdatedListener()
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          if (response == null) {
            Swal.fire(
              this.translate.instant("COULD_NOT_GET_SUB"),
              this.translate.instant("COULD_NOT_GET_SUB_WARNING"),
              "error"
            );
          } else {
            var file: any, fileURL: string, contentType: string;
            contentType = "application/pdf";
            file = new Blob([response], { type: contentType });
            fileURL = URL.createObjectURL(file);
            var popup = window.open("");
            popup.location.href = fileURL;
          }
        },
        error: (error) => {
          Swal.fire(
            this.translate.instant("COULD_NOT_GET_SUB"),
            this.translate.instant("COULD_NOT_GET_SUB_WARNING"),
            "error"
          );
        },
      });
  }

  copySubstitution(substitution){
    this.substitutionService.getSingle(substitution.subid);
    this.substitutionService.getSubstitutionDataListener().pipe(take(1)).subscribe({
      next: substitution => {
        if (substitution == null) {
        }
        else {
            substitution = substitution[0];
            this.substitution = substitution;
            localStorage.setItem('substitution', JSON.stringify(this.substitution));
            localStorage.setItem('copysubstitution','set');
            this.router.navigate(['/substitutionedit', this.substitution.id]);
        }
      },
      error: error => {
      }
    })
  }

  getSubstitutions() {
    this.substitutionService.getAll();
    this.substitutionService
      .getSubstitutionAllDataListener()
      .pipe(take(1))
      .subscribe({
        next: (substitutions) => {
          if (substitutions == null || substitutions.length == 0) {
            this.loadingData = false;
          } else {
            setTimeout(() => {
              substitutions.forEach((element) => {
                if (element.subdepartmentid) {
                  element.department = this.departFullName(
                    element.subdepartmentid
                  );
                  element.depName=this.getLastDepartmentName(element.department);
                }
                if (element.substatus !== null &&
                    element.substatus > -1 &&
                    element.substatus < 6)
                {
                      element.statusname = this.getNameStatus(element.substatus);
                }
              });
              this.tempSubstitutions = substitutions;
              this.showSubdepartments=localStorage.getItem('substitutionShowSubdepartments')=='false'?false:true;
              var subDepartmentsIds = this.utilService.getAllSubdepartments(JSON.parse(localStorage.getItem("selectedDepartment")),true);
              this.departMents = this.getDepartmentSubstitutions(this.showSubdepartments,substitutions,subDepartmentsIds)
              this.substitutions = this.departMents;
              if (this.substitutionDBFilter != null)
              {
                if (this.substitutionDBFilter === '1')
                {
                  this.substitutions = this.substitutions.filter(substitution =>
                    [0, 1, 2, 3].includes(substitution.substatus)
                  );
                }

                if (this.substitutionDBFilter === '2')
                {
                  this.substitutions = this.substitutions.filter(substitution =>
                    [4].includes(substitution.substatus)
                  );
                }

                if (this.substitutionDBFilter === '3')
                  {
                    this.substitutions = this.substitutions.filter(substitution =>
                      [5].includes(substitution.substatus)
                    );
                  }
              }
              this.tempDepSubstitutions=_.cloneDeep(this.substitutions);
              this.loadingData = false;
            }, 1000);
          }
          this.getItemsPerPage();
        },
        error: (error) => {
          this.loadingData = false;
          console.log("Error: ", error);
        },
      });
  }

  getAllDepartments() {
    this.organisationService.getAllDepartmentNames(this.organisation);
    this.organisationService
      .getOrganisationAllDepartmentNamesDataListener()
      .pipe(take(1))
      .subscribe({
        next: (departments) => {
          if (departments == null) {
          } else {
            this.listAlldepartments = departments;
          }
        },
        error: (error) => {
          console.log("Error: ", error);
        },
      });
  }

  getNameStatus(status) {
    switch (status) {
      case 0:
        return this.translate.instant("NEW");
        break;
      case 1:
        return this.translate.instant("ONGOING");
        break;
      case 2:
        return this.translate.instant("FOR_REVIEW");
        break;
      case 3:
        return this.translate.instant("SAREQ_WAITING_FOR_PRODUCT");
        break;
      case 4:
        return this.translate.instant("FINISHED");
        break;
      case 5:
        return this.translate.instant("FINISHED_AND_SUBSTITUTED");
        break;
      default:
        break;
    }
  }

  getListStatus(){
    setTimeout(() => {
      this.status=[
        this.translate.instant("NEW"),
        this.translate.instant("ONGOING"),
        this.translate.instant("FOR_REVIEW"),
        this.translate.instant("SAREQ_WAITING_FOR_PRODUCT"),
        this.translate.instant("FINISHED"),
        this.translate.instant("FINISHED_AND_SUBSTITUTED")];
    },500);
  }

  departFullName(department) {
    return this.utilService.departFullName(department, this.listAlldepartments);
  }

  sortHeader(value) {
    /*if (this.sortDirection == "asc") {
      this.sortDirection = "desc";
    } else {
      this.sortDirection = "asc";
    }

    localStorage.setItem("sortDirection", this.sortDirection);
    localStorage.setItem("sortValue", value);
    window.location.reload();*/
    if ( this.sortItems[value].order == 'asc' )
    {
      this.sortItems[value].order='desc';
      this.substitutions.sort( (a, b) => ( a[this.sortItems[value].name]?.toLowerCase() > b[this.sortItems[value].name]?.toLowerCase()) ? 1 : -1 );
    }
    else if ( this.sortItems[value].order == 'desc' )
    {
      this.sortItems[value].order='asc';
      this.substitutions.sort( (a, b) => (a[this.sortItems[value].name]?.toLowerCase() > b[this.sortItems[value].name]?.toLowerCase()) ? -1 : 1 );
    }
  }

  onToggleShowSubDepartments(){
    this.showSubdepartments = !this.showSubdepartments;
    localStorage.setItem('substitutionShowSubdepartments',this.showSubdepartments.toString());
    var subDepartmentsIds = this.utilService.getAllSubdepartments(JSON.parse(localStorage.getItem("selectedDepartment")),true);
    this.substitutions = this.getDepartmentSubstitutions(this.showSubdepartments,this.tempSubstitutions,subDepartmentsIds);
    this.tempDepSubstitutions=_.cloneDeep(this.substitutions);
    this.gotoFilterStatus();
  }

  logout() {
    this.authService.logout();
  }

  getDepartmentSubstitutions(showSubdepartments,substitutions,subDepartmentsIds){
    if(showSubdepartments==false){
      var departMents = substitutions.filter(function(substitutionsItems) {
        return substitutionsItems.subdepartmentid == JSON.parse(localStorage.getItem("selectedDepartment")).id;
      });
    } else {
      var departMents = substitutions.filter(function(substitutionsItems) {
        return subDepartmentsIds.indexOf(substitutionsItems.subdepartmentid) > -1;
      });
    }
    return departMents;
  }

  filterStatus(status){
    this.substitutions=this.tempDepSubstitutions;
    this.searchStatus=null;
    if(status==this.translate.instant("NEW")){this.searchStatus=0;}
    else if(status==this.translate.instant("ONGOING")){this.searchStatus=1;}
    else if(status==this.translate.instant("FOR_REVIEW")){this.searchStatus=2;}
    else if(status==this.translate.instant("SAREQ_WAITING_FOR_PRODUCT")){this.searchStatus=3;}
    else if(status==this.translate.instant("FINISHED")){this.searchStatus=4;}
    else if(status==this.translate.instant("FINISHED_AND_SUBSTITUTED")){this.searchStatus=5;}
    else{this.searchStatus=6;}
    if(this.searchStatus>-1 && this.searchStatus <6){
      this.substitutions=this.substitutions.filter(o => o.substatus==this.searchStatus);
    }
  }

  gotoFilterStatus(){
    this.substitutions=this.tempDepSubstitutions;
    if(this.searchStatus>-1 && this.searchStatus <6){
      this.substitutions=this.substitutions.filter(o => o.substatus==this.searchStatus);
    }
  }

  clearStatus(){
    this.substitutions=this.tempDepSubstitutions;
    this.searchStatus=-1;
  }

  loadTableData() {
    this.substitutionService.getSingleProductAny(this.substitution.product_id);
    this.substitutionService.getSubstitutionProductDataListener().pipe(take(1)).subscribe({
      next: product => {

        if (product == null) {
          this.loadingData = false;
        }
        else {

          this.product = product;
        }
      },
      error: error => {
        this.loadingData = false;
      }
    })

    this.substitution.checkbox = this.substitution.checkbox_endusage==1?true:false;

    var counter = 0;
    if (this.substitution && this.substitution.worksteps)
    {
      for (var j = 0; j < this.substitution.worksteps.length; j++)
      {
        if (this.substitution.worksteps[j].product_id)
        {
          this.substitutionService.getSingleProductAny(this.substitution.worksteps[j].product_id);
        }
      }
    }

    this.reveiveProductMsg(counter);
  }

  reveiveProductMsg(counter) {
    this.substitutionService.getSubstitutionProductDataListener().pipe(take(1)).subscribe({
      next: (product2) => {

        if (this.substitution && this.substitution.worksteps) {
          if(product2&&product2.product_id!=this.substitution.product_id){
            if (counter < this.substitution.worksteps.length) {
              this.tempWorksteps[counter] = product2;
              counter = counter + 1
              this.reveiveProductMsg(counter);
          }
        }else{
            //counter = counter + 1
            this.reveiveProductMsg(counter);
          }
      }


      },
      error: error => {
        console.log("Error: ", error);
      }
    });

    // As it is asynchronous I cant be sure of what order the database calls arrive
    if(this.tempWorksteps&&this.substitution.worksteps){
      for(var n = 0;n<this.tempWorksteps.length;n++){
        for(var m = 0;m<this.substitution.worksteps.length;m++){
          if(this.substitution.worksteps[m].product_id==this.tempWorksteps[n].product_id){
            this.substitution.worksteps[m].product=this.tempWorksteps[n];
            this.substitution.worksteps[m].product.name = this.tempWorksteps[n].product_name;
          }
        }
      }
    }
  }

  getLastDepartmentName(department: string): string {
    return department.split('/').pop() || department;
  }
}
