import { Component, OnInit } from '@angular/core';
import { UtilService } from "src/app/services/util.service";
import { OrganisationService } from "src/app/services/organisation.service";
import { KebnekaiseService } from "src/app/services/kebnekaise.service";
import { Router } from "@angular/router";
import { take } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { AuthService } from 'src/app/auth/auth.service';
import * as _ from "lodash";
declare var $: any;

@Component({
  selector: 'app-kebnekaise',
  templateUrl: './kebnekaise.component.html',
  styleUrls: ['./kebnekaise.component.css']
})
export class KebnekaiseComponent implements OnInit {
  selectedDepartment: any;
  selectedOrganisation: any;
  listAlldepartments: any;
  organisation: any = {
    name: "",
    org_nr: "",
    address_1: "",
    address_2: "",
    address_visit: "",
    zip: "",
    city: "",
    country: "",
    www: "",
    email: "",
    phone: "",
    contact_person: "",
    resp_consultant: "",
    misc: "",
    subscriptions: [],
    customer_type: null,
  };

  productSearch = {
    all: "",
    name: "",
    supplier_name: "",
    com_use: ""
};
  itemsPerPage: number = 5;
  pageNumber: number = 1;
  products:any;

  constructor(private router: Router,
              private organisationService: OrganisationService,
              private kebnekaiseService: KebnekaiseService,
              private utilService: UtilService,
              private translate: TranslateService,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(
      localStorage.getItem("rootOrganisation")
    );
    this.getAllDepartments();
    this.loadTableData();
  }

  ngAfterViewInit(): void {
    $("#kebnekaisetable").tablesort();
  }

  departFullName(department) {
    return this.utilService.departFullName(department, this.listAlldepartments);
  }

  getAllDepartments() {
    if (this.organisation) {
      this.organisationService.getAllDepartmentNames(this.organisation);
      //this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        this.organisationService.getOrganisationAllDepartmentNamesDataListener().pipe(take(1)).subscribe({
          next: (departments) => {
            if (departments == null) {
            } else {
              this.listAlldepartments = departments;
            }
          },
          error: (error) => {
            console.log("Error: ", error);
            return null;
          },
        });
    } else {
      return null;
    }
  }

  gotoControlpanel() {
    this.router.navigate(["/controlpanel"]);
  }

  loadTableData() {
    this.kebnekaiseService.getAllKebneKaiseProducts();
    this.kebnekaiseService
      .getKebnekaiseDataListener()
      .pipe(take(1))
      .subscribe({
        next: (products) => {
          this.products = products;
        },
        error: (error) => {
          console.log("Error: ", error);
          return null;
        },
      });
  }

  productSelected(product){
    localStorage.setItem("catalogueDepartment", JSON.stringify(product.organisation_id));
    var id = product.kebnekaise_id;
    if (_.isString(id)) {
        this.router.navigate(['/catalogueitem',id]);
    }
  }

  openSdsInNewWindow(product){
    var filename = product.name + '.pdf';
    this.kebnekaiseService.getSdsAsTrustedResourceUrl(product.kebnekaise_id);
    this.kebnekaiseService.getKebnekaiseDataListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
        }
        else
        {
          var opened=window.open(pdfContent,"_blank");
          if (this.utilService.isMobileDevice()!=true && opened ) {
            const htmlContent = `
              <html>
                <head>
                  <title>${product.name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
      }
    })
  }

  logout() {
    this.authService.logout();
  }
}
