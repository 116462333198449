<div class="container mr-auto px-4 mt-2">
    <ul class="flex flex-col md:flex-row mb-2">
      <li class="mr-1">
          <a (click)="showUserProfile()" [class.active]="isUserProfile"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold active:font-bold cursor-pointer">{{"PROFILE" | translate}}</a>
      </li>
      <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_local_admin || currentUser.is_super_user)" class="mr-1">
        <a (click)="showCompanyUsers()" [class.active]="isCompanyUsers"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"USERS" | translate}}</a>
      </li>
      <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
        <a (click)="showDepartmentSetup()" [class.active]="isDepartmentSetup"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"DEPARTMENT_SETUP" | translate}}</a>
      </li>
      <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
        <a (click)="showGroups()" [class.active]="isGroups"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"PRODUCT_GROUPS" | translate}}</a>
      </li>
      <li *ngIf="currentUser && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
        <a (click)="showCompanyInfo()" [class.active]="isCompanyInfo"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"COMPANY_INFO" | translate}}</a>
      </li>
      <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
        <a (click)="showArticles()" [class.active]="isArticles"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"ARTICLES" | translate}}</a>
      </li>
      <li *ngIf="subscription_stamped_SDS && subscribesToFreemium==false && currentUser && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user || currentUser.is_maintenance_user)" class="mr-1">
        <a (click)="showStampedSds()" [class.active]="isStampedSds"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"SUBSCRIPTION_STAMPED_SDS" | translate}}</a>
      </li>
      <li *ngIf="currentUser && subscribesToFreemium==false && (currentUser.is_useradmin || currentUser.is_admin || currentUser.is_super_user)" class="mr-1">
        <a (click)="showMiscInfo()" [class.active]="isMisc"  class="bg-white inline-block py-2 px-4 text-black hover:text-blue-800 font-semibold cursor-pointer">{{"MISC" | translate}}</a>
      </li>
    </ul>
</div>

<div class="max-w-screen m-4 mb-40 md:mb-20" *ngIf="isStampedSds">
    <div class="px-6 py-4 h-auto w-full rounded bg-white overflow-hidden shadow-lg mb-4">
        <div class="overflow-x-auto">
            <table class="table-auto w-full bg-white mb-4 border border-gray-400 mt-4" st-table="products" st-safe-src="products">
                <thead class="bg-white">
                    <tr>
                        <th class="table-row-header" colspan="12">
                            <div class="flex flex-row items-center">
                                <div class="mt-4">
                                    <input st-search="" type="search" [(ngModel)]="stampedSdsSearch.all" class="prompt"
                                        class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 w-full" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th class="table-row-header sort w-1/6" (click)="sortItem(1)">{{"DEPARTMENT" | translate}}</th>
                        <th class="table-row-header sort" (click)="sortItem(2)">{{"NAME" | translate}}</th>
                        <th class="table-row-header sort" (click)="sortItem(3)">{{"MANUFACTURER" | translate}}</th>
                        <th st-sort="stamped_sds.sds_id" class="table-row-header sort-header">{{"DOCUMENTS" | translate}}</th>
                        <th class="table-row-header sort" (click)="sortItem(4)">{{"REVISION_DATE" | translate}}</th>
                        <th class="table-row-header sort" (click)="sortItem(5)">{{"SDS_Version" | translate}}</th>
                        <th class="table-row-header sort" (click)="sortItem(6)">{{"SDSSTAMPDATE" | translate}}</th>
                        <th class="table-row-header">{{"UPLOAD_STAMPED_DOCUMENT_1" | translate}}<br/>{{"UPLOAD_STAMPED_DOCUMENT_2" | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let product of products
                        | filter : stampedSdsSearch.all
                        | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };" class="tablerow">
                        <td class="table-row-column">{{departFullName(product.organisation_id)}}</td>
                        <td class="table-row-column">{{product.name}}</td>
                        <td class="table-row-column">{{product.supplier_name}}</td>
                        <td class="table-row-column">
                            <div class="flex flex-row">
                                <img class="w-10 h-10 -mr-3 cursor-pointer" *ngIf="( product.esdb_available==0 || ( product.esdb_file_id!='' && product.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}" id="SDBIcon" src="/images/icons/SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(product)" />
                                <img class="w-10 h-10 -mr-3 cursor-pointer" *ngIf="( product.esdb_available==1 && ( product.esdb_file_id=='' || product.esdb_file_id==null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(product)" />
                                <img class="w-10 h-10 -mr-3 cursor-pointer" *ngIf="( product.esdb_available==1 && ( product.esdb_file_id!='' && product.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openExtSdsInNewWindow(product)" />
                                <img class="w-10 h-10 cursor-pointer" *ngIf="product.risk_prio" tooltips tooltip-smart="true" title="{{'GENERAL_RISK_ASSESSMENT' | translate}}" id="GRBIcon" src="/images/icons/GRB.svg" area-hidden="true" (click)="openGrbInNewWindow(product)" />
                                <img (click)="openStampedSdsInNewWindow(product)" *ngIf="product.stamped_sds.sds_stamp_id" class="w-8 h-8 cursor-pointer" title="{{'STAMPED_SDS' | translate}}" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" />
                            </div>
                        </td>
                        <td class="table-row-column" *ngIf="product.stamped_sds.sds_date=='0000-00-00 00:00:00'"></td>
                        <td class="table-row-column" *ngIf="product.stamped_sds.sds_date!=''&&product.stamped_sds.sds_date!='0000-00-00 00:00:00'">{{product.stamped_sds.sds_date | date:'yyyy-MM-dd'}}</td>
                        <td class="table-row-column" *ngIf="product.stamped_sds.sds_date==''"></td>
                        <td class="table-row-column">{{product.stamped_sds.sds_version}}</td>
                        <td class="table-row-column">{{product.stamped_sds.stamp_date | date:'yyyy-MM-dd'}}</td>
                        <td class="table-row-column">
                            <div class="flex flex-row">
                                <input class="border border-gray-200 p-2 w-60" title="file" type="file" id="upload-file" (change)="incomingfile($event,product)"/>
                                <svg title="{{'UPLOAD' | translate}}" (click)="uploadFile(product)" class="w-10 h-10 cursor-pointer" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17 17H17.01M15.6 14H18C18.9319 14 19.3978 14 19.7654 14.1522C20.2554 14.3552 20.6448 14.7446 20.8478 15.2346C21 15.6022 21 16.0681 21 17C21 17.9319 21
                                    18.3978 20.8478 18.7654C20.6448 19.2554 20.2554 19.6448 19.7654 19.8478C19.3978 20 18.9319 20 18 20H6C5.06812 20 4.60218 20 4.23463 19.8478C3.74458 19.6448 3.35523
                                    19.2554 3.15224 18.7654C3 18.3978 3 17.9319 3 17C3 16.0681 3 15.6022 3.15224 15.2346C3.35523 14.7446 3.74458 14.3552 4.23463 14.1522C4.60218 14 5.06812 14 6 14H8.4M12
                                    15V4M12 4L15 7M12 4L9 7" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td class="p-6" colspan="2">
                            <div class="static inline-block text-left" appDropdown>
                                <button type="button" class="button-gray">
                                    <span class=" text-sm">{{"PRODUCTS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                                    <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </button>
                                <div id="dropdownMenu" class="hidden dropdown-menu-static left-40" #dropdownMenuInside>
                                    <div class="py-1">
                                        <a (click)="itemsPerPage=5;pageNumber=1" class="dropdown-menu-item" tabindex="1">5</a>
                                        <a (click)="itemsPerPage=10;pageNumber=1" class="dropdown-menu-item" tabindex="2">10</a>
                                        <a (click)="itemsPerPage=15;pageNumber=1" class="dropdown-menu-item" tabindex="3">15</a>
                                    </div>
                                    </div>
                            </div>
                        </td>
                        <td colspan="4">
                            <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                                previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>

