<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
  <div class="loader"></div>
</div>

<div *ngIf="loadingData==false" class="flex flex-col border border-gray-200">
  <button class="px-4 mt-4" title="{{'BACK' | translate}}" (click)="gotoControlPanel()">
    <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
        <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
        <path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
    </svg>
  </button>
</div>

<div class="overflow-auto">
  <div *ngIf="(!loadingData &&productApplications && productApplications.length > 0)" class="mt-4 px-4 py-2 mb-20">
      <table class="table-auto w-full bg-white mb-4 border border-gray-400">
          <thead class="bg-white">
              <tr>
                  <th class="table-row-header-no-border" colspan="12">
                      <div class="flex flex-row items-center">
                          <div class="mt-4">
                              <input st-search="" type="search" [(ngModel)]="productapplicationSearch.all" class="prompt"
                                  class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 w-full" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                          </div>
                      </div>
                  </th>
              </tr>
              <tr>
                <th class="table-row-header"(click)="sortProductsApplications('name',0)">{{"PRODUCT" | translate}}</th>
                <th class="table-row-header"(click)="sortProductsApplications('org_name',1)">{{"COMPANY" | translate}}</th>
                <th class="table-row-header"(click)="sortProductsApplications('created_date',2)">{{"DATE" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('updated_date',3)">{{"LAST_UPDATED" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('approval_date',4)">{{"APPROVAL_DATE" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('supplier_name',5)">{{"SUPPLIER" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('sareq_id',6)">{{"IN_SAREQ" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('username',7)">{{"ORIGINATOR" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('status',8)">{{"STATUS" | translate}}</th>
              </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let productApplication of productApplications | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };let i = index">
              <tr (mouseover) ="rowindex=i;" (mouseleave)="rowindex=null">
                <td class="table-row-column" [ngStyle]="{'background-color':rowindex == i ? '#f5f5f5' : '' }">
                  {{"  "}}{{productApplication.name}}
                </td>
                <td class="table-row-column" [ngStyle]="{'background-color':rowindex == i ? '#f5f5f5' : '' }">{{productApplication.org_name}}</td>
                <td class="table-row-column" [ngStyle]="{'background-color':rowindex == i ? '#f5f5f5' : '' }">{{productApplication.created_date | date:'yyyy-MM-dd'}}</td>
                <td class="table-row-column" [ngStyle]="{'background-color':rowindex == i ? '#f5f5f5' : '' }">{{productApplication.updated_date | date:'yyyy-MM-dd'}}</td>
                <td class="table-row-column" [ngStyle]="{'background-color':rowindex == i ? '#f5f5f5' : '' }">{{productApplication.approval_date | date:'yyyy-MM-dd'}}</td>
                <td class="table-row-column" [ngStyle]="{'background-color':rowindex == i ? '#f5f5f5' : '' }">{{productApplication.supplier_name}}</td>
                <td class="table-row-column" [ngStyle]="{'background-color':rowindex == i ? '#f5f5f5' : '' }">{{productApplication.sareq_id==null?"Nej":"Ja"}}</td>
                <td class="table-row-column" [ngStyle]="{'background-color':rowindex == i ? '#f5f5f5' : '' }"
                    tooltips tooltip-smart="true"
                    title="{{productApplication.first_name+
                    ' '+productApplication.last_name+'\n'+
                    productApplication.email+'\n'+
                    productApplication.phone}}">
                    {{productApplication.username}}</td>
                <td class="table-row-column" [ngStyle]="{'background-color':rowindex == i ? '#f5f5f5' : '' }">{{getStatus(productApplication.status)}}</td>
              </tr>
            </ng-container>
          </tbody>
          <tfoot>
              <tr>
                  <td class="p-6" colspan="2">
                      <div class="static inline-block text-left" appDropdown>
                          <button type="button" class="button-gray">
                              <span class="text-sm">{{"PRODUCTS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                              <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                          </button>
                          <div id="dropdownMenu" class="hidden dropdown-menu-static bottom-10" #dropdownMenuInside>
                              <div class="py-1">
                                  <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                  <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                  <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                  <a (click)="itemsPerPage=100;pageNumber=1" id="productListItemsPerPage100" class="dropdown-menu-item" tabindex="4">100</a>
                              </div>
                          </div>
                      </div>
                  </td>
                  <td colspan="4">
                      <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                          previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                  </td>
              </tr>
          </tfoot>
      </table>
  </div>
</div>
