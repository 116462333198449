<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
    <div class="loader"></div>
</div>

<div [hidden]="loadingData" class="flex flex-col px-4 max-w-full py-2 mb-4 border border-gray-200">
    <div class="flex justify-between">
        <div class="flex flex-row">
            <p class="hidden md:block text-xl font-bold mt-6 px-4">{{"SUBSTITUTIONS" | translate}}</p>
            <button type="button" *ngIf="substitutionDBFilter" class="button-filter-yellow mt-4" (click)="removeFilter()" >
                <span class="text-lg">{{"FILTERED" | translate}}: {{substitutionDBFilterName}}</span>
            </button>
        </div>
    </div>

</div>

<div *ngIf="loadingData==false" class="px-4 py-2 mb-40 md:mb-20">
    <div class="overflow-x-auto">
        <table class="table-auto w-full bg-white mb-4 border border-gray-400" id="substitutiontable">
            <thead class="bg-white">
                <tr>
                    <th class="table-row-header-no-border" colspan="12">
                        <div class="flex flex-col md:flex-row">
                            <div class="mt-4">
                                <input st-search="" type="search" [(ngModel)]="substitutionSearch.all" class="prompt"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                            </div>
                            <div class="flex items-center space-x-2 mt-4 py-2 px-4">
                                <input title="{{'SHOW_SUBDEPARTMENTS' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" name="example" [ngModel]="showSubdepartments" (click)="onToggleShowSubDepartments()">
                                <span class="text-sm mr-1">{{"SHOW_SUBDEPARTMENTS" | translate}}</span>
                            </div>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th class="table-row-header-no-border" colspan="12">
                        <div class="static inline-block text-left" id="selectRisk" appDropdown>
                            <button type="button" class="button-gray">
                                <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.17 3.91C20.1062 3.78712 20.0101 3.68399 19.8921 3.61173C19.774 3.53947 19.6384 3.50084 19.5 3.5H4.5C4.36157 3.50084 4.226
                                    3.53947 4.10792 3.61173C3.98984 3.68399 3.89375 3.78712 3.83 3.91C3.76636 4.03323 3.73915 4.17204 3.75155 4.31018C3.76395 4.44832 3.81544
                                    4.58007 3.9 4.69L9.25 12V19.75C9.25259 19.9481 9.33244 20.1374 9.47253 20.2775C9.61263 20.4176 9.80189 20.4974 10 20.5H14C14.1981 20.4974
                                    14.3874 20.4176 14.5275 20.2775C14.6676 20.1374 14.7474 19.9481 14.75 19.75V12L20.1 4.69C20.1846 4.58007 20.236 4.44832 20.2484 4.31018C20.2608
                                    4.17204 20.2336 4.03323 20.17 3.91Z" fill="#000000"/>
                                </svg>
                                <span>{{"STATUS" | translate}}</span>
                            </button>
                            <div id="dropdownMenu" class="hidden dropdown-menu-static" #dropdownMenuInside>
                                <div class="py-5" *ngFor="let statusItem of status" (click)="filterStatus(statusItem)" class="dropdown-menu-item" id="{{'substitutionSelectStatus'+statusItem}}" tabindex="1">{{ statusItem }} </div>
                            </div>
                        </div>
                        <div *ngIf="(searchStatus > -1 && searchStatus < 6)" class="relative inline-block border border-gray-200">
                            <button type="button" id="buttonProductList3" class="text-gray-600 button-red" (click)="clearStatus()">{{"CLEAR" | translate}}</button>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th class="table-row-header" (click)="sortHeader(0)">{{"PRODUCT" | translate}}</th>
                    <th class="table-row-header" (click)="sortHeader(1)">{{"DEPARTMENT" | translate}}</th>
                    <th class="table-row-header" (click)="sortHeader(2)">{{"MANUFACTURER" | translate}}</th>
                    <th class="table-row-header" (click)="sortHeader(3)">{{"STATUS" | translate}}</th>
                    <th class="table-row-header" (click)="sortHeader(4)">{{"DATE" | translate}}</th>
                    <th class="table-row-header">{{"DOCUMENT" | translate}}</th>
                    <th class="table-row-header"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let substitution of substitutions
                    | filter : substitutionSearch.all
                    | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber }; let i=index">
                    <td class="table-row-column" (click)="editSubstition(substitution)">{{substitution.sareqname}}</td>
                    <td class="table-row-column" (click)="editSubstition(substitution)" tooltips tooltip-smart="true" title="{{substitution.department}}">{{substitution.depName}}</td>
                    <td class="table-row-column" (click)="editSubstition(substitution)">{{substitution.suppliername}}</td>
                    <td class="table-row-column" (click)="editSubstition(substitution)">{{getNameStatus(substitution.substatus)}}</td>
                    <td class="table-row-column" (click)="editSubstition(substitution)">{{(substitution.subcreateddate | date:'yyyy-MM-dd') ?? ''}}</td>
                    <td class="table-row-column">
                        <div *ngIf="substitution.file_id" class="flex flex-row justify-center">
                            <button (click)="openPDFInNewWindow(substitution, i)" [disabled]="loadingStates[i]==true" class="px-4" [class.cursor-not-allowed]="loadingStates[i]==true">
                                <ng-container *ngIf="!loadingStates[i]; else loading">
                                  <div class="flex items-center">
                                    <img class="w-6 h-6 cursor-pointer" title="{{'ACTIVITY_RISK_ASSESSMENT' | translate}}" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" />
                                  </div>
                                </ng-container>
                                <ng-template #loading>
                                  <div class="flex items-center">
                                    <svg class="w-6 h-6 mr-1 text-black animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                  </div>
                                </ng-template>
                            </button>
                        </div>
                    </td>
                    <td *ngIf="substitution&&(substitution.substatus>-1)" class="table-row-column" (click)="copySubstitution(substitution)">
                        <img class="w-6 h-6 cursor-pointer" title="{{'COPY' | translate}}" src="/images/icons/copy-svgrepo-com.svg" area-hidden="true" />
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td class="p-6" colspan="2">
                        <div class="static inline-block text-left" appDropdown>
                            <button type="button" class="button-gray">
                                <span class="text-sm">{{"SUBSTITUTIONS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            <div id="dropdownMenu" class="hidden dropdown-menu-static bottom-10" #dropdownMenuInside>
                                <div class="py-1">
                                    <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                    <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                    <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                            previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>


