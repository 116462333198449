<div #pdfContent>
    <div *ngIf="substitution" id="content" class="content p-4">
        <div class="page">
            <p class="text-4xl font-extrabold px-4 text-gray-900 text-center mb-6">
                {{ "SUBSTITUTION" | translate }}
            </p>
            <div class="flex flex-row w-full mb-4">
                <!-- Header Section -->
                <div class="flex flex-col md:flex-row justify-center items-center bg-cdocbanner pb-6 w-full">
                    <div class="flex-shrink-0" *ngIf="imageLogo">
                        <img class="pl-4 pt-4 mr-4" title="{{'ATTACHE_PICTURES' | translate}}" [src]="imageLogo" height="200" width="200"/>
                    </div>
                    <div class="flex-grow text-center md:text-left mt-2">
                        <p class="text-xl font-bold px-4 text-white break-all"><a><strong>{{substitution?.supplier_name}}</strong></a></p>
                        <span class="text-3xl font-bold px-4 text-white break-all">{{substitution?.sareq_name}}</span>
                        <div class="flex flex-row px-4 mt-4">
                            <div *ngIf="product?.pictograms?.length > 0" class="flex flex-row">
                                <span *ngFor="let pictogram of product?.pictograms" tooltips tooltip-smart="true" title={{pictogram.description}}>
                                    <img class="w-10 h-10" title={{pictogram.description}} src="/images/pictograms/{{pictogram.filename}}-small.png" />
                                </span>
                            </div>
                        </div>
                        <div class="flex flex-col px-4">
                            <div *ngIf="product?.phrases">
                                <ul *ngIf="product?.phrases" class="list-disc list-inside">
                                <li class="text-white" *ngFor="let phrase of product?.phrases.H">
                                    <small class="text-white">{{phrase}}{{" - "}}{{"PHRASE_" + phrase | translate}}{{" "}}</small>
                                </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Image Gallery -->
            <div class="p-4">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div class="flex flex-col" *ngFor="let image of pictures; let i = index">
                        <div class="flex flex-row">
                            <a>
                                <img title="{{ 'ATTACHE_PICTURES' | translate }}" [src]="image" style="max-height: 400px; max-width: 600px;"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="p-4">
                <div>
                    <h2 class="text-2xl font-bold mb-4 uppercase border-b-2 pb-2">
                        {{ "RESPONSIBLE" | translate }}
                    </h2>
                    <p class="text-xl">
                        {{ substitution?.responsible }}
                    </p>
                    <p class="text-xl">{{ 'DATE' | translate }}: {{ substitution?.created_date | date: 'yyyy-MM-dd' }}</p>
                </div>
            </div>

            <!-- Description Section -->
            <div class="p-4">
                <div>
                    <h2 class="text-2xl font-bold mb-4 uppercase border-b-2 pb-2">
                        {{ "DESCRIPTION" | translate }}
                    </h2>
                    <p class="text-xl break-all whitespace-pre-line">
                        {{ substitution?.comment }}
                    </p>
                </div>
            </div>

            <!-- Description Section -->
            <div class="p-4">
                <div>
                    <h2 class="text-2xl font-bold mb-4 uppercase border-b-2 pb-2">
                        {{ "ESTIMATION" | translate }}
                    </h2>
                    <p class="text-xl break-all whitespace-pre-line">
                        {{ substitution?.estimation }}
                    </p>
                </div>
            </div>

            <!-- Result Section -->
            <div class="p-4">
                <div>
                    <h2 class="text-2xl font-bold mb-4 uppercase border-b-2 pb-2">
                        {{ "ACTION_PRODUCT" | translate }}
                    </h2>
                    <p *ngIf="substitution.checkbox_keep==1" class="font-bold text-xl break-all whitespace-pre-line">
                        {{ "KEEP" | translate }}
                    </p>
                    <p *ngIf="substitution.checkbox_outphase_alt1==1" class="font-bold text-xl break-all whitespace-pre-line">
                        {{"OUTPHASE_ALT1" | translate}}
                    </p>
                    <p *ngIf="substitution.checkbox_outphase_alt2==1" class="font-bold text-xl break-all whitespace-pre-line">
                        {{"OUTPHASE_ALT2" | translate}}
                    </p>
                    <p *ngIf="substitution.checkbox_outphase_alt3==1" class="font-bold text-xl break-all whitespace-pre-line">
                        {{"OUTPHASE_ALT3" | translate}}
                    </p>
                    <p class="text-xl break-all whitespace-pre-line mt-2">
                        {{ substitution?.checkbox_comment_1 }}
                    </p>
                </div>
            </div>

            <!-- Result Section -->
            <div class="p-4">
                <div>
                    <h2 class="text-2xl font-bold mb-4 uppercase border-b-2 pb-2">
                        {{ "ACTION_FOR_REMAINGING_PRODUCT" | translate }}
                    </h2>
                    <p *ngIf="substitution.checkbox_endusage==1" class="font-bold text-xl break-all whitespace-pre-line">
                        {{ "END_CONSUMED" | translate }}
                    </p>
                    <p *ngIf="substitution.checkbox_deponi==1" class="font-bold text-xl break-all whitespace-pre-line">
                        {{"DEPONI" | translate}}
                    </p>
                    <p *ngIf="substitution.checkbox_other==1" class="font-bold text-xl break-all whitespace-pre-line">
                        {{"OTHER_ACTION" | translate}}
                    </p>
                    <p class="text-xl break-all whitespace-pre-line mt-2">
                        {{ substitution?.checkbox_comment_2 }}
                    </p>
                </div>
            </div>
        </div>
        <div class="page-break"></div>
    </div>

    <div *ngIf="substitution" id="content" class="content p-4">
        <div class="page">
            <!-- Candidates Section -->
            <div class="p-4">
                <div>
                    <h2 class="text-2xl font-bold mb-4 uppercase border-b-2 pb-2">
                        {{ "CHEMICAL_CANDIDATES" | translate }}
                    </h2>
                    <table class="table-auto w-full bg-white mb-4 border border-gray-400 mt-4">
                        <tbody>
                            <tr *ngFor="let workstep of substitution?.worksteps; let i = index">
                                <td class="table-row-column">{{workstep.product?.name}}</td>
                                <td class="table-row-column">{{workstep.product?.supplier_name}}</td>
                                <td class="table-row-column">{{workstep.comment}}</td>
                                <td class="table-row-column">{{workstep.product?.risk_prio}}</td>
                                <td class="table-row-column">
                                    <div class="flex flex-row">
                                        <span *ngFor="let pictogram of workstep.product?.pictograms" tooltips tooltip-smart="true" title={{pictogram.description}}>
                                            <img class="w-6 h-6" title={{pictogram.description}} src="/images/pictograms/{{pictogram.filename}}-small.png" />
                                        </span>
                                    </div>
                                </td>
                                <td class="table-row-column" *ngIf="workstep.approved==1">
                                    <div class="flex flex-row justify-center items-center">
                                        <img class="w-4 h-4 mr-1 pt-1" src="/images/icons/check-mark-svgrepo-com.svg" area-hidden="true" title="{{'APPROVED' | translate}}"/>
                                        <p>{{'APPROVED2' | translate}}</p>
                                    </div>
                                </td>
                                <td class="table-row-column" *ngIf="workstep.approved==0">
                                    <div class="flex flex-row justify-center items-center">
                                        <img class="w-4 h-4 mr-1 pt-1" src="/images/icons/gui-ban-svgrepo-com.svg" area-hidden="true" title="{{'NOT_APPROVED' | translate}}"/>
                                        <p>{{'NOT_APPROVE' | translate}}</p>
                                    </div>
                                </td>
                                <td class="table-row-column" *ngIf="workstep.approved==null">
                                    <div class="flex flex-row justify-center items-center">
                                        <img class="w-4 h-4 mr-1 pt-1" src="/images/icons/question-round-svgrepo-com.svg" area-hidden="true" title="{{'ONGOING' | translate}}"/>
                                        <p>{{'ONGOING' | translate}}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
