import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';
import * as Honeybadger from "@honeybadger-io/js";
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent{

  title = 'cDoc';

  currentUser: any=null;
  isAuthenticated: boolean = false;
  toggleNav: boolean = false;

  constructor(private authService: AuthService, translate: TranslateService) {
    this.isAuthenticated = this.authService.getIsAuth();
    translate.addLangs(['en', 'sv', 'fi']);
    translate.setDefaultLang('sv');
    let usedLanguage = localStorage.getItem('usedLanguage');
    if (usedLanguage == null) {
      translate.use('sv');
      localStorage.setItem('usedLanguage', 'sv');
    } else {
      translate.use(usedLanguage);
    }
  }
  
  ngOnInit() {
    this.toggleNav = false;
    let usedNav = localStorage.getItem('toggleNav');
    if (usedNav == 'true') {
      this.toggleNav = true;
    }

    let data = localStorage.getItem('currentUser', );
    if (data != null && data != undefined)
    {
      this.currentUser = JSON.parse(data);

      if (this.currentUser.is_guest == 1) {
        this.toggleNav = true;
      }
    }
  }

  toggleNavigation() {
    if (this.currentUser != null && this.currentUser.is_quest == 1) {
      return;
    }
    else {
      this.toggleNav = !this.toggleNav;

      if (this.toggleNav == true) {
        localStorage.setItem('toggleNav', 'true');
      }
      else {
        localStorage.setItem('toggleNav', 'false');
      }
    }
  }
}