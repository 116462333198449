import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProductService } from 'src/app/services/product.service';
import { AssessmentService } from 'src/app/services/assessment.service';
import * as _ from "lodash";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take, shareReplay } from 'rxjs/operators';
import * as cloneDeep from 'lodash/cloneDeep';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { setOriginalNode } from 'typescript';
declare var $: any;

@Component({
  selector: 'app-workstep',
  templateUrl: './workstep.component.html',
  styleUrls: ['./workstep.component.css']
})
export class WorkstepComponent implements OnInit {

  itemsPerPage: number = 10;
  pageNumber: number = 1;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  currentUser: any;
  listAlldepartments: any;
  loadingData: boolean = false;
  workstep: any;
  prev_workstep: any;
  subscribesToSds: any;
  subscribesToGrb: any;
  products: any;
  assessment: any;
  questionList: any;
  riskQuestion: any;
  answers: any;
  workstepId: any = null;
  product: any;
  protectiveImages: any = [];

  workstepSearch = {
    all:"",
  };

  productAddSearch = {
    all:"",
    product_name: "",
    supplier_name: ""
  };

  constructor(private router: Router,
              private utilService: UtilService,
              private authService: AuthService,
              private productService: ProductService,
              private translate: TranslateService,
              private organisationService: OrganisationService,
              private formBuilder:FormBuilder,
              private assessmentService: AssessmentService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.route.params.pipe(take(1)).subscribe(params => {
      this.workstepId = params['id'];
    });

    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));
    if ((this.organisation == null) || (this.selectedDepartment == null) || (this.selectedOrganisation == null)) {
      this.logout();
    }

    if ((this.organisation == null) || (this.selectedDepartment == null) || (this.selectedOrganisation == null)) {
      this.logout();
    }

    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined)
    {
      this.logout();
    }
    else
    {
      this.currentUser = JSON.parse(data);
    }

    this.subscribesToSds = this.getSubscription('subscription_sds');
    this.subscribesToGrb = this.getSubscription('subscription_grb');

    this.answers = [{value: 0}, {value: 0}, {value: 0}];
    this.riskQuestion = [
      {
          id: 1,
      },
      {
          id: 2,
      },
      {
          id: 3,
      },
      {
          id: 4,
      },
      {
          id: 5,
      }
    ];

    this.assessment = JSON.parse(localStorage.getItem('assessment_activity', ));

    if (this.workstepId == 0) {

      this.workstep = {
        id: "",
        date: new Date(),
        risk_description: "",
        heading: "",
        place: "",
        comments: "",
        consequence: 0,
        propability: 0,
        avoidability: 0,
        risk_level: 0,
        image_mask: 0,
        image_boots: 0,
        image_breath: 0,
        image_clothes: 0,
        image_gloves: 1,
        image_glasses: 1,
        department: this.selectedDepartment.name,
        info: [],
        products: [],
        protectiveArray: [],
        pictograms: [],
      };
    } else {
      if (this.assessment) {
        this.workstep = this.assessment.worksteps[this.workstepId-1];
      }
    }

    this.protectiveImages = [{"name": "image_mask", "filename": "mask"}, {"name": "image_boots", "filename": "boots"}, {"name": "image_breath", "filename": "breath"}, {"name": "image_clothes", "filename": "clothes"},{"name": "image_gloves", "filename": "gloves"}, {"name": "image_glasses", "filename": "glasses"}];

    if (this.workstep) {
      this.workstep.pictograms = [];
      this.workstep.protectiveArray = [];

      if (this.answers) {
        this.answers[0].value = this.workstep.consequence;
        this.answers[1].value = this.workstep.propability;
        this.answers[2].value = this.workstep.avoidability;
      }

      for (var i = 0; i < this.protectiveImages.length; i++)
      {
        if (this.workstep[this.protectiveImages[i].name] === 1)
        {
          this.workstep.protectiveArray.push(this.protectiveImages[i]);
        }
      }
    }

    this.getAllProducts();
  }

  // Method to check if two objects are different
  hasPostChanged(): boolean {
    return JSON.stringify(this.workstep) != JSON.stringify(this.prev_workstep);
  }

  cancel() {
    if (this.hasPostChanged() == true)
    {
      Swal.fire({
        title: this.translate.instant('SAVE') + "?",
        text: this.translate.instant('SAVE_POST'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.translate.instant('NO'),
        confirmButtonText: this.translate.instant('YES')
      }).then((result) => {
        if (result.isConfirmed) {
          this.submit();
        } else {
          if (this.assessment.id == undefined || this.assessment.id == null)
          {
            this.router.navigate(['/assessmentadd']);
          }
          else {
            this.router.navigate(['/assessmentedit', this.assessment.id]);
          }
        }
      })
    }
    else
    {
      if (this.assessment.id == undefined || this.assessment.id == null)
      {
        this.router.navigate(['/assessmentadd']);
      }
      else {
        this.router.navigate(['/assessmentedit', this.assessment.id]);
      }
    }
  }

  submit() {

    if (this.workstepId==0)
    {
        if (!this.assessment.worksteps)
        {
          this.assessment.worksteps = [];
        }
        this.assessment.worksteps.push(this.workstep);
    }
    localStorage.setItem('assessment_activity', JSON.stringify(this.assessment));

    if (this.assessment.id == undefined || this.assessment.id == null)
    {
      this.router.navigate(['/assessmentadd']);
    }
    else {
      this.router.navigate(['/assessmentedit', this.assessment.id]);
    }
  }

  setSelectedOption(index, value) {

    if (this.workstep)
    {
      this.answers[index].value = value;
      if (index == 0)
      {
        this.workstep.consequence = value;
      }

      if (index == 1)
      {
        this.workstep.propability = value;
      }

      this.workstep.risk_level = "Låg";
    }
  }

  protectiveInWorkstep(name){

    if (this.workstep) {
      if (this.workstep[name]){
          return true;
      }
    }
    return false;
  }

  addOrRemoveProtective(name)
  {
    if (this.protectiveInWorkstep(name))
    {
      this.workstep[name] = 0;
      for(var i = 0; i < this.protectiveImages.length; i++)
      {
        if(this.protectiveImages[i].name === name){
          this.workstep.protectiveArray.splice(i,1);
        }
      }
    }
    else
    {
      this.workstep[name] = 1;
      for (var i = 0; i < this.protectiveImages.length; i++)
      {
        if(this.protectiveImages[i].name === name){
          this.workstep.protectiveArray.push(this.protectiveImages[i]);
        }
      }
    }
  }

  getAllProducts() {

    this.assessmentService.getAllProducts();
    this.assessmentService.getAssessmentDataListener().pipe(take(1)).subscribe({
      next: products => {
        if (products == null) {
        }
        else {
          this.questionList = [
            {
                id: 1,
                heading: this.translate.instant("CONSEQUENCE"),
                question: this.translate.instant("ASSESSMENT_QUESTION_1"),
                typeDescription: this.translate.instant("ASSESSMENT_DESCRIPTION_1"),
                weight: 1,
                followUp: []
            },
            {
                id: 2,
                heading: this.translate.instant("PROPABILITY"),
                question: this.translate.instant("ASSESSMENT_QUESTION_2"),
                typeDescription: this.translate.instant("ASSESSMENT_DESCRIPTION_2"),
                weight: 1,
                followUp: []
            }
          ];
          this.products = products;
          let workstepLength = this.workstep.products.length,productLength = this.products.length;
          for(let n=0;n<workstepLength;n++) {
            for(let m=0;m<productLength;m++) {
              if(this.workstep.products[n].product_id==this.products[m].product_id) {
                this.workstep.products[n].protectiveArray=this.products[m].protectiveArray;
                this.workstep.products[n].pictograms=this.products[m].pictograms;
                break;
              }
            }
          }
        }
        this.prev_workstep = _.cloneDeep(this.workstep);
      },
      error: error => {
        console.log("Error: ", error);
      }
    });
  }

  getSubscription(subscription) {
    return (this.utilService.subscribesTo(subscription));
  }

  addProduct(product) {
    var i, productExists = false, addToList = true;

    if(this.workstep.products){
      for (i = 0; i < this.workstep.products.length; i += 1) {
        if (product.product_id === this.workstep.products[i].id) {
          delete product.amount;
          delete product.annual_consumption;
          delete product.maximum_amount;
          delete product.unit;
          delete product.group;
          delete product.isSelected;
          this.workstep.products.splice(i, 1);
          addToList = false;
          break;
        }
      }
    }

    if (addToList) {
    // Check if product is already added to organisation
      if(this.workstep.products){
        for (i = 0; i < this.workstep.products.length; i += 1) {
            if (this.workstep.products[i].product_id === product.product_id) {
                productExists = true;
                delete product.isSelected;
                break;
            }
        }
      }
      if (productExists) {
          delete product.isSelected;
          Swal.fire(this.translate.instant("PRODUCT_ALREADY_EXISTS"),
                      "\"" + product.name + "\" " + this.translate.instant("PRODUCT_ALREADY_EXISTS"),
                      "error");
      } else {
        product.isSelected=true;
        product.name=product.product_name;
        product.id=product.product_id;
        if(!this.workstep.products||this.workstep.products.length<1){
          this.workstep.products=[];
          this.workstep.products.push(product);
        } else {
          this.workstep.products.push(product);
        }
      }
    }
  }

  removeProduct(product) {
    var i;

    delete product.isSelected;

    for (i = 0; i < this.workstep.products.length; i += 1) {
      if (product.id === this.workstep.products[i].id) {
        delete product.amount;
        delete product.annual_consumption;
        delete product.maximum_amount;
        delete product.unit;
        delete product.group;
        this.workstep.products.splice(i, 1);
        break;
      }
    }
  }

  openSdsInNewWindow(product) {
    var filename = product.product_name + '.pdf';
    this.productService.getSdsAsTrustedResourceUrl(product.product_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
        }
        else
        {
          var opened=window.open(pdfContent,"_blank");
          if (this.utilService.isMobileDevice()!=true && opened ) {
            const htmlContent = `
              <html>
                <head>
                  <title>${product.product_name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"), "error");
      }
    })
  }

  openGrbInNewWindow(product) {
    var filename = product.product_name + '.pdf';
    this.productService.getGrbAsTrustedResourceUrl(product.product_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {
          Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
        }
        else
        {
          var opened=window.open(pdfContent,"_blank");
          if (this.utilService.isMobileDevice()!=true && opened ) {
            const htmlContent = `
              <html>
                <head>
                  <title>${product.product_name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_GRB"), this.translate.instant("COULD_NOT_GET_GRB_WARNING"), "error");
      }
    })
  }

  logout() {
    this.authService.logout();
  }

  openExtSdsInNewWindow(prod) {
    var filename = prod.product_name + '.pdf';
    this.productService.getExtSdsAsTrustedResourceUrl(prod.product_id);
    this.productService.getDataSheetUpdatedListener().pipe(take(1)).subscribe({
      next: pdfContent => {
        if (pdfContent == null) {}
        else {
          var opened=window.open(pdfContent,"_blank");
          if (this.utilService.isMobileDevice()!=true && opened ) {
            const htmlContent = `
              <html>
                <head>
                  <title>${prod.product_name}</title>
                </head>
                <body>
                  <iframe src="${pdfContent}" width="100%" height="100%" style="border:none;"></iframe>
                </body>
              </html>
            `;
            opened.document.write(htmlContent);
            opened.document.close();
          } else {
            window.location.href = pdfContent;
          }
        }
      },
      error: error => {
        Swal.fire(this.translate.instant("COULD_NOT_GET_SDS"), this.translate.instant("COULD_NOT_GET_SDS_WARNING"));
        console.log("Error: ", error);
      }
    })
  }
}

