<div *ngIf="currentUser&&(currentUser.is_super_user||currentUser.is_admin||currentUser.is_useradmin||currentUser.is_local_admin)" class="flex flex-col md:flex-row max-w-screen">
  <div [hidden]="!loadingChangedProducts" class="flex flex-row w-full">
    <!-- Form Container -->
    <div class="flex flex-col items-left bg-white w-full md:w-full rounded overflow-hidden shadow-lg mb-4 p-7 md:ml-4">
      <p class="text-xl font-bold">{{"HISTORY_STATISTICS" | translate}}</p>
      <p class="text-lg mb-4">{{"HISTORY_STATISTICS_MESSAGE" | translate}}</p>
      <hr/>
      <div class="flex justify-left mt-4">
        <div class="flex flex-col space-y-4">
          <div class="flex items-center">
            <input type="radio" class="hidden" id="option3" name="options" [disabled]="loadingChangedProducts"
                  (click)="depChoice(1,true)" [checked]="departmentChoice==1">
            <label for="option3" class="flex items-center cursor-pointer">
              <span class="w-5 h-5 inline-block mr-2 rounded-full border border-gray-500 flex items-center justify-center">
                <span class="block w-3 h-3 bg-blue-500 rounded-full" *ngIf="departmentChoice==1"></span>
              </span>
              {{"WHOLE_COMPANY" | translate}}
            </label>
          </div>
          <div class="flex items-center">
            <input type="radio" class="hidden" id="option4" name="options" [disabled]="loadingChangedProducts"
                  (click)="depChoice(2,true)" [checked]="departmentChoice==2">
            <label for="option4" class="flex items-center cursor-pointer">
              <span class="w-5 h-5 inline-block mr-2 rounded-full border border-gray-500 flex items-center justify-center">
                <span class="block w-3 h-3 bg-blue-500 rounded-full" *ngIf="departmentChoice==2"></span>
              </span>
              {{"THIS_DEPARTMENT_AND_SUBDEPARTMENTS" | translate}}
            </label>
          </div>
          <div class="flex items-center">
            <input type="radio" class="hidden" id="option5" name="options" [disabled]="loadingChangedProducts"
                  (click)="depChoice(3,true)" [checked]="departmentChoice==3">
            <label for="option5" class="flex items-center cursor-pointer">
              <span class="w-5 h-5 inline-block mr-2 rounded-full border border-gray-500 flex items-center justify-center">
                <span class="block w-3 h-3 bg-blue-500 rounded-full" *ngIf="departmentChoice==3"></span>
              </span>
              {{"THIS_DEPARTMENT" | translate}}
            </label>
          </div>
        </div>
      </div>
      <div class="flex justify-center">
        <div class="flex flex-col">
          <div class="flex flex-row items-end md:ml-4">
            <div class="mr-40">
              <label class="font-bold mb-1">{{"STARTDATE" | translate}}</label><br>
              <input title="{{'STARTDATE' | translate}}" class="border border-gray p-1" type="date" [ngModel]="changedProductsFrom | date:'yyyy-MM-dd'"
                    (ngModelChange)="$event?changedProductsFrom = $event:null" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="mr-40">
              <button class="button-cdocblue" type="button" (click)="getProducts()">{{"UPDATE" | translate}}</button>
            </div>
            <div>
              <label class="font-bold mb-1">{{"STOPDATE" | translate}}</label><br>
              <input title="{{'STOPDATE' | translate}}" class="border border-gray p-1" type="date" [ngModel]="changedProductsTo | date:'yyyy-MM-dd'"
                      (ngModelChange)="$event?changedProductsTo= $event:null" [ngModelOptions]="{standalone: true}">
            </div>
        </div>
        <div><label *ngIf="errorDate!=''">{{errorDate}}</label></div>
      </div>
    </div>
    <div [hidden]="loadingChangedProducts" class="flex justify-center items-center mt-10">
      <div *ngIf="products&&products?.length>0" class="flex flex-col items-right bg-white pt-7 md:ml-4 z-10">
        <div class="flex-grow">
          <div class="flex flex-row shadow-lg mt-6">
            <div class="flex flex-col items-center mt-4 bg-white w-full md:w-full rounded overflow-hidden">
              <highcharts-chart
                [Highcharts]="Highcharts"
                [options]="riskPrioChartConfig1"
                [(update)]="updateRiskPrioChart1"
                id = "highchart-yearyreportdiagram">
              </highcharts-chart>
            </div>
            <div class="flex flex-col items-center mt-4 bg-white w-full md:w-full rounded overflow-hidden">
              <highcharts-chart
                [Highcharts]="Highcharts"
                [options]="riskPrioChartConfig2"
                [(update)]="updateRiskPrioChart2"
                id = "highchart-yearyreportdiagram2">
              </highcharts-chart>
            </div>
          </div>

          <div class="flex flex-row shadow-lg mt-6">
          <div class="flex flex-col items-center mt-4 bg-white w-full md:w-full rounded overflow-hidden" *ngIf=" groupInfo && groupInfo?.length > 0">
            <highcharts-chart
              [Highcharts]="Highcharts"
              [options]="productgroupChartConfig1"
              [(update)]="updateProductGroupChart1"
              id = "highchart-yearyreportdiagram">
            </highcharts-chart>
          </div>
          <div class="flex flex-col items-center mt-4 bg-white w-full md:w-full rounded overflow-hidden" *ngIf=" groupInfo2 && groupInfo2?.length > 0">
            <highcharts-chart
              [Highcharts]="Highcharts"
              [options]="productgroupChartConfig2"
              [(update)]="updateProductGroupChart2"
              id = "highchart-yearyreportdiagram2">
            </highcharts-chart>
          </div>
        </div>

        <div class="flex flex-row shadow-lg mt-6">
          <div class="flex flex-col items-center mt-4 bg-white w-full md:w-full rounded overflow-hidden" *ngIf=" restriktionList[0] && restriktionList[0].length > 0">
            <highcharts-chart
              [Highcharts]="Highcharts"
              [options]="restrictionChartConfig1"
              [(update)]="updateRestrictionChart1"
              id = "highchart-yearyreportdiagram">
            </highcharts-chart>
          </div>
          <div class="flex flex-col items-center mt-4 bg-white w-full md:w-full rounded overflow-hidden" *ngIf=" restriktionList[1] && restriktionList[1].length > 0">
            <highcharts-chart
              [Highcharts]="Highcharts"
              [options]="restrictionChartConfig2"
              [(update)]="updateRestrictionChart2"
              id = "highchart-yearyreportdiagram2">
            </highcharts-chart>
          </div>
        </div>

        <div class="flex flex-row shadow-lg mt-6">
          <div class="flex flex-col items-center mt-4 bg-white w-full md:w-full rounded overflow-hidden" *ngIf=" productsById[0] && productsById[0].length > 0">
            <highcharts-chart
              [Highcharts]="Highcharts"
              [options]="supplierChartConfig1"
              id = "highchart-supplierdiagram">
            </highcharts-chart>
          </div>
          <div class="flex flex-col items-center mt-4 bg-white w-full md:w-full rounded overflow-hidden" *ngIf=" productsById[1] && productsById[1].length > 0">
            <highcharts-chart
              [Highcharts]="Highcharts"
              [options]="supplierChartConfig2"
              id = "highchart-supplierdiagram">
            </highcharts-chart>
          </div>
          </div>

          <div class="flex flex-row shadow-lg mt-6">
            <div class="flex flex-col items-center mt-4 bg-white w-full md:w-full rounded overflow-hidden" *ngIf="CMRAChart1 == true">
              <highcharts-chart
                [Highcharts]="Highcharts"
                [options]="CMRAChartConfig1"
                [(update)]="updateCMRAChart1"
                id = "highchart-supplierdiagram">
              </highcharts-chart>
            </div>
            <div class="flex flex-col items-center mt-4 bg-white w-full md:w-full rounded overflow-hidden" *ngIf="CMRAChart2 == true">
              <highcharts-chart
                [Highcharts]="Highcharts"
                [options]="CMRAChartConfig2"
                [(update)]="updateCMRAChart2"
                id = "highchart-supplierdiagram">
              </highcharts-chart>
            </div>
        </div>

        <div class="flex flex-row shadow-lg mt-6" *ngIf = "subscribesToSpecial">
          <div class="flex flex-col items-center mt-4 bg-white w-full md:w-full rounded overflow-hidden" *ngIf="SpecialChart1 == true">
            <highcharts-chart
              [Highcharts]="Highcharts"
              [options]="specialChartConfig1"
              [(update)]="updateSpecialChart1"
              id = "highchart-supplierdiagram">
            </highcharts-chart>
          </div>
          <div class="flex flex-col items-center mt-4 bg-white w-full md:w-full rounded overflow-hidden" *ngIf="SpecialChart2 == true">
            <highcharts-chart
              [Highcharts]="Highcharts"
              [options]="specialChartConfig2"
              [(update)]="updateSpecialChart2"
              id = "highchart-supplierdiagram">
            </highcharts-chart>
          </div>
      </div>

      <div class="flex flex-row shadow-lg mt-6">
        <div class="flex flex-col items-center mt-4 bg-white w-full md:w-full rounded overflow-hidden" *ngIf="pictogramChart1 == true">
          <highcharts-chart
            [Highcharts]="Highcharts"
            [options]="pictogramChartConfig1"
            [(update)]="updatePictogramChart1"
            id = "highchart-supplierdiagram">
          </highcharts-chart>
        </div>
        <div class="flex flex-col items-center mt-4 bg-white w-full md:w-full rounded overflow-hidden" *ngIf="pictogramChart2 == true">
          <highcharts-chart
            [Highcharts]="Highcharts"
            [options]="pictogramChartConfig2"
            [(update)]="updatePictogramChart2"
            id = "highchart-supplierdiagram">
          </highcharts-chart>
        </div>
    </div>

    <div class="flex flex-row shadow-lg mt-6">
      <div class="flex flex-col items-center mt-4 bg-white w-full md:w-full rounded overflow-hidden" *ngIf="environmentChart1 == true">
        <highcharts-chart
          [Highcharts]="Highcharts"
          [options]="environmentChartConfig1"
          [(update)]="updateEnvironmentChart1"
          id = "highchart-supplierdiagram">
        </highcharts-chart>
      </div>
      <div class="flex flex-col items-center mt-4 bg-white w-full md:w-full rounded overflow-hidden" *ngIf="environmentChart2 == true">
        <highcharts-chart
          [Highcharts]="Highcharts"
          [options]="environmentChartConfig2"
          [(update)]="updateEnvironmentChart2"
          id = "highchart-supplierdiagram">
        </highcharts-chart>
      </div>
  </div>
      </div>
    </div>
  </div>
  <div *ngIf="loadingChangedProducts" class="flex justify-center items-center mt-10">
    <div class="loader w-10 h-10"></div>
  </div>
</div>

    </div>

</div>
