import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { take } from 'rxjs/operators';
import { ManufacturerService } from 'src/app/services/manufacturer.service';
import { OrganisationService } from "src/app/services/organisation.service";
import { SareqService } from "src/app/services/sareq.service";
import { TranslateService } from "@ngx-translate/core";
import { UtilService } from "src/app/services/util.service";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { AuthService } from 'src/app/auth/auth.service';
import * as _ from "lodash";
declare var $: any;

@Component({
  selector: 'app-add-sareq',
  templateUrl: './add-sareq.component.html',
  styleUrls: ['./add-sareq.component.css']
})
export class AddSareqComponent implements OnInit {
  displayedManufacturers: any;
  filteredManufacturers: any;
  showmanufacturlist:boolean=false;
  showlanguagelist:boolean=false;
  tempsareqsuppliername;
  supplierFilter;
  securityfile: any;
  loadingData: boolean = false;
  sareqSearch = {
    all: "",
    name: "",
    manufacturer: ""
  };

  sareq: any = {
    id: "",
    name: "",
    eancodes: [],
    cas: "",
    eg: "",
    sareq: "",
    approved: 0,
    articlenumber: "",
    h_phrase: [],
    p_phrase: [],
    r_phrase: [],
    s_phrase: [],
    c_phrase: [],
    description: "",
    supplier_id: "",
    supplier_name: "",
    is_dangerous_to_the_environment: false,
    is_flammable: false,
    is_toxic: false,
    is_explosive: false,
    is_gas_under_pressure: false,
    is_oxidising: false,
    is_corrosive: false,
    is_caution: false,
    is_longer_term_health_hazards: false,
    kifs_is_harmful: false,
    kifs_is_toxic: false,
    kifs_is_corrosive: false,
    kifs_is_dangerous_to_the_environment: false,
    kifs_is_flammable: false,
    kifs_is_explosive: false,
    kifs_is_oxidising: false,
    kifs_is_warning: false,
    ice_general_information: "",
    ice_inhalation: "",
    ice_skin_contact: "",
    ice_eye_contact: "",
    ice_consumption: "",
    ice_symptom: "",
    prot_breath: "",
    prot_gloves: "",
    prot_glasses: "",
    prot_clothes: "",
    prot_hygiene: "",
    prot_breath_small: "",
    prot_gloves_small: "",
    prot_glasses_small: "",
    prot_clothes_small: "",
    mutagenicity: "",
    cancerogenity: "",
    reproduction_toxicity: "",
    risk_name: "",
    risk_body: "",
    risk_prio: 0,
    risk_file_id: "",
    picture_file_id: "",
    footprint_water: 0,
    footprint_air: 0,
    footprint_product: 0,
    footprint_waste: 0,
    classification: "",
    sds_file_id: "",
    productsheet_file_id: "",
    esdb_file_id: "",
    rework_date: new Date(),
    replaces_date: new Date(),
    print_date: new Date(),
    version: "",
    cdoc_date: new Date(),
    manufacturer: { name: "", id: "" },
    substances: { name: "", cas: "", eg: "", min: "", max: "" },
    com_use: "",
    com_use_short: "",
    eco_eu: null,
    eco_svanen: null,
    eco_gec: null,
    training: "",
    health_control: "",
    fitness_for_duty: "",
    no_special_demands: 0,
    information: "",
    hygiene_limits: 0,
    no_hygiene_limits: 0,
    allergic: null,
    hygiene_limits_comment: "",
    handling_emissions: "",
    handling: "",
    handling_storage: "",
    handling_waste: "",
    handling_fire_measures: "",
    fire_ext_agent: "",
    fire_uns_ext_agent: "",
    combustion_products: "",
    image_mask: 0,
    image_boots: 0,
    image_breath: 0,
    image_clothes: 0,
    image_gloves: 1,
    image_glasses: 1,
    no_protection: 0,
    status: 0,
    assigned: "",
    lang: 0,
    override: null,
    hideAddLanguage: false,
    transport: [],
    other_dangers: "",
    esdb_available: 0,
    secretchem: 0,
    secretchemorg: "",
  };

  chemShow:boolean=false;

  transportToBeAdded: any = {
    transport_label_name_1: "",
    transport_label_name_2: "",
    transport_label_name_3: "",
    transport_label_name_4: "",
    transport_label_name_5: "",
    transport_un_nbr: "",
    adr_name: "",
    adr_class: "",
    adr_classification_code: "",
    adr_danger_code: "",
    adr_packing: "",
    adr_environment: 0,
    adr_other_info: "",
    adr_bulk_info: "",
    iata_name: "",
    iata_class: "",
    iata_packing: "",
    iata_environment: 0,
    iata_other_info: "",
    iata_bulk_info: "",
    imdg_name: "",
    imdg_class: "",
    imdg_ems: "",
    imdg_packing: "",
    imdg_environment: 0,
    imdg_other_info: "",
    imdg_bulk_info: "",
    adr_not_classfied: 0,
    adr_tunnel_restr: 0,
    adr_amount_limit: 0,
    adr_special_provision: 0,
  };
  sareqNewLangs;
  languagevalue;
  secretCompsname: any;
  secretComps: any;
  chem: any;
  currentUser: any;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private manufacturerService: ManufacturerService,
    private sareqService: SareqService,
    private organisationService: OrganisationService,
    private translate: TranslateService,
    private authService: AuthService)
  {}

  ngOnInit(): void {
    this.loadingData = true;
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.sareqNewLangs = [this.translate.instant("LANGUAGE_0"), this.translate.instant("LANGUAGE_1"), this.translate.instant("LANGUAGE_2") ];
    this.languagevalue = this.translate.instant(this.sareqNewLangs[0]);
    this.loadManufacturerList();
    this.loadSecretChem();

    this.secretCompsname="";
    this.chem="";
    this.loadingData = false;
  }

  loadManufacturerList() {
    this.manufacturerService.getAll();
    this.manufacturerService
      .getManufacturerDataListener()
      .pipe(take(1))
      .subscribe({
        next: (manufacturer) => {
          if (manufacturer == null) {
          } else {

            /* Temporary fix */
            /*var namesort = manufacturer.slice(0);
            namesort.sort(function (a, b) {
              var x = a.name.toLowerCase();
              var y = b.name.toLowerCase();
              return x < y ? -1 : x > y ? 1 : 0;
            });*/
            this.displayedManufacturers = _.sortBy(manufacturer, [function(o) { return o.name; }]);
            this.filteredManufacturers =  this.displayedManufacturers;
          }
        },
        error: (error) => {},
      });
  }

  onSearchChange(searchValue: string): void {
    this.filteredManufacturers =  this.displayedManufacturers;

    if (searchValue !== "")
    {
        this.filteredManufacturers = this.displayedManufacturers.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()));
    }
  }

  loadSecretChem(){
    this.organisationService.getSecretChem();
      this.organisationService.getOrganisationDataListener().pipe(take(1)).subscribe({
        next: secretChem => {
          if (secretChem == null) {

          }
          else
          {
            this.secretComps=secretChem;
          }
        },
        error: error => {

        }
      })

  }

  setName(manufacturer){
    this.sareq.supplier_name = manufacturer.name;
    this.sareq.supplier_id = manufacturer.id;
  }

  addManufacturer(){
    this.router.navigate(['/manufactureritem'],{ queryParams: { id: 'new'}});
  }

  setNameLanguage(language){
    this.sareq.lang = language;
    this.languagevalue=this.translate.instant(this.sareqNewLangs[language]);
    this.showlanguagelist=false;
  }

  cancel() {
      this.router.navigate(["/sareq"]);
  }

  setChem(index){
    this.sareq.secretchemOrg=this.secretComps[index].id;
    this.secretCompsname=this.secretComps[index].name;
    this.chem=this.secretComps[index].id;
    this.chemShow=false;
  }

  fileChange(event) {
      this.securityfile=event.target.files[0];
}

saveAddNew() {
  this.loadingData = true;
  if(this.sareq.supplier_name == ""){
    Swal.fire("tillverkare ska vara ifyllt");
    this.loadingData = false;
    return;
  }

  if (!this.securityfile)
  {
    Swal.fire("Säkerhetsdatablad (SDB) saknas!");
    this.loadingData = false;
    return;
  }

  if (this.sareq.name == "" || this.sareq.name == null)
  {
    Swal.fire("namn ska vara ifyllt!");
    this.loadingData = false;
    return;
  }
  this.sareqService.checkifnameexists(encodeURIComponent(this.sareq.name));
  this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
    next: (responce) => {
      var responceText="";
    if ( responce == null ) {
        Swal.fire(this.translate.instant("INCORRECT_NAME") + "\n" + this.sareq.name);
        this.loadingData = false;
        return;
      } else if(responce!='Not found'){
        responce?.rows.forEach(element => {
          responceText+='• ' + element.supplier_name+', '+element.name+', '+element.supplier_article_number+' </br>';
        });
        Swal.fire({
          title: "" + this.translate.instant("SIMILAR_NAME") + "",
          html:
          `<div style="font-family: Arial, sans-serif; color: #333;">
            <h2 style="color: #f00;">${this.translate.instant("PRODUCTS_SIMILAR_NAME_EXISTS")}</h2></br>
            <p style="text-align: left;"><b>${this.translate.instant("PRODUCT_WANT_TO_ADD")}</b></br>
            ${this.translate.instant("MANUFACTURER_NAME")}</br>
            • ${this.sareq.supplier_name},  ${this.sareq.name}</p></br>
            <p style="text-align: left;"><b>${this.translate.instant("EXISTING_PRODUCTS")}</b></br>
            ${this.translate.instant("MANUFACTURER_NAME_ARTICLENUMBER")}</br>
            ${responceText}</p></br>
            <p>${this.translate.instant("ARE_YOU_SURE")}</p>
          </div>`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.translate.instant("OK"),
          cancelButtonText: this.translate.instant("ABORT"),
        }).then((result) => {
          if (result.isConfirmed) {
            this.savethis();
          } else {
            this.loadingData = false;
          }
        });
      } else {
        this.savethis();
      }
    },
    error: error => {
      console.log("Update error: ", error);
      this.loadingData = false;
      this.utilService.onError(error);
    }
  });
};

savethis(){
  this.loadingData=true;
  var promises = [];
  if(this.securityfile){
    var getSecurityFile = new Promise((resolve, reject) => {
      // The timeout to make sure the id is different
      setTimeout( () =>{
        this.utilService.uploadFile(this.securityfile);
        this.utilService.getUtilDataListener().pipe(take(1)).subscribe({
          next: response => {
            resolve(response.filename);
          },
          error: error => {
            this.loadingData = false;
            this.utilService.onError(error);
          }
        })
      },1000)
    });
  }
  Promise.all([getSecurityFile]).then(values => {
    if(this.securityfile){
      this.sareq.sds_file_id = values[0];
    }
    if(this.sareq.sds_file_id&&this.sareq.sds_file_id.length < 0)
    {
      this.sareq.status = 2;
    }
    else {
      this.sareq.status = 4;
    }
    this.sareq.rework_date = this.sareq.rework_date.toLocaleDateString('sv');
    if(this.sareq.status==0){
      this.sareq.cdoc_date=new Date();
      this.sareq.cdoc_date = this.sareq.cdoc_date.toLocaleDateString('sv');
    }else{
      this.sareq.cdoc_date = this.sareq.cdoc_date.toLocaleDateString('sv');
    }
    this.sareq.replaces_date = this.sareq.replaces_date.toLocaleDateString('sv');
    this.sareq.print_date = this.sareq.print_date.toLocaleDateString('sv');
    this.sareq[ 'manufacturer' ] = {"id": this.sareq.supplier_id};
    this.sareq.assigned = this.currentUser.username;
    this.sareq.transport.push(this.transportToBeAdded);

      // this.sareq = this.utilService.serialize(this.sareq);
      if(this.sareq) {
        this.sareqService.createSareqWithId(this.sareq);
        this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
          next: () => {
            this.loadingData = false;
            this.router.navigate(["/sareq"]);
          },
          error: error => {
            console.log("Update error: ", error);
            this.loadingData = false;
            this.utilService.onError(error);
          }
        })
      } else {
        this.sareqService.updateSareqWithId(this.sareq.id, this.sareq);
        this.sareqService.getSareqDataListener().pipe(take(1)).subscribe({
          next: () => {this.loadingData = false;},
          error: error => {
            console.log("Update error: ", error);
            this.loadingData = false;
            this.utilService.onError(error);
          }
        })
      }
    });
  }

  logout() {
    this.authService.logout();
  }

  adjustTextareaHeight(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto'; // Reset the height
    const extraRowHeight = 0; // Adjust this value based on your line height
    textarea.style.height = `${textarea.scrollHeight + extraRowHeight}px`; // Set the height to the scroll height plus one extra row
  }
}
