
<div *ngIf="loadingData" class="flex justify-center items-center mt-4 mb-4">
  <div class="loader"></div>
</div>

<div class="overflow-auto mb-40 md:mb-10">
  <div class="flex flex-col min-h-screen border border-gray-300">
    <div class="flex flex-row justify-left">
      <div class="flex flex-col w-1/2 md:w-1/2 py-4">
        <button class="px-4" title="{{'BACK' | translate}}" (click)="goToProductList()">
          <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
            <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
            <path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
          </svg>
        </button>
      </div>
      <div class="flex flex-col w-1/3 md:w-1/3 py-4">
        <div *ngIf="departmentList && (departmentList.length > 0)" class="relative inline-block text-left w-full" appDropdown>
          <button type="button"class="text-black-900 flex items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
              <span class="text-sm">{{"CHANGE_PRODUCT_DEPARTMENT" | translate}}</span>
              <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </button>
          <div class="hidden dropdown-menu right-1 overflow-y-auto max-h-[600px] md:left-1 z-20" style="-webkit-overflow-scrolling: touch;" #dropdownMenuInside>
              <div class="py-1">
                  <div *ngFor="let department of departmentList"
                    class="dropdown-menu-item"
                    (click)="changeDepartment(department)"
                    [ngClass]="{'current': department.id === selectedDepartment.id}"
                    tabindex="1"><span class="text-sm font-bold mr-1">{{department.name}}</span> <span class="italic text-xs">({{departFullName(department.id)}})</span>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!loadingData && product" class="flex flex-row px-4 w-full py-2 mb-2 bg-cdocbanner">
      <div class="w-2/3 mt-2">
        <div class="flex flex-col">
          <p class="text-gray-200"><a (click)="showManufacturerInformation(product)" id="itemProductButton1"><strong>{{product.supplier_name}}</strong></a></p>
          <span class="text-white text-2xl font-bold mr-4">{{product?.product_name}}</span>
          <span *ngIf="product?.alternative_name" class="text-white font-bold">{{"ALTERNATIVE_NAME2" | translate}}: {{product.alternative_name}}</span>
          <span *ngIf="product?.ufi" class="text-white font-bold text-sm mt-1">{{"UFI" | translate}}: {{product?.ufi}}</span>
          <span *ngIf="product?.excluded" class="text-white font-bold text-sm mt-1">{{"EXCLUDED" | translate}}{{' '}}{{product?.excluded_comment}}</span>
          <label *ngIf="product?.replaced_by" class="bg-yellow rounded w-40 px-6 py-2 text-white mt-2">{{'Ersättare finns'}}</label>
          <label *ngIf="substitution!=null" class="bg-red-500 rounded w-60 px-6 py-2 font-bold text-white mt-2">{{ this.product.substitutionstatus }} substitution</label>
        </div>
      </div>
      <div *ngIf="subscribesToRiskAssessment && aggregatedLevel==false && product.LRB_risk" class="hidden md:block w-1/5 text-right">
        <span class="bg-risk-1-color w-20 h-20 text-white text-3xl font-bold rounded-full flex items-center justify-center"
              *ngIf="product&&product.LRB_risk&&product.LRB_risk==1"
              tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}{{': \n'}}{{'RISK_1_DESCRIPTION' | translate}}">
              {{product.LRB_risk}}</span>
        <span class="bg-risk-2-color w-20 h-20 text-white text-3xl font-bold rounded-full flex items-center justify-center"
              *ngIf="product&&product.LRB_risk&&product.LRB_risk==2"
              tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}{{': \n'}}{{'RISK_2_DESCRIPTION' | translate}}">
              {{product.LRB_risk}}</span>
        <span class="bg-yellow w-20 h-20 text-white text-3xl font-bold rounded-full flex items-center justify-center"
              *ngIf="product.LRB_risk&&product.LRB_risk==3"
              tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}{{': \n'}}{{'RISK_3_DESCRIPTION' | translate}}">
              {{product.LRB_risk}}</span>
        <span class="bg-risk-4-color w-20 h-20 text-white text-3xl font-bold rounded-full flex items-center justify-center"
              *ngIf="product&&product.LRB_risk&&product.LRB_risk==4"
              tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}{{': \n'}}{{'RISK_4_DESCRIPTION' | translate}}">
              {{product.LRB_risk}}</span>
        <span class="bg-risk-5-color w-20 h-20 text-white text-3xl font-bold rounded-full flex items-center justify-center"
              *ngIf="product&&product.LRB_risk&&product.LRB_risk==5"
              tooltips tooltip-smart="true" title="{{'LOCAL_RISK_ASSESSMENT' | translate}}{{': \n'}}{{'RISK_5_DESCRIPTION' | translate}}">
              {{product.LRB_risk}}</span>
      </div>
      <div *ngIf="!subscribesToRiskAssessment||(product&&!product.LRB_risk)" class="hidden md:block w-1/5 text-right">
        <span class="bg-risk-1-color w-20 h-20 text-white text-3xl font-bold rounded-full flex items-center justify-center"
              *ngIf="product&&product.risk_prio&&product.risk_prio==1"
              tooltips tooltip-smart="true" title="{{'RISK_1_DESCRIPTION' | translate}}">
              {{product.risk_prio}}</span>
        <span class="bg-risk-2-color w-20 h-20 text-white text-3xl font-bold rounded-full flex items-center justify-center"
              *ngIf="product&&product.risk_prio&&product.risk_prio==2"
              tooltips tooltip-smart="true" title="{{'RISK_2_DESCRIPTION' | translate}}">
              {{product.risk_prio}}</span>
        <span class="bg-yellow w-20 h-20 text-white text-3xl font-bold rounded-full flex items-center justify-center"
              *ngIf="product&&product.risk_prio&&product.risk_prio==3"
              tooltips tooltip-smart="true" title="{{'RISK_3_DESCRIPTION' | translate}}">
              {{product.risk_prio}}</span>
        <span class="bg-risk-4-color w-20 h-20 text-white text-3xl font-bold rounded-full flex items-center justify-center"
              *ngIf="product&&product.risk_prio&&product.risk_prio==4"
              tooltips tooltip-smart="true" title="{{'RISK_4_DESCRIPTION' | translate}}">
              {{product.risk_prio}}</span>
        <span class="bg-risk-5-color w-20 h-20 text-white text-3xl font-bold rounded-full flex items-center justify-center"
              *ngIf="product&&product.risk_prio&&product.risk_prio==5"
              tooltips tooltip-smart="true" title="{{'RISK_5_DESCRIPTION' | translate}}">
              {{product.risk_prio}}</span>
      </div>
      <div class="w-1/6 mt-2">
        <strong class="text-white">{{"AVAILABLE_LANGUAGES" | translate}}</strong>
        <ul>
            <li *ngFor="let langs of availableLangs">
                <a class="text-brightblue cursor-pointer" (click)="goOtherLang(langs)" [ngClass]="availableLangs&&(availableLangs?.length==1||currentLanguage==langs.lang)?'active':'inactive'" id="itemProductButton2">{{"LANGUAGE_" + langs.lang | translate}}</a>
            </li>
        </ul>
      </div>
      <div class="w-1/6 text-right m-4">
        <div class="relative inline-block text-left" appDropdown>
          <button title="{{'MENU' | translate}}" id="dropdownBtn" type="button" class="text-black flex items-start space-x-1 w-full rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
            <svg class="h-8 w-8 cursor-pointer"fill="#ffffff" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
              <path d="M.63 11.08zm.21.41v-.1zm.23.38L1 11.68zM1 11.68l-.11-.19zm-.21-.29c-.06-.1-.11-.21-.16-.31.05.1.1.21.16.31zm.32.54v-.06z"/>
              <path d="m11.26 12.63 1.83 1.09a7.34 7.34 0 0 0 1-.94 7.48 7.48 0 0 0 1.56-2.86l-1.74-1A5.29 5.29 0 0 0 14 8a5.29 5.29 0 0 0-.08-.9l1.74-1a7.45 7.45 0 0 0-1.33-2.58
              7.54 7.54 0 0 0-1.24-1.22l-1.83 1.04a6 6 0 0 0-1.11-.53v-2A8.55 8.55 0 0 0 7.94.53a8.39 8.39 0 0 0-2.26.3v2a7.23 7.23 0 0 0-1.12.54L2.78 2.28A7.46 7.46 0 0 0 .2 6.06l1.72 1a5.29 5.29
              0 0 0-.08.9 5.29 5.29 0 0 0 .08.9l-1.73 1a8 8 0 0 0 .43 1.15c.05.1.1.21.16.31v.1l.11.19.12.19v.06a7.69 7.69 0 0 0 1.64 1.78l1.81-1.08a7.23 7.23 0 0 0 1.12.54v2a8.39 8.39 0 0 0 2.26.31 8.56
              8.56 0 0 0 2.22-.3v-2a6 6 0 0 0 1.2-.48zm-2.39 1.52a7.57 7.57 0 0 1-.95.06 7.73 7.73 0 0 1-1-.06v-1.69a4.92 4.92 0 0 1-2.53-1.27l-1.54.92a6.22 6.22 0 0 1-1.08-1.61l1.56-.93a4.27 4.27 0 0 1
              0-3.17l-1.56-.92a6.11 6.11 0 0 1 1.12-1.62l1.56.93A5 5 0 0 1 7 3.53V1.82a7.73 7.73 0 0 1 1-.06 7.57 7.57 0 0 1 .95.06v1.72a4.9 4.9 0 0 1 2.4 1.26l1.59-.94a6.31 6.31 0 0 1 1.11 1.62l-1.6.94a4.35
              4.35 0 0 1 .3 1.58 4.44 4.44 0 0 1-.29 1.55l1.56.93a6.43 6.43 0 0 1-1.11 1.61l-1.58-.93a5 5 0 0 1-2.49 1.28z"/><path d="M7.92 5.49A2.59 2.59 0 0 0 5.25 8a2.59 2.59 0 0 0 2.67 2.51A2.6 2.6 0 0 0
              10.6 8a2.6 2.6 0 0 0-2.68-2.51zM8 9.2A1.35 1.35 0 0 1 6.55 8 1.35 1.35 0 0 1 8 6.7 1.35 1.35 0 0 1 9.39 8 1.35 1.35 0 0 1 8 9.2z"/>
            </svg>
          </button>
          <div id="dropdownMenu" class="hidden dropdown-menu right-1" #dropdownMenuInside>
              <div class="py-2">
                <a *ngIf="currentUser && allDepartmentsChoosen!=true && (currentUser.is_local_admin || currentUser.is_local_assignee || currentUser.is_admin ||
                  currentUser.is_useradmin || currentUser.is_super_user)" class="item" (click)="editProduct()" id="itemProductButton15"
                  id="itemProductButton15" class="dropdown-menu-item" tabindex="2">
                    {{"EDIT" | translate}}
                </a>
                <a *ngIf="subscribesToRiskAssessment && allDepartmentsChoosen!=true" (click)="goToLRB(product)" id="itemProductButton14" class="dropdown-menu-item" tabindex="1">
                  {{"DO_LRB" | translate}}
                </a>
                <a *ngIf="subscribesToSubstitution && currentUser && allDepartmentsChoosen!=true && (currentUser.is_local_admin || currentUser.is_local_assignee || currentUser.is_admin ||
                  currentUser.is_useradmin || currentUser.is_super_user)" (click)="sendToSubstitution(product)" id="itemProductButton16" class="dropdown-menu-item" tabindex="3">
                    {{"SEND_TO_SUBSTITUTION" | translate}}
                </a>
                <a *ngIf="subscribesToStamping && currentUser && allDepartmentsChoosen!=true && (currentUser.is_local_admin || currentUser.is_local_assignee || currentUser.is_admin ||
                  currentUser.is_useradmin || currentUser.is_super_user)" (click)="uploadStampedFile()" id="itemProductButton17" class="dropdown-menu-item" tabindex="4">
                    {{"ADDSTAMPING" | translate}}
                </a>
                <a *ngIf="subscribesToLabels" (click)="orderLabels()" id="itemProductButton18" class="dropdown-menu-item" tabindex="5">
                    {{"PRINT_LABELS" | translate}}
                </a>
                <a *ngIf="subscribesToProductAnalysis && allDepartmentsChoosen!=true &&
                  ((currentUser.is_local_admin || currentUser.is_local_assignee || currentUser.is_admin || currentUser.is_useradmin) || currentUser.is_super_user)"
                  (click)="goToRiskAnalys()" id="itemProductButton18" class="dropdown-menu-item" tabindex="6">
                    {{"RISK_ANALYSIS" | translate}}
                </a>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col md:flex-row w-full px-4 py-2 mb-2">
      <div class="flex flex-row w-full md:w-1/2">
        <div class="flex flex-col w-1/3 text-sm">
          <span class="text-gray-500 text-lg font-bold">{{"DEPARTMENT" | translate}}</span>
          <span class="break-words text-sm italic mb-4" *ngIf="!allDepartmentsChoosen||subDepartmentsExist==false">{{selectedDepartment?.name}}</span>
          <span class="break-words text-xs italic mb-4" *ngIf="allDepartmentsChoosen&&subDepartmentsExist==true">{{"ALL_DEPARTMENTS_TEXT" | translate}}

          </span>
        </div>
        <div *ngIf="product" class="flex flex-col w-full text-sm px-4">
          <span class="text-gray-500 text-lg font-bold">{{"DESCRIPTION" | translate}}</span>
          <span class="break-words italic" *ngIf="(subscribesToRiskAssessment && aggregatedLevel == false && product && product?.LRB_usage_area && product?.LRB_usage_area !== '')">{{product.LRB_usage_area}}</span>
          <span class="break-words italic" *ngIf="!(subscribesToRiskAssessment && aggregatedLevel == false && product && product.LRB_usage_area && product.LRB_usage_area !== '')">{{product.com_use}}</span>
        </div>
      </div>
      <div *ngIf="product" class="w-full md:w-1/2 flex flex-row flex-wrap px-4">
        <div *ngIf="( subscribesToRiskAssessment && aggregatedLevel == false && product && product.LRB_cdoc_comment && product.LRB_cdoc_comment !== '')" class="flex flex-col text-sm w-full md:w-2/3">
          <span class="text-gray-500 text-lg font-bold">{{"COMMENTS" | translate}}</span>
          <span class="break-words text-sm italic mb-4">{{product?.LRB_cdoc_comment}}</span>
        </div>
        <div *ngIf="((product?.department_info != '') || (product?.observation != ''))" class="flex flex-col w-full md:w-1/3">
          <span class="text-gray-500 text-lg font-bold">{{"OBSERVATION" | translate}}</span>
          <span class="break-words text-sm italic">{{product?.observation}}</span>
          <span *ngIf="( product && product.department_info && product.department_info !== '')" class="break-words text-sm italic">{{product?.department_info}}</span>
        </div>
      </div>
    </div>
    <div *ngIf="!loadingData && product" class="flex flex-col md:flex-row w-full px-2 py-2 mb-4 bg-white">
      <div class="md:w-1/5 mt-2 mr-2">
        <table class="table-auto mb-4 w-full" id="cataloguetable">
          <tbody>
            <tr>
              <td *ngIf="subscribesToRiskAssessment && aggregatedLevel==false && product.LRB_risk" class="table-row-column"><span class="text-sm">{{'LOCAL_RISK_LEVEL' | translate}}: <strong>{{product.LRB_risk}}</strong></span></td>
              <td *ngIf="!subscribesToRiskAssessment || aggregatedLevel==true || !product.LRB_risk" class="table-row-column"><span class="text-sm">{{'RISK_LEVEL' | translate}}: <strong>{{product.risk_prio ? product.risk_prio : "MISSING" | translate}}</strong></span></td>
            </tr>
            <tr>
              <td *ngIf = "allDepartmentsChoosen == true" class="table-row-column">
                <span class="text-sm">{{'AMOUNT_ON_DEPARTMENT' | translate}}:{{' '}}</span>
                <ng-container *ngIf="productViewAllDepartments.amount_volume">
                  <span class="text-sm"><strong>{{showSwedishDecimalComma(productViewAllDepartments.amount_volume)}}{{' '}}{{'l'}}</strong></span>
                </ng-container>

                <ng-container *ngIf="productViewAllDepartments.amount_weight">
                  <ng-container *ngIf="productViewAllDepartments.amount_volume">, </ng-container>
                  <span class="text-sm"><strong>{{showSwedishDecimalComma(productViewAllDepartments.amount_weight)}}{{' '}}{{'kg'}}</strong></span>
                </ng-container>

                <ng-container *ngIf="productViewAllDepartments.amount_other">
                  <ng-container *ngIf="productViewAllDepartments.amount_volume || productViewAllDepartments.amount_weight">, </ng-container>
                  <span class="text-sm"><strong>{{showSwedishDecimalComma(productViewAllDepartments.amount_other)}}</strong></span>
                </ng-container>
              </td>
              <td *ngIf="allDepartmentsChoosen != true && !product.amount" class="table-row-column"><span class="text-sm">{{'AMOUNT_ON_DEPARTMENT' | translate}}: <strong>{{"0"}} {{product.unit}}</strong></span></td>
              <td *ngIf="allDepartmentsChoosen != true && product.amount" class="table-row-column"><span class="text-sm">{{'AMOUNT_ON_DEPARTMENT' | translate}}: <strong>{{showSwedishDecimalComma(product.amount)}} {{product.unit}}</strong></span></td>
            </tr>
            <tr>
              <td *ngIf = "allDepartmentsChoosen == true" class="table-row-column">
                <span class="text-sm">{{'ANNUAL_CONSUMPTION' | translate}}:{{' '}}</span>
                <ng-container *ngIf="productViewAllDepartments.annual_consumption_volume">
                  <span class="text-sm"><strong>{{showSwedishDecimalComma(productViewAllDepartments.annual_consumption_volume)}}{{' '}}{{'l'}}</strong></span>
                </ng-container>

                <ng-container *ngIf="productViewAllDepartments.annual_consumption_weight">
                  <ng-container *ngIf="productViewAllDepartments.annual_consumption_volume">, </ng-container>
                  <span class="text-sm"><strong>{{showSwedishDecimalComma(productViewAllDepartments.annual_consumption_weight)}}{{' '}}{{'kg'}}</strong></span>
                </ng-container>

                <ng-container *ngIf="productViewAllDepartments.annual_consumption_other">
                  <ng-container *ngIf="productViewAllDepartments.annual_consumption_volume || productViewAllDepartments.amount_weight">, </ng-container>
                  <span class="text-sm"><strong>{{showSwedishDecimalComma(productViewAllDepartments.annual_consumption_other)}}</strong></span>
                </ng-container>
              </td>
              <td *ngIf="allDepartmentsChoosen != true && !product.annual_consumption" class="table-row-column"><span class="text-sm">{{'ANNUAL_CONSUMPTION' | translate}}: <strong>{{"0"}} {{product.unit}}</strong></span></td>
              <td *ngIf="allDepartmentsChoosen != true && product.annual_consumption" class="table-row-column"><span class="text-sm">{{'ANNUAL_CONSUMPTION' | translate}}: <strong>{{showSwedishDecimalComma(product.annual_consumption)}} {{product.unit}}</strong></span></td>
            </tr>
            <tr>
              <td *ngIf="!product.maximum_amount" class="table-row-column"><span class="text-sm">{{'MAXIMUM_AMOUNT' | translate}}: <strong>{{"0"}} {{product.unit}}</strong></span></td>
              <td *ngIf="product.maximum_amount" class="table-row-column"><span class="text-sm">{{'MAXIMUM_AMOUNT' | translate}}: <strong>{{showSwedishDecimalComma(product.maximum_amount)}} {{product.unit}}</strong></span></td>
            </tr>
            <tr>
              <td class="table-row-column" *ngIf = "allDepartmentsChoosen != true"><span class="text-sm">{{'PRODUCT_GROUP' | translate}}: <strong>{{product.group_name ? product.group_name : "MISSING" | translate}}</strong></span></td>
              <td class="table-row-column" *ngIf="allDepartmentsChoosen == true">
                {{'PRODUCT_GROUP' | translate}}: <strong>
                <span class="text-sm">
                  <span *ngIf="getUniqueGroupNameList(productViewAllDepartments).length > 0; else missing">
                    {{ getUniqueGroupNameList(productViewAllDepartments) }}
                  </span>
                  <ng-template #missing>
                    {{ "MISSING" | translate }}
                  </ng-template>
                </span>
                </strong>
              </td>
            </tr>
            <tr *ngIf="product.CMRA_Category && product.CMRA_Category.length > 0">
              <td class="table-row-column"><span class="text-sm">{{"CMRA_CATEGORY" | translate}}:
                <span class="font-bold" *ngFor="let CMRA_Category_Item of product.CMRA_Category let lastitem = last" tooltips tooltip-smart="true"
                  title="{{getCmraTitleTranslation(CMRA_Category_Item.title)}}">{{CMRA_Category_Item.name}}
                  <span *ngIf="product?.CMRA_Category?.length>1&&!lastitem">{{","}}</span></span>
                </span>
              </td>
            </tr>
            <tr>
              <td *ngIf="product.hygiene_limits" class="table-row-column">
                <div  class="flex flex-row items-center">
                  <span class="text-sm mr-1">{{'HYGIENE_LIMITS' | translate}}: </span>
                  <img title="{{'HYGIENE_LIMITS' | translate}}" class="w-4 h-4" src="/images/icons/check-mark-svgrepo-com.svg" area-hidden="true" />
                </div>
              </td>
              <td *ngIf="!product.hygiene_limits&&product.no_hygiene_limits" class="table-row-column">
                <div  class="flex flex-row items-center">
                  <span class="text-sm mr-1">{{'HYGIENE_LIMITS' | translate}}: </span>
                  <img title="{{'HYGIENE_LIMITS' | translate}}" class="w-3 h-3" src="/images/icons/cross-mark-svgrepo-com.svg" area-hidden="true" />
                </div>
              </td>
              <div *ngIf="!product.hygiene_limits&&!product.no_hygiene_limits">
                <td class="table-row-column"><span class="text-sm">{{'HYGIENE_LIMITS' | translate}}:</span> <span class="text-sm"><strong>{{"MISSING" | translate}}</strong></span></td>
              </div>
            </tr >
            <tr>
              <td *ngIf="product.allergic&&product.allergic==1" class="table-row-column">
                <div  class="flex flex-row items-center">
                  <span class="text-sm mr-1">{{'ALLERGIC' | translate}}: </span>
                  <img title="{{'ALLERGIC' | translate}}" class="w-4 h-4" src="/images/icons/check-mark-svgrepo-com.svg" area-hidden="true" />
                </div>
              </td>
              <td *ngIf="product.allergic&&product.allergic==0" class="table-row-column text-lg">
                <div  class="flex flex-row items-center">
                  <span class="text-sm mr-1">{{'ALLERGIC' | translate}}: </span>
                  <img title="{{'ALLERGIC' | translate}}" class="w-3 h-3" src="/images/icons/cross-mark-svgrepo-com.svg" area-hidden="true" />
                </div>
              </td>
              <div *ngIf="product.allergic!=1&&product.allergic!=0">
                <td class="table-row-column text-sm"><span class="text-sm">{{'ALLERGIC' | translate}}: <strong>{{"MISSING" | translate}}</strong></span></td>
              </div>
            </tr>
            <tr *ngIf="subscribesToFootprint&&showFootprint==true">
              <td class="table-row-column"><span class="text-sm">{{'FOOTPRINT_TO' | translate}}: <strong>{{footPrintText}}</strong></span></td>
            </tr>
            <tr *ngIf="subscribesToFootprint&&showFootprint==true">
              <td class="table-row-column"><span class="text-sm">{{"EMISSIONS" | translate}} {{"COMMENT" | translate}}: <strong>{{product.o_footprint_comment}}</strong></span></td>
            </tr>
            <tr>
              <td class="table-row-column"><span class="text-sm break-all">{{'ARTICLE_NUMBER' | translate}}:  <strong>{{product.articlenumber ? product.articlenumber : "MISSING" | translate}}</strong></span></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="1/2 md:w-1/5 mt-2 mr-2">
        <table class="table-auto bg-white mb-4 w-full" id="cataloguetable">
          <tbody>
              <tr>
                <td class="table-row-column" *ngIf="subscribesToSds && product && product.sds_file_id && product.sds_file_id!='' && product.sds_file_id!=null && ( product.esdb_available == 0 || ( product.esdb_available == 1 && ( product.esdb_file_id && product.esdb_file_id!='')))">
                  <div class="flex flex-row items-center text-sm cursor-pointer" (click)="openSdsInNewWindow(product)" id="itemProductButton9">
                    <img title="SDB" class="w-4 h-8 mr-1" id="SDBIcon" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> {{"SAFETY_DATA_SHEET" | translate}}
                  </div>
                </td>
                <td class="table-row-column" *ngIf="subscribesToSds && product && product.sds_file_id && product.sds_file_id!='' && product.sds_file_id!=null && ( product.esdb_available == 1 && ( !product.esdb_file_id || product.esdb_file_id==''))">
                  <div class="flex flex-row items-center text-sm cursor-pointer" (click)="openSdsInNewWindow(product)" id="itemProductButton9">
                    <img title="SDB" class="w-4 h-8 mr-1" id="SDBIcon" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> {{"SAFETY_EXT_DATA_SHEET" | translate}}
                  </div>
                </td>
              </tr>
              <tr>
                <td class="table-row-column" *ngIf="subscribesToSds && product && !product.sds_file_id && product.sds_file_id=='' || product.sds_file_id!=null && ( product.esdb_available == 1 && ( product.esdb_file_id || product.esdb_file_id!=''))">
                  <div class="flex flex-row items-center text-sm cursor-pointer" (click)="openSdsInNewWindow(product)" id="itemProductButton9">
                    <img title="SDB" class="w-4 h-8 mr-1" id="SDBIcon" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> {{"SAFETY_EXT_DATA_SHEET" | translate}}
                  </div>
                </td>
              </tr>
              <tr>
                <td class="table-row-column" *ngIf="subscribesToRiskAssessment && aggregatedLevel==false && subscribesToGrb && product && product?.LRB_id && product?.LRB_id!='' && product?.LRB_id!=null && product?.LRB_status == 3">
                  <div class="flex flex-row items-center text-sm cursor-pointer" (click)="openLrbInNewWindow(product)" id="itemProductButton14">
                    <img title="LRB" class="w-4 h-8 mr-1" id="LRBIcon" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> {{"LOCAL_RISK_ASSESSMENT" | translate}}
                  </div>
                </td>
                <td class="table-row-column" *ngIf="!subscribesToRiskAssessment || aggregatedLevel==true || (subscribesToGrb && product && !( product?.LRB_id && product?.LRB_id!='' && product?.LRB_id!=null && product?.LRB_status == 3))">
                  <div class="flex flex-row items-center text-sm cursor-pointer" (click)="openGrbInNewWindow(product)" id="itemProductButton8">
                    <img title="LRB" class="w-4 h-8 mr-1" id="GRBIcon" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> {{"GENERAL_RISK_ASSESSMENT" | translate}}
                  </div>
                </td>
              </tr>
              <tr *ngIf="subscribesToStamping && product && product.sds_stamp_id&&showStampedDocument">
                <td class="table-row-column">
                  <div class="flex flex-row items-center text-sm cursor-pointer" (click)="openStampedSdsInNewWindow(product)" id="itemProductButton12">
                    <img title="SDB" class="w-4 h-8 mr-1" id="SDBIcon" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> {{"STAMPED_SDS" | translate}}
                  </div>
                </td>
              </tr>
              <tr *ngIf="subscribesToSubstitution && product && product.substitutionid && product.substitutionfileid">
                <td class="table-row-column">
                  <button (click)="openSubstitutionInNewWindow(product.substitutionid)" [disabled]="loadingsub" [class.cursor-not-allowed]="loadingsub">
                    <ng-container *ngIf="!loadingsub; else loading">
                      <div class="flex items-center">
                        <img title="{{'SUBSTITUTION' | translate}}" class="w-4 h-8 mr-1" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> {{"SUBSTITUTION" | translate}}
                      </div>
                    </ng-container>
                    <ng-template #loading>
                      <div class="flex items-center">
                        <svg class="w-4 h-8 mr-1 text-black animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        {{"SUBSTITUTION" | translate}}
                      </div>
                    </ng-template>
                  </button>
                </td>
              </tr>
              <tr *ngIf="subscribesToTransport">
                <td class="table-row-column">
                  <div class="flex flex-row items-center text-sm cursor-pointer" (click)="printTransportProduct()" id="itemProductButton3">
                    <img title="Transport" class="w-4 h-8 mr-1" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> {{"PRINT_TRANSPORT_INFO" | translate}}
                  </div>
                </td>
              </tr>
              <tr *ngIf="subscribesToTransport">
                <td class="table-row-column">
                  <div class="flex flex-row items-center text-sm cursor-pointer" (click)="printCargoDeclaration()" id="itemProductButton4">
                    <img title="cargo" class="w-4 h-8 mr-1" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> {{"PRINT_CARGODECLARATION_PDF" | translate}}
                    <sup><img title="pdf" class="w-4 h-4" src="/images/icons/info-svgrepo-com.svg" area-hidden="true" /></sup>
                  </div>
                </td>
              </tr>
              <tr *ngIf="subscribesToTransport&&product&&product.transport_un_file_id">
                <td class="table-row-column">
                  <div class="flex flex-row items-center text-sm cursor-pointer" (click)="openTransportDocumentInNewWindow(product)" id="itemProductButton6">
                    <img title="Transport" class="w-4 h-8 mr-1" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> {{"DOWNLOAD_PACKAGEINSTRUCTION" | translate}}
                  </div>
                </td>
              </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="subscribesToSpecialDemands" class="1/1 md:w-1/5 mt-2 mr-2">
        <table class="table-auto bg-white mb-4 w-full">
          <thead>
            <tr>
              <td class="table-row-column">
                <div class="flex flex-row items-center">
                  <span class="text-sm font-bold">{{"SPECIAL_REQUIREMENTS" | translate}} </span>
                  <sup><img class="w-4 h-4" src="/images/icons/info-svgrepo-com.svg" area-hidden="true" tooltips tooltip-smart="true" title="{{'SPECIAL_REQUIREMENTS_MESSAGE' | translate}}" /></sup>
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="product.training">
              <td class="table-row-column"><span class="text-sm">{{'TRAINING' | translate}}: {{product.training}}</span></td>
            </tr>
            <tr *ngIf="product.health_control">
              <td class="table-row-column"><span class="text-sm">{{'HEALTH_CONTROL' | translate}}: {{product.health_control}}</span></td>
            </tr>
            <tr *ngIf="product.information">
              <td class="table-row-column"><span class="text-sm">{{'INFORMATION' | translate}}: {{product.information}}</span></td>
            </tr>
            <tr *ngIf="product.fitness_for_duty">
              <td class="table-row-column"><span class="text-sm">{{'FITNESS_FOR_DUTY' | translate}}: {{product.fitness_for_duty}}</span></td>
            </tr>
            <tr *ngIf="product.no_special_demands">
              <td class="table-row-column"><span class="text-sm">{{'NO_SPECIAL_DEMANDS' | translate}}: {{product.no_special_demands}}</span></td>
            </tr>
            <tr *ngIf="product.LRB_id">
              <td *ngIf='subscribesToRiskAssessment && aggregatedLevel==false && product.LRB_food_approved==1' class="table-row-column"><span class="text-sm">{{'FOOD_APPROVED' | translate}}: {{'YES' | translate}}</span></td>
              <td *ngIf='subscribesToRiskAssessment && aggregatedLevel==false && product.LRB_food_approved==0' class="table-row-column"><span class="text-sm">{{'FOOD_APPROVED' | translate}}: {{'NO' | translate}}</span></td>
              <td *ngIf='subscribesToRiskAssessment && aggregatedLevel==false && product.LRB_food_approved==2' class="table-row-column"><span class="text-sm">{{'FOOD_APPROVED' | translate}}: {{'NOT_ASSESSED' | translate}}</span></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="1/2 md:w-1/5 mt-2">
        <table class="table-auto bg-white mb-4 w-full">
          <thead>
            <tr>
              <td class="table-row-column">
                <div class="flex flex-row items-center">
                  <span class="text-sm font-bold">{{"RESTRICTION_LISTS" | translate}}</span>
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="product.list_reach_14">
              <td class="table-row-column"><span class="text-sm text-red-500">{{'RESTRICTION_LIST_REACH_14' | translate}}</span></td>
            </tr>
            <tr *ngIf="product.list_reach_17">
              <td class="table-row-column"><span class="text-sm text-red-500">{{'RESTRICTION_LIST_REACH_17' | translate}}</span></td>
            </tr>
            <tr *ngIf="product.list_reach_candidate">
              <td class="table-row-column"><span class="text-sm text-red-500">{{'RESTRICTION_LIST_REACH_CANDIDATE' | translate}}</span></td>
            </tr>
            <tr *ngIf="product.list_chem_prio_p">
              <td class="table-row-column"><span class="text-sm text-red-500">{{'RESTRICTION_LIST_CHEM_PRIO_P' | translate}}</span></td>
            </tr>
            <tr *ngIf="product.list_chem_prio_u">
              <td class="table-row-column"><span class="text-sm text-red-500">{{'RESTRICTION_LIST_CHEM_PRIO_U' | translate}}</span></td>
            </tr>
            <tr *ngIf="product.list_water_directive">
              <td class="table-row-column"><span class="text-sm text-red-500">{{'RESTRICTION_LIST_WATER_DIRECTIVE' | translate}}</span></td>
            </tr>
            <tr *ngIf="product.list_amv_a">
              <td class="table-row-column"><span class="text-sm text-red-500">{{'RESTRICTION_LIST_AMV_A' | translate}}</span></td>
            </tr>
            <tr *ngIf="product.list_amv_a">
              <td class="table-row-column"><span class="text-sm text-red-500">{{'RESTRICTION_LIST_AMV_B' | translate}}</span></td>
            </tr>
            <tr *ngIf="subscribesToSIN&&product.list_sin">
              <td class="table-row-column"><span class="text-sm text-red-500">{{'RESTRICTION_LIST_SIN' | translate}}</span></td>
            </tr>
            <tr *ngIf="subscribesToSLL&&product.list_sll">
              <td class="table-row-column"><span class="text-sm text-red-500">{{'RESTRICTION_LIST_SLL' | translate}}</span></td>
            </tr>
            <tr *ngIf="subscribesToBASTA&&product.list_basta">
              <td class="table-row-column"><span class="text-sm text-red-500">{{'RESTRICTION_LIST_BASTA' | translate}}</span></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="picture != null" class="md:w-1/5 mt-2 ml-2 mr-2 flex justify-center">
        <img class="max-w-1/5 h-auto" [src]="picture" alt="Responsive image" id="picture" />
      </div>
    </div>
    <div class="flex flex-col md:flex-row justify-between px-4 w-full py-2 mb-4">
      <div class="flex flex-row">
        <span *ngFor="let pictogram of product?.pictograms" tooltips tooltip-smart="true" title="{{pictogram.description}}">
          <img title="{{pictogram.description}}" src="/images/pictograms/{{pictogram.filename}}-small.png" />
        </span>
        <span *ngFor="let protective of product?.protectiveArray" tooltips tooltip-smart="true" title="{{protective.description}}">
          <img title="{{protective.description}}" src="/images/protective/{{protective.filename}}-small.png" />
        </span>
      </div>
      <div *ngIf="!loadingData && product && subscribesToReportEnvironment" class="flex flex-col px-4 mb-4 mt-4 md:mt-0">
        <span class="font-bold">{{"ECOLABELS" | translate}}</span>
        <div class="flex flex-row">
          <img title="ecolabel" class="h-10 w-10 mr-1" *ngIf="product.eco_eu" src="/images/loggos/ecolabel-logo.png" />
          <img title="eco_svanen" *ngIf="product.eco_svanen" class="h-10 w-10 mr-1" src="/images/loggos/SE_Svanen_A_POS_RGB.png" />
          <img title="eco_GEC" *ngIf="product.eco_GEC" class="h-10 w-10 mr-1" src="/images/loggos/gec.jpg" />
          <div title="eco_eu" *ngIf="!product.eco_eu && !product.eco_svanen && !product.eco_GEC"> - {{"MISSING" | translate}}</div>
        </div>
      </div>
    </div>

    <div *ngIf="!loadingData" class="flex flex-col max-w-screen bg-cdocbanner mb-20">
      <div class="container">
        <ul class="flex flex-col md:flex-row">
          <li class="mr-1">
              <a (click)="showFirstHelp()" [class.active]="isFirstHelp" id="itemProductButton19" class="bg-cdocbanner inline-block py-2 px-4 text-sm text-white hover:text-blue-800 font-semibold active:font-bold cursor-pointer">
                {{"FIRST_AID" | translate}}
              </a>
          </li>
          <li class="mr-1">
            <a (click)="showProtective()" [class.active]="isProtective" id="itemProductButton20" class="bg-cdocbanner inline-block py-2 px-4 text-sm text-white hover:text-blue-800 font-semibold cursor-pointer">
              {{"PROTECTIVE_GEAR" | translate}}
            </a>
          </li>
          <li class="mr-1">
            <a (click)="showHandling()" [class.active]="isHandling" id="itemProductButton39" class="bg-cdocbanner inline-block py-2 px-4 text-sm text-white hover:text-blue-800 font-semibold cursor-pointer">
              {{"HANDLING" | translate}}
            </a>
          </li>
          <li class="mr-1">
            <a (click)="showphrases()" [class.active]="isphrases" id="itemProductButton21" class="bg-cdocbanner inline-block py-2 px-4 text-sm text-white hover:text-blue-800 font-semibold cursor-pointer">
              {{"RISK_PHRASES" | translate}}
            </a>
          </li>
          <li *ngIf="subscribesToPHClassification" class="mr-1">
            <a (click)="showPhClassification()" [class.active]="isPhClassification" id="itemProductButton22" class="bg-cdocbanner inline-block py-2 px-4 text-sm text-white hover:text-blue-800 font-semibold cursor-pointer">
              {{"PhValueAndDosage" | translate}}
            </a>
          </li>
          <li class="mr-1">
            <a (click)="showMixtures()" [class.active]="isMixtures" id="itemProductButton25" class="bg-cdocbanner inline-block py-2 px-4 text-sm text-white hover:text-blue-800 font-semibold cursor-pointer">
              {{"MIXTURES" | translate}}
            </a>
          </li>
          <li *ngIf="subscribesToTransport" class="mr-1">
            <a (click)="showTransport()" [class.active]="isTransport" id="itemProductButton26" class="bg-cdocbanner inline-block py-2 px-4 text-sm text-white hover:text-blue-800 font-semibold cursor-pointer">
              {{"DANGEROUS_GOODS" | translate}}
            </a>
          </li>
          <li *ngIf="subscribesToSpecialDemands" class="mr-1">
            <a (click)="showSpecialDemands()" [class.active]="isSpecialDemand" id="itemProductButton37" class="bg-cdocbanner inline-block py-2 px-4 text-sm text-white hover:text-blue-800 font-semibold cursor-pointer">
              {{'SPECIAL_REQUIREMENTS' | translate}}
            </a>
          </li>
          <li class="mr-1">
            <a (click)="showMisc()" [class.active]="isMisc" id="itemProductButton39" class="bg-cdocbanner inline-block py-2 px-4 text-sm text-white hover:text-blue-800 font-semibold cursor-pointer">
              {{'MISC' | translate}}
            </a>
          </li>
        </ul>
      </div>
      <div *ngIf="product&&isFirstHelp" class="flex flex-col bg-white px-4">
        <span class="font-bold mt-4">{{"AT_INHALATION" | translate}}</span>
        <span class="text-gray-700">{{product.ice.inhalation}}</span>
        <span class="font-bold mt-2">{{"AT_SKIN_CONTACT" | translate}}</span>
        <span class="text-gray-700">{{product.ice.skin_contact}}</span>
        <span class="font-bold mt-2">{{"AT_EYE_CONTACT" | translate}}</span>
        <span class="text-gray-700">{{product.ice.eye_contact}}</span>
        <span class="font-bold mt-2">{{"AT_CONSUMPTION" | translate}}</span>
        <span class="text-gray-700">{{product.ice.consumption}}</span>
      </div>
      <div *ngIf="product&&isProtective" class="flex flex-col bg-white px-4">
        <span class="font-bold mt-4">{{"RESPIRATORY" | translate}}</span>
        <span *ngIf="(subscribesToRiskAssessment && aggregatedLevel == false && product && product.LRB_breath_protection_own && product.LRB_breath_protection_own !== '')" class="text-gray-700">{{product.LRB_breath_protection_own}}</span>
        <span *ngIf="!(((subscribesToRiskAssessment || aggregatedLevel==true) && product && product.LRB_breath_protection_own && product.LRB_breath_protection_own !== ''))" class="text-gray-700">{{product.protection.breath}}</span>
        <span class="font-bold mt-4">{{"HAND_PROTECTION" | translate}}</span>
        <span *ngIf="(subscribesToRiskAssessment && aggregatedLevel == false && product && product.LRB_hand_protection_own && product.LRB_hand_protection_own !== '')" class="text-gray-700">{{product.LRB_hand_protection_own}}</span>
        <span *ngIf="!(((subscribesToRiskAssessment || aggregatedLevel == true) && product && product.LRB_hand_protection_own && product.LRB_hand_protection_own !== ''))" class="text-gray-700">{{product.protection.gloves}}</span>
        <span class="font-bold mt-4">{{"EYE_PROTECTION" | translate}}</span>
        <span *ngIf="(subscribesToRiskAssessment && aggregatedLevel == false  && product && product.LRB_eye_protection_own && product.LRB_eye_protection_own !== '')" class="text-gray-700">{{product.LRB_eye_protection_own}}</span>
        <span *ngIf="!(((subscribesToRiskAssessment || aggregatedLevel == true) && product && product.LRB_eye_protection_own && product.LRB_eye_protection_own !== ''))" class="text-gray-700">{{product.protection.glasses}}</span>
        <span class="font-bold mt-4">{{"PROTECTIVE_CLOTHING" | translate}}</span>
        <span *ngIf="(subscribesToRiskAssessment && aggregatedLevel == false && product && product.LRB_body_protection_own && product.LRB_body_protection_own !== '')" class="text-gray-700">{{product.LRB_body_protection_own}}</span>
        <span *ngIf="!(((subscribesToRiskAssessment || aggregatedLevel == true) && product && product.LRB_body_protection_own && product.LRB_body_protection_own !== ''))" class="text-gray-700">{{product.protection.clothes}}</span>
        <span class="font-bold mt-4">{{"PROTECTIVE_AND_HYGIENE_MEASURES" | translate}}</span>
        <span class="text-gray-700">{{product.protection.hygiene}}</span>
      </div>
      <div *ngIf="product&&isHandling" class="flex flex-col bg-white px-4">
        <span *ngIf="(subscribesToRiskAssessment && aggregatedLevel == false && product && product.LRB_general_own && product.LRB_general_own !== '')" class="font-bold mt-4">{{"GENERAL" | translate}}</span>
        <span *ngIf="(subscribesToRiskAssessment && aggregatedLevel == false && product && product.LRB_general_own && product.LRB_general_own !== '')" class="text-gray-700">{{product.LRB_general_own}}</span>
        <span class="font-bold mt-4">{{"HANDLING" | translate}}</span>
        <span *ngIf="(subscribesToRiskAssessment && aggregatedLevel == false && product && product.LRB_handling_own && product.LRB_handling_own !== '')" class="text-gray-700">{{product.LRB_handling_own}}</span>
        <span *ngIf="!(((subscribesToRiskAssessment || aggregatedLevel == true) && product && product.LRB_handling_own && product.LRB_handling_own !== ''))" class="text-gray-700">{{product.handling}}</span>
        <span class="font-bold mt-4">{{"STORAGE" | translate}}</span>
        <span *ngIf="(subscribesToRiskAssessment && aggregatedLevel == false && product && product.LRB_storage_own && product.LRB_storage_own !== '')" class="text-gray-700">{{product.LRB_storage_own}}</span>
        <span *ngIf="!((subscribesToRiskAssessment && aggregatedLevel == false && product && product.LRB_storage_own && product.LRB_storage_own !== ''))" class="text-gray-700">{{product.handling_storage}}</span>
        <span class="font-bold mt-4">{{"FIRE_MEASURES" | translate}}</span>
        <span *ngIf="(subscribesToRiskAssessment && aggregatedLevel == false && product && product.LRB_fire_own && product.LRB_fire_own !== '')" class="text-gray-700">{{product.LRB_fire_own}}</span>
        <span *ngIf="!(((subscribesToRiskAssessment || aggregatedLevel == true) && product && product.LRB_fire_own && product.LRB_fire_own !== ''))" class="text-gray-700">{{product.handling_fire_measures}}</span>
        <span *ngIf="(product && product.handling_emissions && product.handling_emissions !== '')" class="font-bold mt-4">{{"EMISSIONS" | translate}}</span>
        <span *ngIf="(product && product.handling_emissions && product.handling_emissions !== '')" class="text-gray-700">{{product.handling_emissions}}</span>
        <span class="font-bold mt-4">{{"WASTE_HANDLING" | translate}}</span>
        <span *ngIf="(subscribesToRiskAssessment && aggregatedLevel == false && product && product.LRB_waste_own && product.LRB_waste_own !== '')" class="text-gray-700">{{product.LRB_waste_own}}</span>
        <span *ngIf="!((subscribesToRiskAssessment && aggregatedLevel == false && product && product.LRB_waste_own && product.LRB_waste_own !== ''))" class="text-gray-700">{{product.handling_waste}}</span>
      </div>
      <div *ngIf="product&&isphrases" class="flex flex-col bg-white px-4">
        <div class="flex flex-col md:flex-row mt-4">
          <span class="w-full md:w-1/2" *ngIf="product?.phrases?.H&&product?.phrases?.H?.length>0">
            <span class="font-bold mt-4">{{"H_PHRASES" | translate}}</span>
            <table class="table-auto mt-2">
                <tr *ngFor="let phrase of get_unique_items(product.phrases.H)">
                    <td class="table-row-column">{{phrase}}</td>
                    <td class="table-row-column">{{"PHRASE_" + phrase | translate}}</td>
                </tr>
            </table>
          </span>
          <span class="w-full md:w-1/2" *ngIf="product?.phrases?.P&&product?.phrases?.P?.length>0">
            <span class="font-bold mt-4">{{"P_PHRASES" | translate}}</span>
            <table class="table-auto mt-2">
                <tr *ngFor="let phrase of get_unique_items(product.phrases.P)">
                    <td class="table-row-column">{{phrase}}</td>
                    <td class="table-row-column">{{"PHRASE_" + phrase | translate}}</td>
                </tr>
            </table>
          </span>
        </div>
        <div class="flex flex-col md:flex-row mt-4">
          <span class="w-full md:w-1/2" *ngIf="product?.c_phrases&&product?.c_phrases?.length>0">
            <span class="font-bold mt-4">{{"C_PHRASES" | translate}}</span>
            <table class="table-auto mt-2">
                <tr *ngFor="let phrase of product.c_phrases">
                    <td class="table-row-column">{{phrase.c_phrase}}</td>
                    <td class="table-row-column"></td>
                </tr>
            </table>
          </span>
          <span class="w-full md:w-1/2 font-bold mt-2">{{"OTHER_HAZARDS" | translate}}
            <p>{{product.other_dangers}}</p>
          </span>
        </div>
      </div>
      <div *ngIf="isPhClassification" class="flex flex-col bg-white px-4">
        <span class="font-bold mt-4">{{"PH_CLASSIFICATION" | translate}}</span>
        <span class="text-gray-700">{{product.classification.name}}</span>
        <span class="font-bold mt-4">{{"PH_VALUE" | translate}}</span>
        <span class="text-gray-700">{{product.ph_value}}</span>
        <span class="font-bold mt-4">{{"DOSERING" | translate}}</span>
        <span class="text-gray-700">{{product.dosering}}</span>
      </div>
      <div *ngIf="isMixtures" class="flex flex-col bg-white px-4">
        <span class="font-bold mt-4">{{'MIXTURES' | translate}} / {{'SUBSTANCES' | translate}}</span>
        <div class="overflow-x-auto">
          <table class="table-auto mt-4">
              <tr>
                  <th class="table-row-header">{{'NAME' | translate}}</th>
                  <th class="table-row-header">{{'EG' | translate}}</th>
                  <th class="table-row-header">{{'CAS' | translate}}</th>
                  <th class="table-row-header">{{'H_PHRASES' | translate}}</th>
                  <th class="table-row-header">{{'RESTRICTION_LISTS' | translate}}</th>
                  <th class="table-row-header">{{'CONCENTRATION' | translate}}</th>
                  <th class="table-row-header" *ngIf="subscribesToPop">{{'LIST_POP_SHORT' | translate}}</th>
                  <th class="table-row-header" *ngIf="subscribesToRohs">{{'LIST_ROHS_SHORT' | translate}}</th>
              </tr>
              <tr *ngFor="let mixture of product.mixtures">
                  <td class="table-row-column">{{mixture.name}}</td>
                  <td class="table-row-column">{{mixture.eg}}</td>
                  <td class="table-row-column">{{mixture.cas}}</td>
                  <td class="table-row-column">{{mixture.hphrases}}</td>
                  <td class="table-row-column">{{getRestriktionList(mixture)}}</td>
                  <td class="table-row-column">{{getPercent(mixture)}}</td>
                  <td class="table-row-column" *ngIf="subscribesToPop"><span class="text-red-500" *ngIf="mixture.list_pop==1">X</span></td>
                <td class="table-row-column" *ngIf="subscribesToRohs"><span class="text-red-500" *ngIf="mixture.list_rohs==1">X</span></td>
              </tr>
              <tr *ngFor="let substance of product.substances">
                <td class="table-row-column">{{substance.name}}</td>
                <td class="table-row-column">{{substance.eg}}</td>
                <td class="table-row-column">{{substance.cas}}</td>
                <td class="table-row-column">{{substance.hphrases}}</td>
                <td class="table-row-column">{{getRestriktionList(substance)}}</td>
                <td class="table-row-column">{{getPercent(substance)}}</td>
                <td class="table-row-column" *ngIf="subscribesToPop"><span class="text-red-500" *ngIf="substance.list_pop==1">X</span></td>
                <td class="table-row-column" *ngIf="subscribesToRohs"><span class="text-red-500" *ngIf="substance.list_rohs==1">X</span></td>
            </tr>
          </table>
        </div>
      </div>
      <div *ngIf="isTransport&&subscribesToTransport&&!product.adr_not_classfied" class="flex flex-col bg-white px-4">
        <div class="flex flex-col md:flex-row">
          <span class="w-full md:w-1/3 text-2xl font-bold mt-6 items-center">{{"UN_NUMBER" | translate}}: {{product.transport_un_nbr}}</span>
          <div class="w-full md:w-2/3 mt-4">
              <img title="icon" class="w-10 h-10" *ngIf="product.transport_label_name_1" src="/images/transport/{{product.transport_label_name_1}}-small.png" />
              <img title="icon" class="w-10 h-10" *ngIf="product.transport_label_name_2" src="/images/transport/{{product.transport_label_name_2}}-small.png" />
              <img title="icon" class="w-10 h-10" *ngIf="product.transport_label_name_3" src="/images/transport/{{product.transport_label_name_3}}-small.png" />
              <img title="icon" class="w-10 h-10" *ngIf="product.transport_label_name_4" src="/images/transport/{{product.transport_label_name_4}}-small.png" />
              <img title="icon" class="w-10 h-10" *ngIf="product.transport_label_name_5" src="/images/transport/{{product.transport_label_name_5}}-small.png" />
          </div>
        </div>
        <div class="flex flex-col md:flex-row">
          <div class="flex flex-col mt-4 w-full md:w-1/3">
            <span class="text-lg font-bold mt-4 underline">ADR/ADN/RID</span>
            <span class="flex flex-row font-bold mt-4">{{"PROPER_SHIPPING_NAME" | translate}}: <p class="font-normal"> &nbsp;{{product.adr_name}}</p></span>
            <span class="flex flex-row font-bold mt-1">{{"HAZARD_CLASS_FOR_TRANSPORT" | translate}}: <p class="font-normal"> &nbsp;{{product.adr_class}}</p></span>
            <span class="flex flex-row font-bold mt-1">{{"CLASSIFICATION_CODE" | translate}}: <p class="font-normal"> &nbsp;{{product.adr_classification_code}}</p></span>
            <span class="flex flex-row font-bold mt-1">{{"DANGER_CODE" | translate}}: <p class="font-normal"> &nbsp;{{product.adr_danger_code}}</p></span>
            <span class="flex flex-row font-bold mt-1">{{"TUNNEL_RESTRICTION_CODE" | translate}}: <p class="font-normal"> &nbsp;{{product.adr_tunnel_restr}}</p></span>
            <span class="flex flex-row font-bold mt-1">{{"LIMITED_AMOUNTS" | translate}}: <p class="font-normal"> &nbsp;{{product.adr_amount_limit}}</p></span>
            <span class="flex flex-row font-bold mt-1">{{"ADR_SPECIAL_PROVISIONS" | translate}}: <p class="font-normal"> &nbsp;{{product.adr_special_provision}}</p></span>
            <span class="flex flex-row font-bold mt-1">{{"PACKING_GROUP" | translate}}: <p class="font-normal"> &nbsp;{{product.adr_packing}}</p></span>
            <span class="flex flex-row font-bold mt-1">{{"ENVIRONMENT_HAZARDS" | translate}}:
              <p *ngIf="product.adr_environment==1" class="font-normal"> &nbsp;{{"YES" | translate}}</p>
              <p *ngIf="product.adr_environment==0" class="font-normal"> &nbsp;{{"YES" | translate}}</p>
            </span>
            <span class="font-bold mt-1">{{"SPECIAL_PRECAUTIONS" | translate}}: </span>
            <p class="font-normal">{{product.adr_other_info}}</p>
            <span class="font-bold mt-1">{{"Bulk information" | translate}}:</span>
            <p class="font-normal">{{product.adr_bulk_info}}</p>
          </div>
          <div class="flex flex-col mt-4 w-full md:w-1/3">
            <span class="text-lg font-bold mt-4 underline">ICAO(IATA)</span>
            <span class="font-bold mt-4">{{"PROPER_SHIPPING_NAME" | translate}}: </span>
            <p class="font-normal">{{product.iata_name}}</p>
            <span class="flex flex-row font-bold mt-1">{{"HAZARD_CLASS_FOR_TRANSPORT" | translate}}: <p class="font-normal"> &nbsp;{{product.iata_class}}</p></span>
            <span class="flex flex-row font-bold mt-1">{{"PACKING_GROUP" | translate}}: <p class="font-normal"> &nbsp;{{product.iata_packing}}</p></span>
            <span class="flex flex-row font-bold mt-1">{{"ENVIRONMENT_HAZARDS" | translate}}:
              <p *ngIf="product.iata_environment==1" class="font-normal"> &nbsp;{{"YES" | translate}}</p>
              <p *ngIf="product.iata_environment==0" class="font-normal"> &nbsp;{{"YES" | translate}}</p>
            </span>
            <span class="font-bold mt-1">{{"SPECIAL_PRECAUTIONS" | translate}}: </span>
            <p class="font-normal">{{product.iata_other_info}}</p>
            <span class="font-bold mt-1">{{"Bulk information" | translate}}:</span>
            <p class="font-normal">{{product.iata_bulk_info}}</p>
          </div>
          <div class="flex flex-col mt-4 w-full md:w-1/3">
            <span class="text-lg font-bold mt-4 underline">IMDG/IMO</span>
            <span class="font-bold mt-4">{{"PROPER_SHIPPING_NAME" | translate}}: </span>
            <p class="font-normal">{{product.imdg_name}}</p>
            <span class="flex flex-row font-bold mt-1">{{"HAZARD_CLASS_FOR_TRANSPORT" | translate}}: <p class="font-normal"> &nbsp;{{product.imdg_class}}</p></span>
            <span class="flex flex-row font-bold mt-1">{{"EMS" | translate}}: <p class="font-normal"> &nbsp;{{product.imdg_ems}}</p></span>
            <span class="flex flex-row font-bold mt-1">{{"MARINE_ENVIRONMENT_HAZARDS" | translate}}:
              <p *ngIf="product.imdg_environment==1" class="font-normal"> &nbsp;{{"YES" | translate}}</p>
              <p *ngIf="product.imdg_environment==0" class="font-normal"> &nbsp;{{"YES" | translate}}</p>
            </span>
            <span class="font-bold mt-1">{{"SPECIAL_PRECAUTIONS" | translate}}: </span>
            <p class="font-normal">{{product.imdg_other_info}}</p>
            <span class="font-bold mt-1">{{"Bulk information" | translate}}:</span>
            <p class="font-normal">{{product.imdg_bulk_info}}</p>
          </div>
        </div>
      </div>
      <div *ngIf="isSpecialDemand&&subscribesToSpecialDemands" class="flex flex-col bg-white px-4">
        <table class="table-auto mt-4 w-2/3">
          <tr>
            <th class="table-row-header">{{"SPECIAL_REQUIREMENTS" | translate}}</th>
            <th class="table-row-header">{{"REQUIRED" | translate}}</th>
            <th class="table-row-header">{{"COMMENT" | translate}}</th>
          </tr>
          <tr *ngIf="product.training">
            <td class="table-row-column">{{"TRAINING" | translate}}</td>
            <td class="table-row-column">{{"YES" | translate}}</td>
            <td class="table-row-column">{{product.training}}</td>
          </tr>
          <tr *ngIf="!product.training">
            <td class="table-row-column">{{"TRAINING" | translate}}</td>
            <td class="table-row-column">{{"NO" | translate}}</td>
            <td class="table-row-column"></td>
          </tr>
          <tr *ngIf="product.health_control">
            <td class="table-row-column">{{"HEALTH_CONTROL" | translate}}</td>
            <td class="table-row-column">{{"YES" | translate}}</td>
            <td class="table-row-column">{{product.health_control}}</td>
          </tr>
          <tr *ngIf="!product.health_control">
            <td class="table-row-column">{{"HEALTH_CONTROL" | translate}}</td>
            <td class="table-row-column">{{"NO" | translate}}</td>
            <td class="table-row-column"></td>
          </tr>
          <tr *ngIf="product.fitness_for_duty">
            <td class="table-row-column">{{"FITNESS_FOR_DUTY" | translate}}</td>
            <td class="table-row-column">{{"YES" | translate}}</td>
            <td class="table-row-column">{{product.fitness_for_duty}}</td>
          </tr>
          <tr *ngIf="!product.fitness_for_duty">
            <td class="table-row-column">{{"FITNESS_FOR_DUTY" | translate}}</td>
            <td class="table-row-column">{{"NO" | translate}}</td>
            <td class="table-row-column"></td>
          </tr>
          <tr *ngIf="product.information">
            <td class="table-row-column">{{"INFORMATION_DEMAND" | translate}}</td>
            <td class="table-row-column">{{"YES" | translate}}</td>
            <td class="table-row-column">{{product.information}}</td>
          </tr>
          <tr *ngIf="!product.information">
            <td class="table-row-column">{{"INFORMATION_DEMAND" | translate}}</td>
            <td class="table-row-column">{{"NO" | translate}}</td>
            <td class="table-row-column"></td>
          </tr>
          <tr *ngIf="!(product.no_special_demands==null || product.no_special_demands=='notset')">
            <td class="table-row-column">{{"NO_SPECIAL_DEMANDS" | translate}}</td>
            <td class="table-row-column">{{"YES" | translate}}</td>
            <td class="table-row-column">{{product.no_special_demands}}</td>
          </tr>
          <tr *ngIf="(product.no_special_demands==null || product.no_special_demands=='notset')">
            <td class="table-row-column">{{"NO_SPECIAL_DEMANDS" | translate}}</td>
            <td class="table-row-column">{{"NO" | translate}}</td>
            <td class="table-row-column"></td>
          </tr>
        </table>
      </div>
      <div *ngIf="isMisc" class="flex flex-col md:flex-row bg-white px-4">
        <div class="flex flex-col mt-4 w-full md:w-1/4">
          <span class="font-bold mt-4">{{"DEPARTMENTS" | translate}}</span>
          <ul>
            <li *ngFor="let department of departmentList">
              <span class="text-sm font-bold mr-1">{{department.name}}</span> <span class="italic text-xs">({{departFullName(department.id)}})</span>
            </li>
          </ul>
        </div>
        <div class="flex flex-col mt-4 w-full md:w-1/4">
          <span class="font-bold mt-4">{{"SAFETY_DATA_SHEET" | translate}} ({{"HISTORY" | translate}})</span>
          <div *ngIf="documentHistory.sds&&documentHistory?.sds?.length > 0">
            <ul>
              <li class="flex flex-row items-center cursor-pointer" *ngFor="let sds of documentHistory.sds | orderBy: 'sds.timestamp'" (click)="openHistoricalSds(sds)">
                <img title="{{'SAFETY_DATA_SHEET' | translate}}" class="w-4 h-8 mr-1" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> {{sds.timestamp | date:'yyyy-MM-dd'}}
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="subscribesToGrb" class="flex flex-col mt-4 w-full md:w-1/4">
          <span class="font-bold mt-4">{{"GENERAL_RISK_ASSESSMENT" | translate}} ({{"HISTORY" | translate}})</span>
          <div *ngIf="documentHistory.grb&&documentHistory?.grb?.length > 0">
            <ul>
              <li class="flex flex-row items-center cursor-pointer" *ngFor="let grb of documentHistory.grb | orderBy: 'grb.timestamp'" (click)="openHistoricalGrb(grb)">
                <img title="{{'GENERAL_RISK_ASSESSMENT' | translate}}" class="w-4 h-8 mr-1" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> {{grb.last_changed | date:'yyyy-MM-dd'}}
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="activityBasedDataSheets && allDepartmentsChoosen!=true" class="flex flex-col mt-4 w-full md:w-1/4">
          <span class="font-bold mt-4 mb-2">{{"ACTIVITY_RISK_ASSESSMENTS" | translate}}</span>
          <div *ngIf="activityBasedDataSheets?.length > 0">
            <div *ngFor="let activityBasedDataSheet of activityBasedDataSheets; let i = index">
              <button (click)="openArbInNewWindow(activityBasedDataSheet, i)" [disabled]="loadingStates[i]" class="px-4 mb-2" [class.cursor-not-allowed]="loadingStates[i]">
                <ng-container *ngIf="!loadingStates[i]; else loading">
                  <div class="flex items-center">
                    <img title="{{'ACTIVITY_RISK_ASSESSMENTS' | translate}}" class="w-6 h-6 mr-1" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" /> {{activityBasedDataSheet.heading}}
                  </div>
                </ng-container>
                <ng-template #loading>
                  <div class="flex items-center">
                    <svg class="w-6 h-6 mr-1 text-black animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    {{activityBasedDataSheet.heading}}
                  </div>
                </ng-template>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
