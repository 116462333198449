import { Component, OnInit, ViewChild } from '@angular/core';
import { OrganisationService } from 'src/app/services/organisation.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ProductService } from "src/app/services/product.service";
import { ProductConversionsService } from 'src/app/services/product-conversions.service';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import * as _ from "lodash";
import { DatePipe } from '@angular/common';
import * as Highcharts from 'highcharts';
declare var $: any;

@Component({
  selector: 'app-dashboard-history-statistics',
  templateUrl: './dashboard-history-statistics.component.html',
  styleUrls: ['./dashboard-history-statistics.component.css'],
  providers: [DatePipe]
})

export class DashboardHistoryStatisticsComponent implements OnInit {
  currentUser: any;
  loadingChangedProducts: boolean = false;
  getProductsIsClicked: boolean = false;
  organisation: any;
  selectedDepartment: any;
  selectedOrganisation: any;
  products: any;
  historyProductGroup:any;
  Highcharts: typeof Highcharts = Highcharts;
  allDep:boolean = true;
  changedProductsFrom;
  changedProductsTo;
  changedProducts: any[] = [];
  errorDate = "";
  groupInfo: any;
  groupInfo2: any;
  updateRiskPrioChart1 = false;
  updateRiskPrioChart2 = false;
  updateProductGroupChart1 = false;
  updateProductGroupChart2 = false;
  updateRestrictionChart1 = false;
  updateRestrictionChart2 = false;
  updateSupplierChart1 = false;
  updateSupplierChart2 = false;
  updateCMRAChart1 = false;
  updateCMRAChart2 = false;
  updateSpecialChart1 = false;
  updateSpecialChart2 = false;
  updatePictogramChart1 = false;
  updatePictogramChart2 = false;
  updateEnvironmentChart1 = false;
  updateEnvironmentChart2 = false;
  restrictionListsCount: any[] = [];
  restriktionList: any[] = [];
  subscribesToSIN: any;
  subscribesToSLL: any;
  subscribesToBASTA: any;
  subscribesToReportEnvironment: any;
  subscribesToSpecial: any;
  productsById = [];
  productsByIdShort = [];
  productsByIdLength:number;
  amountInProductsByIdShort;
  notInAmountInProductsByIdShort;
  CMRA_amount: any[] = [];
  sareqIds: any[];
  CMRAChart1: boolean = false;
  CMRAChart2: boolean = false;
  SpecialChart1: boolean = false;
  SpecialChart2: boolean = false;
  pictogramChart1: boolean = false;
  pictogramChart2: boolean = false;
  environmentChart1: boolean = false;
  environmentChart2: boolean = false;
  dynamicTitle = "";
  ecoCounter: any[] = [];
  departmentChoice = 2;
  departmentChangedSubscription: Subscription;

  riskPrioChartConfig1: Highcharts.Options = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: ''
    },
    subtitle: {
      text: ''
    },
    credits: {
      enabled: false
    },
    plotOptions: {
        pie: {
            colorIndex: 1,
            allowPointSelect: false,
            cursor: 'none',
            colors: [
              '#7FFFD4',
              'rgb(25,251,139',
              '#FFEA00',
              '#FFA500',
              '#FF0000'
            ],
        }
    },
    series: [{
        type: 'pie',
        // Must be 'column'
        // colorByPoint: true,
        cursor: 'none',
        point: {

        },
        data: [{
            name: 'data',
            y: 0,
            sliced: false,
            selected: false
        }, {
            name: '',
            y: 0
        }, {
            name: '0',
            y: 0
        }, {
            name: '0',
            y: 0
        }, {
            name: '',
            y: 0
        }]
    }]
  }

  riskPrioChartConfig2: Highcharts.Options = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: ''
    },
    subtitle: {
      text: ''
    },
    credits: {
      enabled: false
    },
    plotOptions: {
        pie: {
            colorIndex: 1,
            allowPointSelect: false,
            cursor: 'none',
            colors: [
              '#7FFFD4',
              'rgb(25,251,139',
              '#FFEA00',
              '#FFA500',
              '#FF0000'
            ],
        }
    },
    series: [{
        type: 'pie',
        // Must be 'column'
        // colorByPoint: true,
        cursor: 'none',
        point: {

        },
        data: [{
            name: 'data',
            y: 0,
            sliced: false,
            selected: false
        }, {
            name: '',
            y: 0
        }, {
            name: '0',
            y: 0
        }, {
            name: '0',
            y: 0
        }, {
            name: '',
            y: 0
        }]
    }]
  }

  productgroupChartConfig1: Highcharts.Options = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: ''
    },
    subtitle: {
      text: ''
    },
    credits: {
      enabled: false
    },
    plotOptions: {
        pie: {
            colorIndex: 1,
            allowPointSelect: false,
            cursor: 'none'
        },
    },
    series: [{
        type: 'pie',
        showInLegend: false,
        // Must be 'column'
        // colorByPoint: true,
        cursor: 'none',
        point: {},
        data: [{
          name: 'data',
          y: 0,
          sliced: false,
          selected: false
      }, {
          name: '',
          y: 0
      }, {
          name: '0',
          y: 0
      }, {
          name: '0',
          y: 0
      }, {
          name: '',
          y: 0
      }, {
        name: '',
        y: 0
      }, {
          name: '0',
          y: 0
      }, {
          name: '0',
          y: 0
      }, {
          name: '',
          y: 0
      }, {
        name: '',
        y: 0
      }]
    }]
  }

  productgroupChartConfig2: Highcharts.Options = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: ''
    },
    subtitle: {
      text: ''
    },
    credits: {
      enabled: false
    },
    plotOptions: {
        pie: {
            colorIndex: 1,
            allowPointSelect: false,
            cursor: 'none'
        },
    },
    series: [{
      type: 'pie',
      showInLegend: false,
      // Must be 'column'
      // colorByPoint: true,
      cursor: 'none',
      point: {},
      data: [{
        name: 'data',
        y: 0,
        sliced: false,
        selected: false
      }, {
          name: '',
          y: 0
      }, {
          name: '0',
          y: 0
      }, {
          name: '0',
          y: 0
      }, {
          name: '',
          y: 0
      }, {
        name: '',
        y: 0
      }, {
          name: '0',
          y: 0
      }, {
          name: '0',
          y: 0
      }, {
          name: '',
          y: 0
      }, {
        name: '',
        y: 0
      }]
    }]
  }

  restrictionChartConfig1: Highcharts.Options = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: ''
    },
    subtitle: {
      text: ''
    },
    credits: {
      enabled: false
    },
    plotOptions: {
        pie: {
            colorIndex: 1,
            allowPointSelect: false,
            cursor: 'none'
        },
    },
    series: [{
        type: 'pie',
        showInLegend: false,
        // Must be 'column'
        // colorByPoint: true,
        cursor: 'none',
        point: {},
        data: [{
          name: "",
          y: 0,
          sliced: false,
          selected: false
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }]
    }]
  }

  restrictionChartConfig2: Highcharts.Options = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: ''
    },
    subtitle: {
      text: ''
    },
    credits: {
      enabled: false
    },
    plotOptions: {
        pie: {
            colorIndex: 1,
            allowPointSelect: false,
            cursor: 'none'
        },
    },
    series: [{
        type: 'pie',
        showInLegend: false,
        // Must be 'column'
        // colorByPoint: true,
        cursor: 'none',
        point: {
        },
        data: [{
          name: "",
          y: 0,
          sliced: false,
          selected: false
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }, {
          name:"",
          y: 0
        }]
    }]
  }

  supplierChartConfig1: Highcharts.Options = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: ''
    },
    subtitle: {
      text: ''
    },
    credits: {
      enabled: false
    },
    plotOptions: {
        pie: {
            colorIndex: 1,
            allowPointSelect: false,
            cursor: 'none'
        },
    },
    series: [{
        type: 'pie',
        showInLegend: false,
        // Must be 'column'
        // colorByPoint: true,
        cursor: 'none',
        point: {

        },
        data: [{
          name: '0',
          y: 0,
          sliced: false,
          selected: false
        }] // Setting the data later in the code
    }]
  }

  supplierChartConfig2: Highcharts.Options = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: ''
    },
    subtitle: {
      text: ''
    },
    credits: {
      enabled: false
    },
    plotOptions: {
        pie: {
            colorIndex: 1,
            allowPointSelect: false,
            cursor: 'none'
        },
    },
    series: [{
        type: 'pie',
        showInLegend: false,
        // Must be 'column'
        // colorByPoint: true,
        cursor: 'none',
        point: {

        },
        data: [{
          name: '0',
          y: 0,
          sliced: false,
          selected: false
        }] // Setting the data later in the code
    }]
  }

  CMRAChartConfig1: Highcharts.Options = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'column'
    },
    title: {
        text: ''
    },
    subtitle: {
      text: ''
    },
    credits: {
      enabled: false
    },
    xAxis: {
      labels: {
        useHTML: true,
        formatter: function () {
            return  '<span style="cursor:none;" id="cmra' + this.value + '">'+this.value+'<span/>';
        }
      },
      categories: ['Cancerogent', 'Mutagent', 'Reproduktionshämmande', 'Allergiskt']
    },
    yAxis: {
      min: 0,
      title: {
          text: 'Antal'
      },
      stackLabels: {
          enabled: true,
          style: {
              fontWeight: 'bold',
              color: ( // theme
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color
              ) || 'gray'
          }
      }
    },
    plotOptions: {
        column: {
            colorIndex: 2,
            allowPointSelect: false,
            cursor: 'none',

        },
        series: {
          borderWidth: 0,
          dataLabels: {
              enabled: true,
              format: '{point.y:1f}'
          }
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px"></span><br>',
      pointFormat: '<span>{point.name}</span><br/><span>Antal: {point.y:1f}</span><br/>'
  },

    series: [{
        type: 'column',
        showInLegend: false,
        colorByPoint: true,
        color: '#FF0000',
        cursor: 'none',
        colors: ['#3B97B2',
          '#67BC42',
          '#FF56DE',
          '#E6D605'
        ],
        point: {

        },
        data: [{
          name: '0',
          y: 0,
          id:'123',
          sliced: false,
          selected: false
        }
      ] // Setting the data later in the code
    }]
  }

  CMRAChartConfig2: Highcharts.Options = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'column'
    },
    title: {
        text: ''
    },
    subtitle: {
      text: ''
    },
    credits: {
      enabled: false
    },
    xAxis: {
      labels: {
        useHTML: true,
        formatter: function () {
            return  '<span style="cursor:none;" id="cmra' + this.value + '">'+this.value+'<span/>';
        }
      },
      categories: ['Cancerogent', 'Mutagent', 'Reproduktionshämmande', 'Allergiskt']
    },
    yAxis: {
      min: 0,
      title: {
          text: 'Antal'
      },
      stackLabels: {
          enabled: true,
          style: {
              fontWeight: 'bold',
              color: ( // theme
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color
              ) || 'gray'
          }
      }
    },
    plotOptions: {
        column: {
            colorIndex: 2,
            allowPointSelect: false,
            cursor: 'none',

        },
        series: {
          borderWidth: 0,
          dataLabels: {
              enabled: true,
              format: '{point.y:1f}'
          }
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px"></span><br>',
      pointFormat: '<span>{point.name}</span><br/><span>Antal: {point.y:1f}</span><br/>'
  },

    series: [{
        type: 'column',
        showInLegend: false,
        colorByPoint: true,
        color: '#FF0000',
        cursor: 'none',
        colors: ['#3B97B2',
          '#67BC42',
          '#FF56DE',
          '#E6D605'
        ],
        point: {

        },
        data: [{
          name: '0',
          y: 0,
          id:'123',
          sliced: false,
          selected: false
        }
      ] // Setting the data later in the code
    }]
  }

  specialChartConfig1: Highcharts.Options = {
    chart: {
        type: 'column'
    },
    credits: {
      enabled: false
    },
    title: {
      text: ""
    },
    subtitle: {
      text: ""
    },
    xAxis: {
      labels: {
        useHTML: true,
        formatter: function () {
            return  '<span style="cursor:none;" id="specialdemands' + this.pos + '">'+this.value+'<span/>';
        }
      },
      categories: ["information", 'training', 'health_control', 'fitness_for_duty', 'no_special_demands']
    },
    yAxis: {
      min: 0,
      title: {
          text: ''
      },
      stackLabels: {
          enabled: true,
          style: {
              fontWeight: 'bold',
              color: ( // theme
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color
              ) || 'gray'
          }
      }
    },
    plotOptions: {
        column: {
            stacking: 'normal',
            dataLabels: {
                enabled: false
            }
        }
    },
    series: [{
      name: "",
      type: 'column',
      colorByPoint: true,
      cursor: 'none',
      point: {
      },
      data: [0, 0, 0, 0]
    }]
  }

  specialChartConfig2: Highcharts.Options = {
    chart: {
        type: 'column'
    },
    credits: {
      enabled: false
    },
    title: {
      text: ""
    },
    subtitle: {
      text: ""
    },
    xAxis: {
      labels: {
        useHTML: true,
        formatter: function () {
            return  '<span style="cursor:none;" id="specialdemands' + this.pos + '">'+this.value+'<span/>';
        }
      },
      categories: ["information", 'training', 'health_control', 'fitness_for_duty', 'no_special_demands']
    },
    yAxis: {
      min: 0,
      title: {
          text: ''
      },
      stackLabels: {
          enabled: true,
          style: {
              fontWeight: 'bold',
              color: ( // theme
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color
              ) || 'gray'
          }
      }
    },
    plotOptions: {
        column: {
            stacking: 'normal',
            dataLabels: {
                enabled: false
            }
        }
    },
    series: [{
      name: "",
      type: 'column',
      colorByPoint: true,
      cursor: 'none',
      point: {

      },
      data: [0, 0, 0, 0]
    }]
  }

  pictogramChartConfig1: Highcharts.Options = {
    chart: {
        type: 'column'
    },
    credits: {
      enabled: false
    },
    title: {
      text: ""
    },
    subtitle: {
      text: ""
    },
    legend: {
      enabled: false
    },
    xAxis: {
      categories: [],
      labels: {
        x: 5,
        useHTML: true
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: ""

      },
      stackLabels: {
          enabled: true,
          style: {
              fontWeight: 'bold',
              color: ( // theme
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color
              ) || 'gray'
          }
      }
    },
    plotOptions: {
        column: {
            stacking: 'normal',
            dataLabels: {
                enabled: false
            }
        }
    },
    series: [{
      name: "",
      type: 'column',
      colorByPoint: false,
      cursor: 'none',
      point: {

      },
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
    }]
  }

    pictogramChartConfig2: Highcharts.Options = {
      chart: {
          type: 'column'
      },
      credits: {
        enabled: false
      },
      title: {
        text: ""
      },
      subtitle: {
        text: ""
      },
      legend: {
        enabled: false
      },
        /* xAxis: {
        categories: ["clp_acid-tiny", 'clp_bottle-tiny', 'clp_exclamation-tiny', 'clp_explosion-tiny', 'clp_flame-tiny',
                     "clp_pollution-tiny", 'clp_roundflame-tiny', 'clp_silhouete-tiny', 'clp_skull-tiny'],
        labels: {
          x: 5,
          useHTML: true,
          formatter: function () {
            return  '<img class="" title="' + this.value + '" id="pictogram' + this.value + '"' + 'style="padding-bottom: 30px; padding-top: 5px; padding-right:40px; max-width:100!important;cursor:none;" src="/images/pictograms/' + this.value + '.png" />';
          },
        },
      },*/
      xAxis: {
        categories: [],
        labels: {
          useHTML: true
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: ""

        },
        stackLabels: {
            enabled: true,
            style: {
                fontWeight: 'bold',
                color: ( // theme
                    Highcharts.defaultOptions.title.style &&
                    Highcharts.defaultOptions.title.style.color
                ) || 'gray'
            }
        }
      },
      plotOptions: {
          column: {
              stacking: 'normal',
              dataLabels: {
                  enabled: false
              }
          }
      },
      series: [{
        name: "",
        type: 'column',
        colorByPoint: false,
        cursor: 'none',
        point: {

        },
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
      }]
    }

    environmentChartConfig1: Highcharts.Options = {
      chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
      },
      title: {
          text: ''
      },
      subtitle: {
        text: ''
      },
      credits: {
        enabled: false
      },
      plotOptions: {
          pie: {
              colorIndex: 1,
              allowPointSelect: false,
              cursor: 'none'
          },
      },
      series: [{
          type: 'pie',
          showInLegend: false,
          // Must be 'column'
          // colorByPoint: true,
          cursor: 'none',
          point: {

          },
          data: [{
            name: "",
            y: 0,
            sliced: false,
            selected: false
          }, {
            name: "",
            y: 0
          }, {
            name: "",
            y: 0
          }]
      }]
    }

    environmentChartConfig2: Highcharts.Options = {
      chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
      },
      title: {
          text: ''
      },
      subtitle: {
        text: ''
      },
      credits: {
        enabled: false
      },
      plotOptions: {
          pie: {
              colorIndex: 1,
              allowPointSelect: false,
              cursor: 'none'
          },
      },
      series: [{
          type: 'pie',
          showInLegend: false,
          // Must be 'column'
          // colorByPoint: true,
          cursor: 'none',
          point: {

          },
          data: [{
            name: "",
            y: 0,
            sliced: false,
            selected: false
          }, {
            name: "",
            y: 0
          }, {
            name: "",
            y: 0
          }]
      }]
    }

  constructor(
    private organisationService: OrganisationService,
    private translate: TranslateService,
    private utilService: UtilService,
    private authService: AuthService, private productService: ProductService,
    private productConversionsService: ProductConversionsService,
    private router: Router, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.subscribesToSIN = this.utilService.subscribesTo('subscription_sin');
    this.subscribesToSLL = this.utilService.subscribesTo('subscription_sll');
    this.subscribesToBASTA = this.utilService.subscribesTo('subscription_basta');
    this.subscribesToReportEnvironment = this.utilService.subscribesTo('subscription_report_enviroment');
    this.subscribesToSpecial = this.utilService.subscribesTo('subscription_special_demands');
    this.changedProductsFrom = new Date(new Date().getTime() - (100 * 24 * 60 * 60 * 1000));
    this.changedProductsTo = new Date();
    var data = localStorage.getItem('currentUser', );
    if (data == null || data == undefined) {
      this.logout();
    } else {
      this.currentUser = JSON.parse(data);
    }
    this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
    this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
    this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation', ));
    this.productsByIdLength=30;  // Change this for more or less suppliers (or let it be set by user)
    if ( this.departmentChoice!=1 && this.departmentChoice!=2 && this.departmentChoice!=3 ) {
      // This might happen in some obscure case and for errors
      // The reason this.departmentChoice isnt set directly in ngOnInit
      // is that I dont want it to be set when changing organisation
      this.departmentChoice = 2;
    }
    this.depChoice ( this.departmentChoice, false );
    this.departmentChangedSubscription = this.organisationService.departmentChanged.subscribe((msg: any) => {
      this.organisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
      this.selectedOrganisation = JSON.parse(localStorage.getItem('rootOrganisation', ));
      this.selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment', ));
      this.departmentChangedSubscription.unsubscribe();
      this.ngOnInit();
    })
    this.getProducts();
  }

  logout() {
    this.authService.logout();
  }

  tabDashboard() {
    this.router.navigate(['/dashboard']);
  }

  tabDashboardAdmin() {
    this.router.navigate(['/dashboardadmin']);
  }

  resetChartData() {
    // This is to reset the data in the chart for riskprio
    for (let chartColumn = 0; chartColumn < 5; chartColumn++) {
      this.riskPrioChartConfig1.series[0]["data"][chartColumn].y = 0;
      this.riskPrioChartConfig2.series[0]["data"][chartColumn].y = 0;
    }

    // This is to reset the data in the chart for productgroup
    for (let chartColumn = 0; chartColumn < 10; chartColumn++) {
      this.productgroupChartConfig1.series[0]["data"][chartColumn].y = 0;
      this.productgroupChartConfig1.series[0]["data"][chartColumn].name = "";
      this.productgroupChartConfig2.series[0]["data"][chartColumn].y = 0;
      this.productgroupChartConfig2.series[0]["data"][chartColumn].name = "";
    }

    for (let chartColumn = 0; chartColumn < 11; chartColumn++) {
      this.restrictionChartConfig1.series[0]["data"][chartColumn].y = 0;
      this.restrictionChartConfig1.series[0]["data"][chartColumn].name = "";
      this.restrictionChartConfig2.series[0]["data"][chartColumn].y = 0;
      this.restrictionChartConfig2.series[0]["data"][chartColumn].name = "";
    }
    this.restrictionChartConfig1.series[0]["data"][0].name = this.translate.instant("RESTRICTION_LIST_AMV_A");
    this.restrictionChartConfig1.series[0]["data"][1].name = this.translate.instant("RESTRICTION_LIST_AMV_B");
    this.restrictionChartConfig1.series[0]["data"][2].name = this.translate.instant("RESTRICTION_LIST_CHEM_LIMIT");
    this.restrictionChartConfig1.series[0]["data"][3].name = this.translate.instant("RESTRICTION_LIST_CHEM_PRIO_P");
    this.restrictionChartConfig1.series[0]["data"][4].name = this.translate.instant("RESTRICTION_LIST_CHEM_PRIO_U");
    this.restrictionChartConfig1.series[0]["data"][5].name = this.translate.instant("RESTRICTION_LIST_REACH_14");
    this.restrictionChartConfig1.series[0]["data"][6].name = this.translate.instant("RESTRICTION_LIST_REACH_17");
    this.restrictionChartConfig1.series[0]["data"][7].name = this.translate.instant("RESTRICTION_LIST_REACH_CANDIDATE");
    this.restrictionChartConfig1.series[0]["data"][8].name = this.translate.instant("RESTRICTION_LIST_SIN");
    this.restrictionChartConfig1.series[0]["data"][9].name = this.translate.instant("RESTRICTION_LIST_SLL");
    this.restrictionChartConfig1.series[0]["data"][10].name = this.translate.instant("RESTRICTION_LIST_WATER_DIRECTIVE");
    this.restrictionChartConfig1.series[0]["data"][11].name = this.translate.instant("RESTRICTION_LIST_BASTA");
    this.restrictionChartConfig1.series[0]["data"][12].name = this.translate.instant("NO_RESTRICION_LIST");
    this.restrictionChartConfig2.series[0]["data"][0].name = this.translate.instant("RESTRICTION_LIST_AMV_A");
    this.restrictionChartConfig2.series[0]["data"][1].name = this.translate.instant("RESTRICTION_LIST_AMV_B");
    this.restrictionChartConfig2.series[0]["data"][2].name = this.translate.instant("RESTRICTION_LIST_CHEM_LIMIT");
    this.restrictionChartConfig2.series[0]["data"][3].name = this.translate.instant("RESTRICTION_LIST_CHEM_PRIO_P");
    this.restrictionChartConfig2.series[0]["data"][4].name = this.translate.instant("RESTRICTION_LIST_CHEM_PRIO_U");
    this.restrictionChartConfig2.series[0]["data"][5].name = this.translate.instant("RESTRICTION_LIST_REACH_14");
    this.restrictionChartConfig2.series[0]["data"][6].name = this.translate.instant("RESTRICTION_LIST_REACH_17");
    this.restrictionChartConfig2.series[0]["data"][7].name = this.translate.instant("RESTRICTION_LIST_REACH_CANDIDATE");
    this.restrictionChartConfig2.series[0]["data"][8].name = this.translate.instant("RESTRICTION_LIST_SIN");
    this.restrictionChartConfig2.series[0]["data"][9].name = this.translate.instant("RESTRICTION_LIST_SLL");
    this.restrictionChartConfig2.series[0]["data"][10].name = this.translate.instant("RESTRICTION_LIST_WATER_DIRECTIVE");
    this.restrictionChartConfig2.series[0]["data"][11].name = this.translate.instant("RESTRICTION_LIST_BASTA");
    this.restrictionChartConfig2.series[0]["data"][12].name = this.translate.instant("NO_RESTRICION_LIST");

    this.specialChartConfig1.series[0]["data"] = [0, 0, 0, 0, 0];
    this.specialChartConfig2.series[0]["data"] = [0, 0, 0, 0, 0];

    this.pictogramChartConfig1.series[0]["data"] = [0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.pictogramChartConfig2.series[0]["data"] = [0, 0, 0, 0, 0, 0, 0, 0, 0];

    this.environmentChartConfig1.series[0]["data"][0].y = 0;
    this.environmentChartConfig1.series[0]["data"][1].y = 0;
    this.environmentChartConfig1.series[0]["data"][2].y = 0;
    this.environmentChartConfig2.series[0]["data"][0].y = 0;
    this.environmentChartConfig2.series[0]["data"][1].y = 0;
    this.environmentChartConfig2.series[0]["data"][2].y = 0;
    this.ecoCounter = [0,0];

    this.dynamicTitle=this.translate.instant("QUANTITY");
    this.specialChartConfig1['yAxis']['title'].text =this.dynamicTitle;
    this.specialChartConfig2['yAxis']['title'].text =this.dynamicTitle;
    this.pictogramChartConfig1['yAxis']['title'].text =this.dynamicTitle;
    this.pictogramChartConfig2['yAxis']['title'].text =this.dynamicTitle;
    this.CMRAChartConfig1['yAxis']['title'].text =this.dynamicTitle;
    this.CMRAChartConfig2['yAxis']['title'].text =this.dynamicTitle;
    this.specialChartConfig1['yAxis']['title'].text =this.dynamicTitle;
    this.specialChartConfig2['yAxis']['title'].text =this.dynamicTitle;

    this.CMRA_amount = [
      [
        {name:'Cancerogent',count:0},
        {name:'Mutagent',count:0},
        {name:'Reprodutionshämmande',count:0},
        {name:'Allergiskt',count:0}
      ],[
        {name:'Cancerogent',count:0},
        {name:'Mutagent',count:0},
        {name:'Reprodutionshämmande',count:0},
        {name:'Allergiskt',count:0}
      ]
    ];
  }

  getProducts(showorg= "") {
    var org = "";
    if ( showorg == "dep" ) {
      org = this.selectedDepartment.id;
    } else {
      org = this.selectedOrganisation.id;
    }
    this.resetChartData();
    this.products = [];
    this.getProductsIsClicked = true;
    this.loadingChangedProducts = true;
    this.CMRAChart1 = false;
    this.CMRAChart2 = false;
    this.pictogramChart1 = false;
    this.pictogramChart2 = false;
    this.environmentChart1 = false;
    this.environmentChart2 = false;
    this.errorDate = "";
    this.changedProductsFrom = this.datePipe.transform(this.changedProductsFrom,'yyyy-MM-dd');
    this.changedProductsTo = this.datePipe.transform(this.changedProductsTo,'yyyy-MM-dd');
    if( (this.changedProductsFrom.substring(0,4)>1899 && this.changedProductsFrom.substring(0,4)<2100)&&
        (this.changedProductsTo.substring(0,4)>1899 && this.changedProductsTo.substring(0,4)<2100))
    {

    } else {
      this.changedProductsFrom = new Date(new Date().getTime() - (100 * 24 * 60 * 60 * 1000))
      this.changedProductsTo = new Date();
      this.errorDate = "Ogiltigt datum";
      return;
    }
    this.allDep = true;
    this.loadRestrictionList();

    if (this.selectedDepartment) {
      this.loadRestrictionList().subscribe(() => {
        this.productService.historyStatisticsReport(org, this.allDep, this.changedProductsFrom, this.changedProductsTo);
        this.productService.getHistoryStatisticsListener().pipe(take(1)).subscribe({
          next: products => {
            if (products != null) {
              this.products = products;

              // Remove all products that are overrides
              this.products = this.products.map( productArray =>
                productArray.filter(product => product.override == null)
              );
              this.sareqIds = [];
              for(var productIndex = 0; productIndex < this.products.length; productIndex++) {
                this.products[productIndex] = this.productConversionsService.convertPictogramsOnProductArray(this.products[productIndex]);
                let tempSareqIds = [];
                for(var i = 0; i < this.products[productIndex].length; i++) {
                  tempSareqIds.push(this.products[productIndex][i].sareq_id);
                }
                this.sareqIds[productIndex]=[];
                this.sareqIds[productIndex]=tempSareqIds;
              }
              this.getHHPhrases();
              this.getHHPhrases().subscribe( () => {
                this.getCMRA_Category();
              });
              this.createRiskPrioCharts();
              this.createProductGroupCharts();
              this.createRestrictionCharts();
              this.createSupplierCharts();
              this.createSpecialCharts();
              this.createPictogramCharts();
              this.createEnvironmentCharts();
              this.loadingChangedProducts = false;
            }
          },
          error: error => {}
        });
      });
    }
  }

  // Create the risk prio chart
  createRiskPrioCharts() {
    this.products.forEach((productArray, i) => {
      if (i > 1) return; // Skip if i is not 0 or 1

      productArray.forEach(product => {
        if (product.risk_prio >= 1 && product.risk_prio <= 5) {
          const notNegativeController = Math.max(0, product.risk_prio - 1); // Ensure it's not negative

          const config = i === 0 ? this.riskPrioChartConfig1 : this.riskPrioChartConfig2;
          const data = config.series[0]["data"][notNegativeController];

          data.name = `${this.translate.instant("LEVEL")} ${product.risk_prio}`;
          data.y += 1;
          data.key = product.risk_prio;
        }
      });
    });



    [this.riskPrioChartConfig1, this.riskPrioChartConfig2].forEach((config, i) => {
      for ( let i = 0; i< config.series[0]['data'].length; i++) {
        if (config.series[0]['data'][i].y == 0) {
          config.series[0]['data'][i].visible = false;
        }
      }
      config['title']['text'] = this.translate.instant("RISK_LEVEL");
      config['subtitle']['text'] = i === 0 ? this.changedProductsFrom : this.changedProductsTo;
    });

    this.updateRiskPrioChart1 = true;
    this.updateRiskPrioChart2 = true;
  }

  // Create the productgroup charts
  createProductGroupCharts() {
    this.groupInfo = [];
    this.groupInfo2 = [];
    const groupNameId = [];
    const groupNameId2 = [];

    for (let productIndex = 0; productIndex < this.products.length; productIndex++) {
      const group_id_exists = this.products.some(productArray =>
        productArray.some(product =>
          product.groups?.some(group => group?.id)
        )
      );

      if (group_id_exists) {
        for (let subProductIndex = 0; subProductIndex < this.products[productIndex].length; subProductIndex++) {
          const product = this.products[productIndex][subProductIndex];

          if (product.groups?.length > 0) {
            for (let groupIndex = 0; groupIndex < product.groups.length; groupIndex++) {
              const groupId = product.groups[groupIndex].id;
              const groupName = product.groups[groupIndex].name;

              if (productIndex === 0) {
                const groupInfoItem = this.groupInfo.find(item => item.id === groupId);
                if (groupInfoItem) {
                  groupInfoItem.count++;
                } else if (groupNameId.indexOf(groupId) === -1) {
                  groupNameId.push(groupId);
                  this.groupInfo.push({ id: groupId, count: 1, name: groupName });
                }
              } else if (productIndex === 1) {
                const groupInfo2Item = this.groupInfo2.find(item => item.id === groupId);
                if (groupInfo2Item) {
                  groupInfo2Item.count++;
                } else if (groupNameId2.indexOf(groupId) === -1) {
                  groupNameId2.push(groupId);
                  this.groupInfo2.push({ id: groupId, count: 1, name: groupName });
                }
              }
            }
          }
        }
      }

      this.groupInfo = this.groupInfo.filter(item => item.id !== null);
      this.groupInfo2 = this.groupInfo2.filter(item => item.id !== null);

      if (this.products[productIndex] && this.products[productIndex].length > 0) {
        for (let xi = 0; xi < 10; xi++) {
          if (productIndex === 0) {
            if (xi < this.groupInfo.length) {
              this.productgroupChartConfig1.series[0]["data"][xi].y = this.groupInfo[xi].count;
              this.productgroupChartConfig1.series[0]["data"][xi].visible = true;
              this.productgroupChartConfig1.series[0]["data"][xi].name = this.groupInfo[xi].name || "Ej angett";
            } else {
              this.productgroupChartConfig1.series[0]["data"][xi].name = "";
              this.productgroupChartConfig1.series[0]["data"][xi].visible = false;
            }
          }
          if (productIndex === 1) {
            if (xi < this.groupInfo2.length) {
              this.productgroupChartConfig2.series[0]["data"][xi].y = this.groupInfo2[xi].count;
              this.productgroupChartConfig2.series[0]["data"][xi].visible = true;
              this.productgroupChartConfig2.series[0]["data"][xi].name = this.groupInfo2[xi].name || "Ej angett";
            } else {
              this.productgroupChartConfig2.series[0]["data"][xi].name = "";
              this.productgroupChartConfig2.series[0]["data"][xi].visible = false;
            }
          }
        }
      }
    }

    this.productgroupChartConfig1['title']['text'] = this.translate.instant("PRODUCT_GROUP");
    this.productgroupChartConfig1['subtitle']['text'] = this.changedProductsFrom;
    this.productgroupChartConfig2['title']['text'] = this.translate.instant("PRODUCT_GROUP");
    this.productgroupChartConfig2['subtitle']['text'] = this.changedProductsTo;
    this.updateProductGroupChart1 = true;
    this.updateProductGroupChart2 = true;
  }


  // Restriction chart
  createRestrictionCharts() {
    var numberOfCharts = 2;
    for (var restrictionProductIndex = 0; restrictionProductIndex < numberOfCharts; restrictionProductIndex++) {
        this.restrictionListsCount[restrictionProductIndex] = ({
          'item': "",
          'list_amv_a_count': 0,
          'list_amv_b_count': 0,
          'list_chem_limit_count': 0,
          'list_chem_prio_p_count': 0,
          'list_chem_prio_u_count': 0,
          'list_reach_14_count': 0,
          'list_reach_17_count': 0,
          'list_reach_candidate_count': 0,
          'list_sin_count': 0,
          'list_sll_count': 0,
          'list_water_directive_count': 0,
          'list_BASTA_count': 0,
          'no_list_count': 0
        });
        var duplicateaArray=[];
        var temprestriktionList=[];
          for (var key = 0; key < this.restriktionList[restrictionProductIndex].length; key++) {
            var count = 1;
            if (duplicateaArray.indexOf(this.restriktionList[restrictionProductIndex][key].Sareq_id) < 1) {
            count=1;
            duplicateaArray.push(this.restriktionList[restrictionProductIndex][key].Sareq_id);
            var item = ({
                'item': this.restriktionList[restrictionProductIndex][key].Sareq_id,
                'list_amv_a_count': 0,
                'list_amv_b_count': 0,
                'list_chem_limit_count': 0,
                'list_chem_prio_p_count': 0,
                'list_chem_prio_u_count': 0,
                'list_reach_14_count': 0,
                'list_reach_17_count': 0,
                'list_reach_candidate_count': 0,
                'list_sin_count': 0,
                'list_sll_count': 0,
                'list_water_directive_count': 0,
                'list_BASTA_count': 0,
                'count':count
            });
            for (var key2 = 0; key2 < this.restriktionList[restrictionProductIndex].length; key2++) {
              if (item.item == this.restriktionList[restrictionProductIndex][key2].Sareq_id) {
                var count=1;
                if (this.restriktionList[restrictionProductIndex][key2].list_amv_a == 1) {
                  item.list_amv_a_count += count;
                }
                if (this.restriktionList[restrictionProductIndex][key2].list_amv_b == 1) {
                  item.list_amv_b_count += count;
                }
                if (this.restriktionList[restrictionProductIndex][key2].list_chem_limit == 1) {
                  item.list_chem_limit_count += count;
                }
                if (this.restriktionList[restrictionProductIndex][key2].list_chem_prio_p == 1) {
                  item.list_chem_prio_p_count += count;
                }
                if (this.restriktionList[restrictionProductIndex][key2].list_chem_prio_u == 1) {
                  item.list_chem_prio_u_count += count;
                }
                if (this.restriktionList[restrictionProductIndex][key2].list_reach_14 == 1) {
                  item.list_reach_14_count += count;
                }
                if (this.restriktionList[restrictionProductIndex][key2].list_reach_17 == 1) {
                  item.list_reach_17_count += count;
                }
                if (this.restriktionList[restrictionProductIndex][key2].list_reach_candidate == 1) {
                  item.list_reach_candidate_count += count;
                }
                if (this.restriktionList[restrictionProductIndex][key2].list_sin == 1) {
                  item.list_sin_count += count;
                }
                if (this.restriktionList[restrictionProductIndex][key2].list_sll == 1) {
                  item.list_sll_count += count;
                }
                if (this.restriktionList[restrictionProductIndex][key2].list_water_directive == 1) {
                  item.list_water_directive_count += count;
                }
                if (this.restriktionList[restrictionProductIndex][key2].list_BASTA == 1) {
                  item.list_BASTA_count += count;
                }
              }
            }
            temprestriktionList.push(item);
          } else {
            continue;
          }
        }
        if (this.selectedDepartment.id != this.selectedOrganisation.id) {
          var deps = [];
          var depProductsId = [];
          var depProductsIdandCount = [];
          for (var depsid = 0; depsid < this.selectedDepartment.orgs.length; depsid++) {
            deps.push(this.selectedDepartment.orgs[depsid]);
            if (this.selectedDepartment.orgs[depsid].orgs) {
              for (var depsid2 = 0; depsid2 < this.selectedDepartment.orgs[depsid].orgs.length; depsid2++) {
                deps.push(this.selectedDepartment.orgs[depsid].orgs[depsid2]);
              }
            }
          }
          deps.push(this.selectedDepartment);
          for (var index = 0;index < this.products[restrictionProductIndex].length; index++) {
            if (deps.indexOf(this.products[restrictionProductIndex][index].organisation_id > -1)&&this.products[restrictionProductIndex][index].organisation_id_list) {
              var depIndexList = this.products[restrictionProductIndex][index].organisation_id_list.split(',');
              var depCount = 0;
              for (var depIndex = 0; depIndex < depIndexList.length; depIndex++){
                for (var depDep = 0; depDep < deps.length; depDep++){
                  if (depIndexList[depIndex] == deps[depDep].id){
                    depCount++;
                  }
                }
              }

            depProductsId.push(this.products[restrictionProductIndex][index].sareq_id);
            depProductsIdandCount.push({'id': this.products[restrictionProductIndex][index].sareq_id, 'count':depCount});
          }
        }

        var numOnList=0;
        var numNotOnList=0;
        temprestriktionList=_.uniqBy(temprestriktionList, 'item');
        // Remove items not in products
        temprestriktionList.forEach((element,index)=>{
        var found=false;
        this.products[restrictionProductIndex].forEach(element2=>{
          if(element2.sareq_id==element.item){
            found=true;
            return;
          }
        })
        if(found==false){
          temprestriktionList.splice(index,1);
        }
      })
      for (var key = 0; key < temprestriktionList.length; key++){
        var isOnList = false;
        var count = 0;
        if (depProductsId.indexOf(temprestriktionList[key].item) >- 1){
          for (var depProductsIdandCountindex = 0;
              depProductsIdandCountindex < depProductsIdandCount.length;
              depProductsIdandCountindex++)
          {
            if (depProductsIdandCount[depProductsIdandCountindex].id == temprestriktionList[key].item){
              count = depProductsIdandCount[depProductsIdandCountindex].count;
            }
          }

          if (temprestriktionList[key].list_amv_a_count > 0) {
            this.restrictionListsCount[restrictionProductIndex].list_amv_a_count += count;
            isOnList = true;
          }

          if (temprestriktionList[key].list_amv_b_count > 0) {
            this.restrictionListsCount[restrictionProductIndex].list_amv_b_count += count;
            isOnList = true;
          }

          if (temprestriktionList[key].list_chem_limit_count > 0) {
            this.restrictionListsCount[restrictionProductIndex].list_chem_limit_count += count;
            isOnList=true;
          }

          if (temprestriktionList[key].list_chem_prio_p_count > 0) {
            this.restrictionListsCount[restrictionProductIndex].list_chem_prio_p_count += count;
            isOnList=true;
          }

          if (temprestriktionList[key].list_chem_prio_u_count > 0) {
            this.restrictionListsCount[restrictionProductIndex].list_chem_prio_u_count += count;
            isOnList=true;
          }

          if (temprestriktionList[key].list_reach_14_count > 0) {
            this.restrictionListsCount[restrictionProductIndex].list_reach_14_count += count;
            isOnList=true;
          }

          if (temprestriktionList[key].list_reach_17_count > 0){
            this.restrictionListsCount[restrictionProductIndex].list_reach_17_count += count;
            isOnList=true;
          }

          if (temprestriktionList[key].list_reach_candidate_count > 0){
            this.restrictionListsCount[restrictionProductIndex].list_reach_candidate_count += count;
            isOnList=true;
          }

          if (temprestriktionList[key].list_sin_count > 0) {
            this.restrictionListsCount[restrictionProductIndex].list_sin_count += count;
            if (this.subscribesToSIN == true) {
            isOnList=true;
            }
          }

          if(temprestriktionList[key].list_sll_count > 0) {
            this.restrictionListsCount[restrictionProductIndex].list_sll_count += count;
            if (this.subscribesToSLL == true) {
            isOnList=true;
            }
          }

          if(temprestriktionList[key].list_water_directive_count > 0) {
            this.restrictionListsCount[restrictionProductIndex].list_water_directive_count += count;
            isOnList=true;
          }

          if(temprestriktionList[key].list_BASTA_count > 0) {
            this.restrictionListsCount[restrictionProductIndex].list_BASTA_count += count;
            isOnList=true;
          }
        }

        if(isOnList==true) {
          numOnList++;
        }
      }

      this.restrictionListsCount[restrictionProductIndex].no_list_count = this.products[restrictionProductIndex].length - numOnList;
    } else {
      temprestriktionList=_.uniqBy(temprestriktionList, 'item');
      // Remove items not in products
      temprestriktionList.forEach((element,index)=>{
        var found=false;
        this.products[restrictionProductIndex].forEach(element2=>{
          if(element2.sareq_id==element.item){
            found=true;
            return;
          }
        })
        if(found==false){
          temprestriktionList.splice(index,1);
        }
      })
      for (var key = 0; key < temprestriktionList.length; key++) {
        if (temprestriktionList[key].list_amv_a_count > 0) {
          this.restrictionListsCount[restrictionProductIndex].list_amv_a_count += temprestriktionList[key].count;
        }

        if (temprestriktionList[key].list_amv_b_count > 0) {
          this.restrictionListsCount[restrictionProductIndex].list_amv_b_coun += temprestriktionList[key].count;
        }

        if (temprestriktionList[key].list_chem_limit_count > 0) {
          this.restrictionListsCount[restrictionProductIndex].list_chem_limit_count += temprestriktionList[key].count;
        }

        if (temprestriktionList[key].list_chem_prio_p_count > 0) {
          this.restrictionListsCount[restrictionProductIndex].list_chem_prio_p_count+=temprestriktionList[key].count;
        }

        if (temprestriktionList[key].list_chem_prio_u_count > 0) {
          this.restrictionListsCount[restrictionProductIndex].list_chem_prio_u_count += temprestriktionList[key].count;
        }

        if(temprestriktionList[key].list_reach_14_count > 0) {
          this.restrictionListsCount[restrictionProductIndex].list_reach_14_count += temprestriktionList[key].count;
        }
        if(temprestriktionList[key].list_reach_17_count > 0) {
          this.restrictionListsCount[restrictionProductIndex].list_reach_17_count += temprestriktionList[key].count;
        }

        if (temprestriktionList[key].list_reach_candidate_count > 0) {
          this.restrictionListsCount[restrictionProductIndex].list_reach_candidate_count += temprestriktionList[key].count;
        }

        if (temprestriktionList[key].list_sin_count > 0) {
          this.restrictionListsCount[restrictionProductIndex].list_sin_count += temprestriktionList[key].count;
        }

        if(temprestriktionList[key].list_sll_count > 0) {
          this.restrictionListsCount[restrictionProductIndex].list_sll_count += temprestriktionList[key].count;
        }

        if(temprestriktionList[key].list_water_directive_count > 0) {
          this.restrictionListsCount[restrictionProductIndex].list_water_directive_count += temprestriktionList[key].count;
        }

        if (temprestriktionList[key].list_BASTA_count > 0) {
          this.restrictionListsCount[restrictionProductIndex].list_BASTA_count += temprestriktionList[key].count;
        }
      }
      this.restrictionListsCount[restrictionProductIndex].no_list_count = this.products[restrictionProductIndex].length - temprestriktionList.length;
    }

    if (restrictionProductIndex == 0) {
      this.restrictionChartConfig1.series[0]["data"][0].y = this.restrictionListsCount[restrictionProductIndex].list_amv_a_count;
      this.restrictionChartConfig1.series[0]["data"][1].y = this.restrictionListsCount[restrictionProductIndex].list_amv_b_count;
      this.restrictionChartConfig1.series[0]["data"][2].y = this.restrictionListsCount[restrictionProductIndex].list_chem_limit_count;
      this.restrictionChartConfig1.series[0]["data"][3].y = this.restrictionListsCount[restrictionProductIndex].list_chem_prio_p_count;
      this.restrictionChartConfig1.series[0]["data"][4].y = this.restrictionListsCount[restrictionProductIndex].list_chem_prio_u_count;
      this.restrictionChartConfig1.series[0]["data"][5].y = this.restrictionListsCount[restrictionProductIndex].list_reach_14_count;
      this.restrictionChartConfig1.series[0]["data"][6].y = this.restrictionListsCount[restrictionProductIndex].list_reach_17_count;
      this.restrictionChartConfig1.series[0]["data"][7].y = this.restrictionListsCount[restrictionProductIndex].list_reach_candidate_count;
      this.restrictionChartConfig1.series[0]["data"][8].y = this.restrictionListsCount[restrictionProductIndex].list_sin_count;
      this.restrictionChartConfig1.series[0]["data"][9].y = this.restrictionListsCount[restrictionProductIndex].list_sll_count;
      this.restrictionChartConfig1.series[0]["data"][10].y = this.restrictionListsCount[restrictionProductIndex].list_water_directive_count;
      this.restrictionChartConfig1.series[0]["data"][11].y = this.restrictionListsCount[restrictionProductIndex].list_BASTA_count;
      this.restrictionChartConfig1.series[0]["data"][12].y = this.restrictionListsCount[restrictionProductIndex].no_list_count;

      for (var n = 0; n < 12; n++){
        this.restrictionChartConfig1.series[0]["data"][n].y==0?
        this.restrictionChartConfig1.series[0]["data"][n].visible=false:
        this.restrictionChartConfig1.series[0]["data"][n].visible=true;
      }

      // The limitlist isnt visible (obsolete)
      this.restrictionChartConfig1.series[0]["data"][2].visible = false;

      if (this.subscribesToSIN != true) {
        this.restrictionChartConfig1.series[0]["data"][8].visible = false;
      }

      if (this.subscribesToSLL != true) {
        this.restrictionChartConfig1.series[0]["data"][9].visible = false;
      }

      if(this.subscribesToBASTA != true){
        this.restrictionChartConfig1.series[0]["data"][11].visible = false;
      }
      this.restrictionChartConfig1['title']['text'] = this.translate.instant("PRODUCTS_RESTRICTIONLIST");
      this.restrictionChartConfig1['subtitle']['text'] = this.changedProductsFrom;
      this.updateRestrictionChart1 = true;
    }
    if ( restrictionProductIndex == 1 ) {
      this.restrictionChartConfig2.series[0]["data"][0].y = this.restrictionListsCount[restrictionProductIndex].list_amv_a_count;
      this.restrictionChartConfig2.series[0]["data"][1].y = this.restrictionListsCount[restrictionProductIndex].list_amv_b_count;
      this.restrictionChartConfig2.series[0]["data"][2].y = this.restrictionListsCount[restrictionProductIndex].list_chem_limit_count;
      this.restrictionChartConfig2.series[0]["data"][3].y = this.restrictionListsCount[restrictionProductIndex].list_chem_prio_p_count;
      this.restrictionChartConfig2.series[0]["data"][4].y = this.restrictionListsCount[restrictionProductIndex].list_chem_prio_u_count;
      this.restrictionChartConfig2.series[0]["data"][5].y = this.restrictionListsCount[restrictionProductIndex].list_reach_14_count;
      this.restrictionChartConfig2.series[0]["data"][6].y = this.restrictionListsCount[restrictionProductIndex].list_reach_17_count;
      this.restrictionChartConfig2.series[0]["data"][7].y = this.restrictionListsCount[restrictionProductIndex].list_reach_candidate_count;
      this.restrictionChartConfig2.series[0]["data"][8].y = this.restrictionListsCount[restrictionProductIndex].list_sin_count;
      this.restrictionChartConfig2.series[0]["data"][9].y = this.restrictionListsCount[restrictionProductIndex].list_sll_count;
      this.restrictionChartConfig2.series[0]["data"][10].y = this.restrictionListsCount[restrictionProductIndex].list_water_directive_count;
      this.restrictionChartConfig2.series[0]["data"][11].y = this.restrictionListsCount[restrictionProductIndex].list_BASTA_count;
      this.restrictionChartConfig2.series[0]["data"][12].y = this.restrictionListsCount[restrictionProductIndex].no_list_count;

      for (var n = 0; n < 12; n++){
        this.restrictionChartConfig2.series[0]["data"][n].y==0?
        this.restrictionChartConfig2.series[0]["data"][n].visible=false:
        this.restrictionChartConfig2.series[0]["data"][n].visible=true;
      }

      // The limitlist isnt visible (obsolete)
      this.restrictionChartConfig2.series[0]["data"][2].visible = false;

      if (this.subscribesToSIN != true) {
        this.restrictionChartConfig2.series[0]["data"][8].visible = false;
      }

      if (this.subscribesToSLL != true) {
        this.restrictionChartConfig2.series[0]["data"][9].visible = false;
      }

      if(this.subscribesToBASTA != true){
        this.restrictionChartConfig2.series[0]["data"][11].visible = false;
      }
    this.restrictionChartConfig2['title']['text'] = this.translate.instant("PRODUCTS_RESTRICTIONLIST");
    this.restrictionChartConfig2['subtitle']['text'] = this.changedProductsTo;
    this.updateRestrictionChart2 = true;
  }
}
  }

  loadRestrictionList() {
    return new Observable(observer => {
      if (this.organisation) {
        this.productService.getRestriktionListFromTwoDates(this.selectedOrganisation.id, this.allDep, this.changedProductsFrom, this.changedProductsTo);
        this.productService.getProductRestTwoDatesDataListener().pipe(take(1)).subscribe({
          next: info => {
            if (info == null) {}
            else {
              this.restriktionList[0] = info[0];
              this.restriktionList[1] = info[1];
            }
            observer.next();
            observer.complete();
          },
          error: error => {
            console.log("Error: ", error);
            observer.error(error);
          }
        })
      } else {
        observer.next();
        observer.complete();
      }
    });
  }

  getCMRA_Category(){
    let numberOfCharts = 2;
    for(var productIndex = 0; productIndex < numberOfCharts; productIndex++){
      this.products[productIndex].forEach(element => {
        element.CMRA_Category=[];
        if((!element.h_phrases||element.h_phrases==null)&&element.allergic!=1){
          return;
        }
        if(_.toUpper(element.h_phrases).indexOf("H350")>-1||_.toUpper(element.h_phrases).indexOf("H351")>-1){
          element.CMRA_Category.push('C');
          this.CMRA_amount[productIndex][0].count++;
        }
        if(_.toUpper(element.h_phrases).indexOf("340")>-1||_.toUpper(element.h_phrases).indexOf("H341")>-1){
          element.CMRA_Category.push('M');
          this.CMRA_amount[productIndex][1].count++;
        }
        if(_.toUpper(element.h_phrases).indexOf("360")>-1||_.toUpper(element.h_phrases).indexOf("H361")>-1||_.toUpper(element.h_phrases).indexOf("H362")>-1){
          element.CMRA_Category.push('R');
          this.CMRA_amount[productIndex][2].count++;
        }
        if(_.toUpper(element.h_phrases).indexOf("H317")>-1||_.toUpper(element.h_phrases).indexOf("H334")>-1
            ||_.toUpper(element.h_phrases).indexOf("EUH208")>-1||_.toUpper(element.h_phrases).indexOf("EUH205")>-1
            ||_.toUpper(element.h_phrases).indexOf("EUH204")>-1||_.toUpper(element.h_phrases).indexOf("EUH202")>-1
            ||_.toUpper(element.h_phrases).indexOf("EUH203")>-1||element.allergic==1)
        {
          element.CMRA_Category.push('A');
          this.CMRA_amount[productIndex][3].count++;
        }
      })
    }
    this.createCMRACharts(); // To make sure the charts are created after the data is set
  }

  createSupplierCharts(){
    var numberOfCharts = 2;
    for(var productIndex = 0; productIndex < numberOfCharts; productIndex++){
      this.productsById[productIndex] = _(this.products[productIndex])
        .groupBy('supplier_id')
        .map((items,supplier_id) => ({ id:supplier_id, count: items.length, name: items[0].supplier_name }))
        .value();
      this.productsById[productIndex] = _.sortBy(this.productsById[productIndex], 'count').reverse();
      this.productsByIdShort[productIndex]=_.cloneDeep(this.productsById[productIndex]);
      if ( ( this.productsByIdShort[productIndex].length ) < this.productsByIdLength[productIndex] ) {
        this.productsByIdLength = this.productsByIdShort[productIndex].length;
      } else {
        this.productsByIdLength=this.productsByIdLength;
      }
      this.productsByIdShort[productIndex].length=this.productsByIdLength;
      this.amountInProductsByIdShort=0;
      this.productsByIdShort[productIndex].forEach( ( element,index ) => {
        this.amountInProductsByIdShort+=element.count;
      });
      this.notInAmountInProductsByIdShort = this.products[productIndex].length - this.amountInProductsByIdShort;
      this.productsByIdShort[productIndex].push ( { id:'-', count: this.notInAmountInProductsByIdShort, name: 'Övriga' } );
      if ( this.productsByIdLength != 6 ) {
       this.productsByIdLength++;
      }
      if ( productIndex == 0) {
        this.supplierChartConfig1.series[0]["data"]=[];
        for ( var n=0; n<this.productsByIdLength; n++ ) {
          if (
            this.supplierChartConfig1 &&
            this.supplierChartConfig1.series &&
            this.supplierChartConfig1.series[0] &&
            this.supplierChartConfig1.series[0]["data"] &&
            this.productsByIdShort &&
            this.productsByIdShort[productIndex] &&
            this.productsByIdShort[productIndex][n]
          ) {
            this.supplierChartConfig1.series[0]["data"].push({
              name: this.productsByIdShort[productIndex][n].name,
              y: this.productsByIdShort[productIndex][n].count
            });
          }
        }

        for ( var n = 0; n < this.productsByIdLength; n++ ) {
          if (this.supplierChartConfig1.series[0]["data"][n] && this.supplierChartConfig1.series[0]["data"][n].y){
            this.supplierChartConfig1.series[0]["data"][n].y==0?
            this.supplierChartConfig1.series[0]["data"][n].visible=false:
            this.supplierChartConfig1.series[0]["data"][n].visible=true;
          }
        }
        this.supplierChartConfig1['title']['text'] = this.translate.instant("SUPPLIERS");
        this.supplierChartConfig1['subtitle']['text'] = this.changedProductsFrom;
        this.updateSupplierChart1 = true;
      }
      if ( productIndex == 1) {
        this.supplierChartConfig2.series[0]["data"]=[];
        for ( var n = 0; n < this.productsByIdLength; n++ ) {
          console.log(this.productsByIdShort[productIndex][n]);
          if ( this.productsByIdShort[productIndex][n] ){
            this.supplierChartConfig2.series[0]["data"].push({name:this.productsByIdShort[productIndex][n].name,y:this.productsByIdShort[productIndex][n].count});
          }
        }
        for (var n = 0; n < this.productsByIdLength; n++){
          if (this.supplierChartConfig2.series[0]["data"][n] && this.supplierChartConfig2.series[0]["data"][n].y){
            this.supplierChartConfig2.series[0]["data"][n].y==0?
            this.supplierChartConfig2.series[0]["data"][n].visible=false:
            this.supplierChartConfig2.series[0]["data"][n].visible=true;
          }
        }
        this.supplierChartConfig2['title']['text'] = this.translate.instant("SUPPLIERS");
        this.supplierChartConfig2['subtitle']['text'] = this.changedProductsTo;
        this.updateSupplierChart2 = true;
      }
    }
  }

  createCMRACharts(){
    var numberOfCharts = 2;
    for(var productIndex = 0; productIndex < numberOfCharts; productIndex++){
      if ( productIndex == 0 ) {
        this.CMRAChartConfig1.series[0]["data"]=[];
        for(var n=0;n<this.CMRA_amount[productIndex].length;n++){
          this.CMRAChartConfig1.series[0]["data"].push({name:this.CMRA_amount[productIndex][n].name,y:this.CMRA_amount[productIndex][n].count});
        }

        for (var n = 0; n < this.CMRA_amount[productIndex].length; n++){
          this.CMRAChartConfig1.series[0]["data"][n].y==0?
          this.CMRAChartConfig1.series[0]["data"][n].visible=false:
          this.CMRAChartConfig1.series[0]["data"][n].visible=true;
        }
        this.CMRAChartConfig1.title.text=this.translate.instant("CMRA_CATEGORY");
        this.CMRAChartConfig1.subtitle.text=this.changedProductsFrom;
        this.CMRAChart1 = true;
        this.updateCMRAChart1 = true;

      }
      if ( productIndex == 1 ) {
        this.CMRAChartConfig2.series[0]["data"]=[];
        for(var n=0;n<this.CMRA_amount[productIndex].length;n++){
          this.CMRAChartConfig2.series[0]["data"].push({name:this.CMRA_amount[productIndex][n].name,y:this.CMRA_amount[productIndex][n].count});
        }

        for (var n = 0; n < this.CMRA_amount[productIndex].length; n++){
          this.CMRAChartConfig2.series[0]["data"][n].y==0?
          this.CMRAChartConfig2.series[0]["data"][n].visible=false:
          this.CMRAChartConfig2.series[0]["data"][n].visible=true;
        }
        this.CMRAChartConfig2.title.text=this.translate.instant("CMRA_CATEGORY");
        this.CMRAChartConfig2.subtitle.text=this.changedProductsTo;
        this.CMRAChart2 = true;
        this.updateCMRAChart1 = true;
      }
    }
  }

  getHHPhrases() {
    return new Observable(observer => {
      this.productService.getHPhrasesSeveralArrays(this.selectedOrganisation.id, this.sareqIds);
      this.productService.getProductDataListener().pipe(take(1)).subscribe({
        next: hPhrases => {
        if (hPhrases != null) {
          for(var productItemIndex = 0; productItemIndex < this.products.length; productItemIndex++){
            for(var i = 0; i < hPhrases[productItemIndex].length; i++){
              for(var j = 0; j < this.products[productItemIndex].length; j++) {
                if(hPhrases[productItemIndex][i].sareq_id == this.products[productItemIndex][j].sareq_id) {
                  if(this.products[productItemIndex][j].h_phrases == undefined) {
                    this.products[productItemIndex][j]['h_phrases'] = [];
                  }
                  this.products[productItemIndex][j]['h_phrases'].push(hPhrases[productItemIndex][i].hphrases);
                }
              }
            }
          }
        }
          observer.next();
          observer.complete();
        },
        error: error => {
        console.log("Error: ", error);
      }
    })
  });
  }

  // Create the special chart
  createSpecialCharts() {
    var numberOfCharts = 2;
    for(var productIndex = 0; productIndex < numberOfCharts; productIndex++){
      const config = productIndex === 0 ? this.specialChartConfig1 : this.specialChartConfig2;
      for(var i = 0; i < this.products[productIndex].length; i++){
        if ((this.products[productIndex][i].information != null) && (this.products[productIndex][i].information != "") && (this.products[productIndex][i].information != " ")) {
          config.series[0]["data"][0] += 1;
        }
        if ((this.products[productIndex][i].training != null) && (this.products[productIndex][i].training != "") && (this.products[productIndex][i].training != " ")) {
          config.series[0]["data"][1] += 1;
        }
        if ((this.products[productIndex][i].health_control != null) && (this.products[productIndex][i].health_control != "") && (this.products[productIndex][i].health_control != " ")) {
          config.series[0]["data"][2] += 1;
        }
        if ((this.products[productIndex][i].fitness_for_duty != null) && (this.products[productIndex][i].fitness_for_duty != "") && (this.products[productIndex][i].fitness_for_duty != " ")) {
          config.series[0]["data"][3] += 1;
        }
        if ((this.products[productIndex][i].no_special_demands != null) && (this.products[productIndex][i].no_special_demands != "") && (this.products[productIndex][i].no_special_demands != " ")) {
          config.series[0]["data"][4] += 1;
        }
      }
      config.xAxis["categories"][0] = "<div class='text-center'>" +
          this.translate.instant("INFORMATION_DEMAND") + "<br>(AFS 2023:10, 7 kap. 23 §)" + "</div>";

      config.xAxis["categories"][1] = "<div class='text-center'>" +
          this.translate.instant("TRAINING") + "<br>(AFS 2023:10, 8 kap. 7-10 §)" + "</div>";

      config.xAxis["categories"][2] = "<div class='text-center'>" +
          this.translate.instant("HEALTH_CONTROL") + "<br>(AFS 2023:10, 8 kap. 11 §)" + "</div>";

      config.xAxis["categories"][3] = "<div class='text-center'>" +
          this.translate.instant("FITNESS_FOR_DUTY") + "<br>(AFS 2023:10, 8 kap. 12 §)" + "</div>";

      config.xAxis["categories"][4] = "<div class='text-center'>" +
          this.translate.instant("NO_SPECIAL_DEMANDS") +"</div>";

      config.title.text = this.translate.instant("SPECIAL_REQUIREMENTS");
      this.updateSpecialChart1 = true;
      this.SpecialChart1 = true;
      this.updateSpecialChart2 = true;
      this.SpecialChart2 = true;
    }
  }

  // Create the Pictogram chart
  createPictogramCharts() {
    var numberOfCharts = 2;
    var pictogramsCount;
    for(var productIndex = 0; productIndex < numberOfCharts; productIndex++){
      const config = productIndex === 0 ? this.pictogramChartConfig1 : this.pictogramChartConfig2;
      for(var i = 0; i < this.products[productIndex].length; i++){
        if ((this.products[productIndex][i].pictograms) && (this.products[productIndex][i].pictograms.length > 0)) {
          pictogramsCount = this.products[productIndex][i].pictograms.length;
          for (var j = 0; j < pictogramsCount; j += 1) {
            switch (this.products[productIndex][i].pictograms[j].name) {
              case "clp_is_corrosive":
                config.series[0]["data"][0] += 1;
                break;
              case "clp_is_gas_under_pressure":
                config.series[0]["data"][1] += 1;
                break;
              case "clp_is_caution":
                config.series[0]["data"][2] += 1;
                break;
              case "clp_is_explosive":
                config.series[0]["data"][3] += 1;
                break;
              case "clp_is_flammable":
                config.series[0]["data"][4] += 1;
                break;
              case "clp_is_dangerous_to_the_environment":
                config.series[0]["data"][5] += 1;
                break;
              case "clp_is_oxidising":
                config.series[0]["data"][6] += 1;
                break;
              case "clp_is_longer_term_health_hazards":
                config.series[0]["data"][7] += 1;
                break;
              case "clp_is_toxic":
                config.series[0]["data"][8] += 1;
                break;
              default:
                break;
            }
          }
        }
      }
      config.title.text = this.translate.instant("PICTOGRAM");
      productIndex === 0 ? config.subtitle.text = this.changedProductsFrom : config.subtitle.text = this.changedProductsTo;
      // Usage:
      const categories = ["clp_acid-tiny", 'clp_bottle-tiny', 'clp_exclamation-tiny', 'clp_explosion-tiny', 'clp_flame-tiny', "clp_pollution-tiny", 'clp_roundflame-tiny', 'clp_silhouete-tiny', 'clp_skull-tiny'];
      productIndex !== 0 ?
        this.pictogramChartConfig2['xAxis'] = this.createXAxisConfig(categories,productIndex) :
        this.pictogramChartConfig1['xAxis'] = this.createXAxisConfig(categories,productIndex);
      productIndex === 0 ? this.updatePictogramChart1 = true : this.updatePictogramChart2 = true;
      productIndex === 0 ? this.pictogramChart1 = true : this.pictogramChart2 = true;
    }
  }

  createEnvironmentCharts(){
    if (this.subscribesToReportEnvironment) {
      var numberOfCharts = 2;
      for(var productIndex = 0; productIndex < numberOfCharts; productIndex++){
        const config = productIndex === 0 ? this.environmentChartConfig1 : this.environmentChartConfig2;
        for(var i = 0; i < this.products[productIndex].length; i++){
          if (this.products[productIndex][i].eco_eu) {
            config.series[0]["data"][0].y +=1;
            this.ecoCounter[productIndex] += 1;
          }
          if (this.products[productIndex][i].eco_svanen) {
            config.series[0]["data"][1].y +=1;
            this.ecoCounter[productIndex] += 1;
          }
          if (this.products[productIndex][i].eco_GEC) {
            config.series[0]["data"][2].y +=1;
            this.ecoCounter[productIndex] += 1;
          }
        }
        config.series[0]["data"][0].name = this.translate.instant("EU_FLOWER");
        config.series[0]["data"][1].name = this.translate.instant("NORDIC_ECOLABELLED");
        config.series[0]["data"][2].name = this.translate.instant("GOOD_ENVIROMENTAL_CHOICE");
        config.title.text = this.translate.instant("ECOLABELS");
        productIndex === 0 ? config.subtitle.text = this.changedProductsFrom : config.subtitle.text = this.changedProductsTo;
        if (this.ecoCounter[productIndex] && this.ecoCounter[productIndex] && this.ecoCounter[productIndex]){
          productIndex === 0 ? this.updateEnvironmentChart1 = true : this.updateEnvironmentChart2 = true;
          productIndex === 0 ? this.environmentChart1 = true : this.environmentChart2 = true;
        }

      }
    }
  }

  createXAxisConfig(categories: string[],index) {
    return {
      categories: categories,
      labels: {
        rotation: 0, // Add this line
        x: 5,
        useHTML: true,
        formatter: function () {
          return '<span><br/><img class="" title="' + this.value + '" id="pictogram' + this.value + index + '"' + 'style="padding-bottom: 30px; padding-top: 5px; padding-right:40px; max-width:100!important;cursor:none;transform: rotate(0) !important;" src="/images/pictograms/' + this.value + '.png" /></span>';
        },
      },
    };
  }

  depChoice( choice, clicked ){
    if ( clicked == true ){
      if (choice == this.departmentChoice ){
        return
      } // I dont want the page to reload if the user clicks the same as it is
      else if ( choice == 1 || choice == 2 || choice == 3){
        this.departmentChoice = choice;
      } else {
        this.departmentChoice = this.departmentChoice;
      }
    }
    switch(this.departmentChoice){
      case 1:
        this.allDep = true;
        this.getProducts( "" );
      break;
      case 2:
        this.allDep = true;
        this.getProducts( "dep" );
      break;
      case 3:
        this.allDep = false;
        this.getProducts( "dep" );
      break;
    }
  }
}
