<div *ngIf="loadingData" class="flex justify-center items-center mt-4 mb-4">
    <div class="loader"></div>
</div>

<div class="flex flex-col px-4 max-w-full py-2 mb-4 border border-gray-200">
    <div class="flex justify-between">
        <button class="px-4 py-2" title="{{'BACK' | translate}}" (click)="gotoControlPanel()">
          <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
            <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
            <path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
          </svg>
        </button>
        <button *ngIf="currentUser.is_super_user" (click)="addSubstituteItem()" class="button-cdocblue mt-4">
            <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"/>
                <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
            <span class="text-white font-bold">{{"ADD" | translate}}</span>
        </button>
    </div>
</div>

<div *ngIf="loadingData == false" class="flex flex-col bg-white w-full rounded shadow-lg m-4 mb-4 p-7">
    <div class="overflow-x-auto ">
        <label class="text-lg font-bold mb-4">{{ 'SUBSTITUTIONLIST_HELP_PHRASE1' | translate }}</label>

        <div *ngIf="currentUser.is_super_user&&addItems" >
            <app-sareq-search (dataOutput)="addProduct($event)"></app-sareq-search>
        </div>

        <table class="table-auto bg-white mb-4 w-full" st-table="displayedManufacturers" st-safe-src="displayedManufacturers">
          <thead>
            <tr>
                <th class="table-row-header-no-border no-sort" colspan="12">
                    <div class="flex flex-col md:flex-row">
                        <input st-search="" type="search" [(ngModel)]="productSearch.name" id="productAddSearchname" (input)="filterSearchItems()"
                            class="flex md:w-1/3 items-center py-2 px-4 rounded-full border border-gray-400 mr-2" placeholder="{{'SEARCH_PRODUCT_NAME' | translate}} ..." />

                        <input st-search="" type="search" [(ngModel)]="productSearch.supplier_name" id="productAddSearchsuppliername" (input)="filterSearchItems()"
                            class="flex md:w-1/3 items-center py-2 px-4 rounded-full border border-gray-400 mr-2" placeholder="{{'SEARCH_MANUFACTURER' | translate}} ..." />
                    </div>
                </th>
            </tr>
            <tr>
              <th class="table-row-header sort-header" (click)="sortItem('name', 1)" st-sort="name">{{"NAME" | translate}}</th>
              <th class="table-row-header sort-header" (click)="sortItem('supplier_name', 2)" st-sort="supplier_name">{{"SUPPLIER" | translate}}</th>
              <th class="table-row-header sort-header">{{"SUBSTITUTIONLIST_CANDIDATE" | translate}}</th>
              <th class="table-row-header sort-header"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let product of products | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber }; let i = index;">
                <td class="table-row-column">
                    <div class="flex flex-row">
                        <img class="h-4 w-4 mr-2 cursor-pointer" tooltips tooltip-smart="true" title="{{'EDIT' | translate}}" (click)="editSubList(i)" src="/images/icons/edit-svgrepo-com.svg"/>
                        <span class="whitespace-pre-line overflow-hidden">{{product.name}}</span>
                    </div>
                </td>
                <td class="table-row-column">
                <span class="whitespace-pre-line overflow-hidden">{{product.supplier_name}}</span>
                </td>
                <td class="table-row-column">
                    <div class="flex flex-row mr-2 mb-1" *ngFor="let sup_product of product.sup_products; let j = index">
                        <span>{{j + 1}}. {{sup_product.name}}, {{sup_product.supplier_name}}</span>
                        <svg *ngIf="editList" (click)="removeSubstituteSubProduct(i, sup_product)" class="h-4 w-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                            21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                            5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                            3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                            stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </td>
                <td class="table-row-column">
                    <svg (click)="removeSubstituteProduct(product.sareq_id)" class="h-4 w-4 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062
                        21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294
                        5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951
                        3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                        stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </td>
            </tr>
            <tr *ngIf="editList">
                <td colspan="4">
                    <app-sareq-search (dataOutput)="addProductToSubstituteList(productEditIndex, $event)"></app-sareq-search>
                </td>
            </tr>
          </tbody>
          <tfoot>
              <tr>
              <td class="p-6" colspan="4">
                  <div class="flex flex-row items-center">
                  <div class="static inline-block text-left mr-20" appDropdown>
                      <button type="button" class="button-gray">
                          <span class="text-sm">{{"SUBSTITUTIONS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                          <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                      </button>
                      <div id="dropdownMenu" class="hidden dropdown-menu-static bottom-10" #dropdownMenuInside>
                          <div class="py-1">
                              <a (click)="itemsPerPage=5;pageNumber=1" class="dropdown-menu-item" tabindex="1">5</a>
                              <a (click)="itemsPerPage=10;pageNumber=1" class="dropdown-menu-item" tabindex="2">10</a>
                              <a (click)="itemsPerPage=25;pageNumber=1" class="dropdown-menu-item" tabindex="3">25</a>
                          </div>
                      </div>
                  </div>
                  <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                      previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                  </div>
              </td>
              </tr>
          </tfoot>
        </table>
    </div>
</div>

<div class="flex flex-row justify-center mb-20">
    <button (click)="saveSubstitutions()" [disabled]="loadingButton" class="button-green" [class.cursor-not-allowed]="loadingButton==true">
        <ng-container *ngIf="!loadingButton; else loading">
            <img title="{{'SAVE' | translate}}" src="images/icons/save-svgrepo-com.svg" class="nav-home">
            <span class="text-white text-lg mr-1">{{ 'SAVE' | translate }}</span>
        </ng-container>
        <ng-template #loading>
            <svg class="w-6 h-6 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <span class="text-white text-lg mr-1">{{ 'SAVE' | translate }}</span>
        </ng-template>
    </button>
</div>
