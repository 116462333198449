<!-- Modal Background -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
    <!-- Modal Content -->
    <div class="relative top-10 mx-auto p-5 border w-full md:w-1/3 shadow-lg rounded-md bg-white mb-20">
        <div class="flex justify-end">
            <img class="w-6 h-6 cursor-pointer" title="{{'CANCEL' | translate}}" (click)="activeModal.close('Close click')" src="/images/icons/cross-svgrepo-com.svg" area-hidden="true" />
        </div>
        <div class="mt-3 text-left">
            <div class="text-center">
                <div class="text-xl text-gray-900 font-bold mb-4">{{product.product_name}}</div>
            </div>
            <!-- Modal Body -->
            <div class="text-center mb-4">
                <span class="text-xl font-bold">{{"PRINT_QR_DEPARTMENT" | translate}}</span><br>
            </div>
            <div class="flex flex-col justify-center items-start mt-4">
              <div class="flex flex-row items-center space-x-2 m-2" id="checkboxProductList1">
                <input title="lables" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" [checked]="printerqrcode" [(ngModel)]="printerqrcode" (ngModelChange)="printerqrcode = $event"
                    [ngModelOptions]="{standalone: true}" (change)="togglePrinterqrcodeCheckbox($event)">
                <span class="text-sm mr-1">{{"PRINT_LABELS" | translate}} {{"QRCODE_FOR_DEPARTMENT" | translate}}{{" (50x88)"}}</span>
              </div>
            <div class="flex flex-row justify-center">
                <button class="button-blue mt-10 mb-20" title="{{'PRINT' | translate}}" (click)="save()">
                    <img title="{{'PRINT' | translate}}" src="images/icons/print-svgrepo-com.svg" class="w-7 h-7">
                    <span class="text-lg">{{"PRINT" | translate}}</span>
                </button>
            </div>
        </div>
    </div>
</div>

