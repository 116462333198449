<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
    <div class="loader"></div>
  </div>

  <div *ngIf="loadingData==false" class="flex flex-col px-2 max-w-full mb-4">
    <div class="flex justify-between">
        <div class="flex flex-row">
            <p class="hidden md:block text-xl font-bold mt-6 px-4">{{"ACTIVITY_RISK_ASSESSMENTS" | translate}}</p>
            <button type="button" *ngIf="arbDBFilter" class="button-filter-yellow mt-4" (click)="removeFilter()" >
                <span class="text-lg">{{"FILTERED" | translate}}: {{arbDBFilterName}}</span>
            </button>
        </div>
        <button *ngIf="currentUser && ((currentUser.is_local_assignee || currentUser.is_local_admin
                || currentUser.is_admin || currentUser.is_useradmin) || currentUser.is_super_user)"
                (click)="addAssessment()" class="button-cdocblue mt-4">
            <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"/>
                <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
            <span class="text-white font-bold">{{"ADD_ACTIVITY_ASSESSMENT" | translate}}</span>
        </button>
    </div>
  </div>

<div *ngIf="!loadingData" class="px-4 py-2 mb-40 md:mb-20">
    <div class="overflow-x-auto">
        <table class="table-auto w-full bg-white mb-4 border border-gray-400" st-table="assessments" st-safe-src="assessments">
            <thead class="bg-white">
                <tr>
                    <th class="table-row-header-no-border mb-4" colspan="12">
                        <div class="flex flex-row items-center">
                            <div class="mt-4">
                                <input st-search="" type="search" [(ngModel)]="assessmentSearch.all" class="prompt"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 w-full" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                            </div>
                            <div class="flex items-center space-x-2 mt-4 py-2 px-4">
                                <input title="{{'SHOW_SUBDEPARTMENTS' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" name="example" [ngModel]="showSubdepartments" (click)="onToggleShowSubDepartments()">
                                <span class="text-sm mr-1">{{"SHOW_SUBDEPARTMENTS" | translate}}</span>
                            </div>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th class="table-row-header" (click)="sortHeader('heading')">{{"HEADING" | translate}}</th>
                    <th class="table-row-header" (click)="sortHeader('date')">{{"DATE" | translate}}</th>
                    <th class="table-row-header" >{{"DEPARTMENT" | translate}}</th>
                    <th class="table-row-header">{{"DOCUMENTS" | translate}}</th>
                    <th class="table-row-header" (click)="sortHeader('risk')">{{"RISK_LEVEL" | translate}}</th>
                    <th class="table-row-header" (click)="sortHeader('status')">{{"STATUS" | translate}}</th>
                    <th class="table-row-header">{{"RECOMMENDED_ACTION" | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let assessment of assessments
                    | filter : assessmentSearch.all
                    | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber }; let i=index">
                    <td class="table-row-column" (click)="assessmentSelected(assessment)">{{assessment.heading}}</td>
                    <td class="table-row-column" (click)="assessmentSelected(assessment)">{{assessment.arb_date | date:'yyyy-MM-dd'}}</td>
                    <td class="table-row-column" (click)="assessmentSelected(assessment)">{{departmentName(assessment.organisation_id)}}</td>
                    <td class="table-row-column">
                        <div *ngIf="assessment.riskfile" class="flex flex-row justify-center">
                            <button (click)="openArbInNewWindow(assessment, i)" [disabled]="loadingStates[i]==true" class="px-4 mb-2" [class.cursor-not-allowed]="loadingStates[i]==true">
                                <ng-container *ngIf="!loadingStates[i]; else loading">
                                  <div class="flex items-center">
                                    <img class="w-6 h-6 cursor-pointer" title="{{'ACTIVITY_RISK_ASSESSMENT' | translate}}" src="/images/icons/pdf-svgrepo-com.svg" area-hidden="true" />
                                  </div>
                                </ng-container>
                                <ng-template #loading>
                                  <div class="flex items-center">
                                    <svg class="w-6 h-6 mr-1 text-black animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                  </div>
                                </ng-template>
                            </button>
                        </div>
                    </td>
                    <td class="table-row-column" *ngIf="assessment.risk>0" (click)="assessmentSelected(assessment)">{{assessment.risk}}</td>
                    <td class="table-row-column" *ngIf="assessment.risk==0" (click)="assessmentSelected(assessment)"></td>
                    <td class="table-row-column" *ngIf="assessment.status==0" (click)="assessmentSelected(assessment)">{{"ONGOING" | translate}}</td>
                    <td class="table-row-column" *ngIf="assessment.status==1" (click)="assessmentSelected(assessment)">{{"FOR_REVIEW" | translate}}</td>
                    <td class="table-row-column" *ngIf="assessment.status==2" (click)="assessmentSelected(assessment)">{{"FOR_VERIFICATION" | translate}}</td>
                    <td class="table-row-column" *ngIf="assessment.status==3" (click)="assessmentSelected(assessment)">{{"APPROVED2" | translate}}</td>
                    <td class="table-row-column" *ngIf="assessment.substitution==1&&assessment.phasing_out==0&&assessment.risk_minimzation==0">{{"SUBSTITUTION" | translate}}</td>
                    <td class="table-row-column" *ngIf="assessment.phasing_out==1&&assessment.substitution==0&&assessment.risk_minimzation==0">{{"OUT_PHASING" | translate}}</td>
                    <td class="table-row-column" *ngIf="assessment.risk_minimzation==1&&assessment.substitution==0&&assessment.phasing_out==0">{{"RISK_MINIMISATION" | translate}}</td>
                    <td class="table-row-column" *ngIf="assessment.substitution==0&&assessment.phasing_out==1&&assessment.risk_minimzation==1">{{"OUT_PHASING" | translate}}, {{"RISK_MINIMISATION" | translate}}</td>
                    <td class="table-row-column" *ngIf="assessment.substitution==1&&assessment.phasing_out==0&&assessment.risk_minimzation==1">{{"SUBSTITUTION" | translate}}, {{"RISK_MINIMISATION" | translate}}</td>
                    <td class="table-row-column" *ngIf="assessment.substitution==1&&assessment.phasing_out==1&&assessment.risk_minimzation==0">{{"SUBSTITUTION" | translate}}, {{"OUT_PHASING" | translate}}</td>
                    <td class="table-row-column" *ngIf="assessment.substitution==1&&assessment.phasing_out==1&&assessment.risk_minimzation==1">{{"SUBSTITUTION" | translate}}, {{"OUT_PHASING" | translate}}, {{"RISK_MINIMISATION" | translate}}</td>
                    <td class="table-row-column" *ngIf="assessment.risk_minimzation==0&&assessment.phasing_out==0&&assessment.substitution==0"></td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td class="p-6" colspan="3">
                        <div class="static inline-block text-left" appDropdown>
                            <button type="button" class="button-gray">
                                <span class="text-sm">{{"RISK_ASSESSMENTS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            <div id="dropdownMenu" class="hidden dropdown-menu-static bottom-10" #dropdownMenuInside>
                                <div class="py-1">
                                    <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                    <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                    <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                            previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>


