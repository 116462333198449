import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { ClipboardModule } from 'ngx-clipboard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// used in tablesort
import { SortPipe } from './shared/pipes/sort.pipe';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';

// The first for filtering whole array with keys | filter : %%filteritem%%
// The second for specific keys | filter ['%%fieldname%%','%%fieldname2%%']: %%filteritem%%
// The third for arrays without keys | FilterPipe : %%filteritem%%
import { NgPipesModule } from 'ngx-pipes';
import { FilterPipe } from './shared/pipes/filterpipe.pipe';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './auth/login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FooterComponent } from './footer/footer.component';
import { NavigationComponent } from './navigation/navigation.component';
import { HelpComponent } from './settings/help/help.component';
import { ContactformComponent } from './settings/contactform/contactform.component';
import { UserProfileComponent } from './settings/user-profile/user-profile.component';
import { CompanyUsersComponent } from './settings/company-users/company-users.component';
import { EditUserComponent } from './settings/company-users/edit-user/edit-user.component';
import { ControlpanelComponent } from './controlpanel/controlpanel.component';
import { ManufacturerComponent } from './controlpanel/manufacturer/manufacturer.component';
import { ManufactureritemComponent } from './controlpanel/manufacturer/manufacturer-item/manufacturer-item.component';
import { SubstancesComponent } from './controlpanel/substances/substances.component';
import { EditSubstanceComponent } from './controlpanel/substances/edit-substance/edit-substance.component';
import { ModalSelectOrganisationComponent } from './modals/modal-select-organisation/modal-select-organisation.component';
import { SareqComponent } from './controlpanel/sareq/sareq.component';
import { DepartmentsComponent } from './settings/departments/departments.component';
import { ModalAddDepartmentComponent } from './modals/modal-add-department/modal-add-department.component';
import { ModalEditDepartmentComponent } from './modals/modal-edit-department/modal-edit-department.component';
import { ProductGroupComponent } from './settings/product-group/product-group.component';
import { ModalAddGroupComponent } from './modals/modal-add-group/modal-add-group.component';
import { ModalEditGroupComponent } from './modals/modal-edit-group/modal-edit-group.component';
import { CompanyInfoComponent } from './settings/company-info/company-info.component';
import { CompanyMiscComponent } from './settings/company-misc/company-misc.component';
import { EditSareqComponent } from './controlpanel/sareq/edit-sareq/edit-sareq.component';
import { CompanyArticlesComponent } from './settings/company-articles/company-articles.component';
import { StampedSdsComponent } from './settings/stamped-sds/stamped-sds.component';
import { AddSareqComponent } from './controlpanel/sareq/add-sareq/add-sareq.component';
import { RestrictionComponent } from './controlpanel/restriction/restriction.component';
import { ProductsComponent } from './products/products.component';
import { OrganisationComponent } from './controlpanel/organisation/organisation.component';
import { AddProductComponent } from './products/add-product/add-product.component';
import { ProductsEditComponent } from './products/products-edit-all/products-edit-all.component';
import { ProductItemComponent } from './products/product-item/product-item.component';
import { ProductapplicationAddComponent } from './productapplication/productapplication-add/productapplication-add.component';
import { ProductapplicationListComponent } from './productapplication/productapplication-list.component';
import { ProductapplicationItemComponent } from './productapplication/productapplication-item/productapplication-item.component';
import { ProductapplicationHistoryComponent } from './productapplication/productapplication-history/productapplication-history.component';
import { ProductapplicationAllComponent } from './productapplication/productapplication-all/productapplication-all.component';
import { ModalAddExcelComponent } from './modals/modal-add-excel/modal-add-excel.component';
import { ModalSelectProductDepartmentComponent } from './modals/modal-select-product-department/modal-select-product-department.component';
import { ModalPrintCargodeclarationComponent } from './modals/modal-print-cargodeclaration/modal-print-cargodeclaration.component';
import { ModalEditProductComponent } from './modals/modal-edit-product/modal-edit-product.component';
import { ModalUploadFileComponent } from './modals/modal-upload-file/modal-upload-file.component';
import { ModalOrderLabelsComponent } from './modals/modal-order-labels/modal-order-labels.component';
import { ModalGetQRDepartmentComponent } from './modals/modal-get-qr-department/modal-get-qr-department.component';
import { ModalSelectStatusComponent } from './modals/modal-select-status/modal-select-status.component';
import { OrganisationEditUserComponent } from './controlpanel/organisation/organisation-edit-user/organisation-edit-user.component';
import { MateriallistComponent } from './materiallist/materiallist.component';
import { AddMaterialComponent } from './materiallist/add-material/add-material.component';
import { EditMaterialComponent } from './materiallist/edit-material/edit-material.component';
import { ModalSendMailComponent } from './modals/modal-send-mail/modal-send-mail.component';
import { TransportinfoComponent } from './controlpanel/transportinfo/transportinfo.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportSimplifiedComponent } from './reports/report-simplified/report-simplified.component';
import { ReportInventoryComponent } from './reports/report-inventory/report-inventory.component';
import { ReportFireComponent } from './reports/report-fire/report-fire.component';
import { ReportCmrComponent } from './reports/report-cmr/report-cmr.component';
import { ReportNoteComponent } from './reports/report-note/report-note.component';
import { ReportSdsComponent } from './reports/report-sds/report-sds.component';
import { ReportGrbComponent } from './reports/report-grb/report-grb.component';
import { ReportClassificationComponent } from './reports/report-classification/report-classification.component';
import { ReportEnvironmentComponent } from './reports/report-environment/report-environment.component';
import { ReportSdsActivityComponent } from './reports/report-sds-activity/report-sds-activity.component';
import { ReportSllComponent } from './reports/report-sll/report-sll.component';
import { ReportKSimplifiedComponent } from './reports/report-k-simplified/report-k-simplified.component';
import { ReportArticleComponent } from './reports/report-article/report-article.component';
import { ReportAllergenicComponent } from './reports/report-allergenic/report-allergenic.component';
import { ReportSubstitutionComponent } from './reports/report-substitution/report-substitution.component';
import { ReportStyrenComponent } from './reports/report-styren/report-styren.component';
import { ReportSareqHistoryComponent } from './reports/report-sareq-history/report-sareq-history.component';
import { AssessmentsComponent } from 'src/app/assessments/assessments.component';
import { AddAssessmentComponent } from 'src/app/assessments/add-assessment/add-assessment.component';
import { EditAssessmentComponent } from 'src/app/assessments/edit-assessment/edit-assessment.component';
import { WorkstepComponent } from 'src/app/assessments/workstep/workstep.component';
import { AssessmentsChemicalComponent } from './assessments-chemical/assessments-chemical.component';
import { AssessmentChemicalEditComponent } from './assessments-chemical/assessment-chemical-edit/assessment-chemical-edit.component';
import { NewfreemiumComponent } from './controlpanel/newfreemium/newfreemium.component';
import { NewsComponent } from './controlpanel/news/news.component';
import { CoordinatorComponent } from './coordinator/coordinator.component';
import { ExposuresComponent } from './exposures/exposures.component';
import { HistoryComponent } from './history/history.component';
import { SubstitutionComponent } from './substitution/substitution.component';
import { EditExposureComponent } from './exposures/edit-exposure/edit-exposure.component';
import { AddExposureComponent } from './exposures/add-exposure/add-exposure.component';
import { AddCoordinatorComponent } from './coordinator/add-coordinator/add-coordinator.component';
import { EditCoordinatorComponent } from './coordinator/edit-coordinator/edit-coordinator.component';
import { EditSubstitutionComponent } from './substitution/edit-substitution/edit-substitution.component';
import { WorkstepSubstitutionComponent } from './substitution/workstep-substitution/workstep-substitution.component';
import { HistoryArticleComponent } from './history/history-article/history-article.component';
import { HistoryInventoryComponent } from './history/history-inventory/history-inventory.component';
import { HistorySimplifiedComponent } from './history/history-simplified/history-simplified.component';
import { ControlpanelcoordinatorComponent } from './controlpanel/controlpanelcoordinator/controlpanelcoordinator.component';
import { KebnekaiseComponent } from './controlpanel/kebnekaise/kebnekaise.component';
import { LawComponent } from './law/law.component';
import { CatalogueComponent } from './catalogue/catalogue.component';
import { CatalogueEditComponent } from './catalogue/catalogue-edit/catalogue-edit.component';
import { CustomeractionsComponent } from './controlpanel/customeractions/customeractions.component';
import { ModalSelectSubstitutionProductComponent } from './modals/modal-select-substitution-product/modal-select-substitution-product.component';
import { RegisterComponent } from './auth/register/register.component';
import { AllowDecimalSeparatorDirective } from './shared/allow-decimal-separator-directive';
import { CustomFilterPipe } from './shared/pipes/custom-filter-pipe.pipe';
import { DropdownDirective } from './shared/directives/dropdown.directive';
import { TopHeaderComponent } from './top-header/top-header.component';
import { DashboardAdminComponent } from './dashboard/dashboard-admin/dashboard-admin.component';
import { DashboardHistoryStatisticsComponent } from './dashboard/dashboard-history-statistics/dashboard-history-statistics.component';
import { DashboardYearlyReportComponent } from 'src/app/dashboard/dashboard-yearly-report/dashboard-yearly-report.component';
import { ProductItemRiskComponent } from './products/product-item-risk/product-item-risk.component';
import { OrganisationEditComponent } from './controlpanel/organisation/organisation-edit/organisation-edit.component';
import { SareqSearchComponent } from './controlpanel/sareq/sareq-search/sareq-search.component';
import { ModalSareqHistoryComponent } from './modals/modal-sareq-history/modal-sareq-history.component';
import { AssessmentPdfComponent } from './assessments/assessment-pdf/assessment-pdf.component';
import { PdfSubstitutionComponent } from './substitution/pdf-substitution/pdf-substitution.component';
import { SubstitutionProductsComponent } from './controlpanel/substitution-products/substitution-products.component';
import { SareqDashboardComponent } from './controlpanel/sareq/sareq-dashboard/sareq-dashboard.component';
import { environment } from 'src/environments/environment';
import * as Honeybadger from "@honeybadger-io/js";

// Configure honeybadger.js
if (environment.production == true)
{
  Honeybadger.configure({
    apiKey: "hbp_ZGA1ni3VxpxzJtxjqHhTXquIG6H0fK05OaCn",
    environment: "production"
  })

  // Define error handler component
  class HoneybadgerErrorHandler implements ErrorHandler {
    handleError(error) {
      Honeybadger.notify(error.originalError || error);
    }
  }
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    DashboardComponent,
    FooterComponent,
    NavigationComponent,
    HelpComponent,
    ContactformComponent,
    UserProfileComponent,
    CompanyUsersComponent,
    EditUserComponent,
    SortPipe,
    ControlpanelComponent,
    ManufacturerComponent,
    ManufactureritemComponent,
    SubstancesComponent,
    EditSubstanceComponent,
    FilterPipe,
    EditSubstanceComponent,
    ModalSelectOrganisationComponent,
    ModalSelectStatusComponent,
    SareqComponent,
    DepartmentsComponent,
    ModalAddDepartmentComponent,
    ModalEditDepartmentComponent,
    ProductGroupComponent,
    ModalAddGroupComponent,
    ModalEditGroupComponent,
    CompanyInfoComponent,
    CompanyMiscComponent,
    SareqComponent,
    EditSareqComponent,
    RestrictionComponent,
    CompanyArticlesComponent,
    StampedSdsComponent,
    AddSareqComponent,
    ProductsComponent,
    OrganisationComponent,
    AddProductComponent,
    ProductsEditComponent,
    ProductItemComponent,
    ProductapplicationAddComponent,
    ProductapplicationListComponent,
    ProductapplicationItemComponent,
    ProductapplicationHistoryComponent,
    ProductapplicationAllComponent,
    ModalAddExcelComponent,
    ModalSelectProductDepartmentComponent,
    ModalPrintCargodeclarationComponent,
    ModalEditProductComponent,
    ModalUploadFileComponent,
    ModalOrderLabelsComponent,
    ModalGetQRDepartmentComponent,
    OrganisationComponent,
    OrganisationEditUserComponent,
    MateriallistComponent,
    AddMaterialComponent,
    EditMaterialComponent,
    ModalSendMailComponent,
    TransportinfoComponent,
    ReportsComponent,
    ReportSimplifiedComponent,
    ReportInventoryComponent,
    ReportFireComponent,
    ReportCmrComponent,
    ReportNoteComponent,
    ReportSdsComponent,
    ReportGrbComponent,
    ReportClassificationComponent,
    ReportEnvironmentComponent,
    ReportSdsActivityComponent,
    ReportSllComponent,
    ReportKSimplifiedComponent,
    ReportArticleComponent,
    ReportAllergenicComponent,
    ReportSubstitutionComponent,
    ReportStyrenComponent,
    ReportSareqHistoryComponent,
    AssessmentsComponent,
    AssessmentsChemicalComponent,
    AddAssessmentComponent,
    EditAssessmentComponent,
    WorkstepComponent,
    AssessmentChemicalEditComponent,
    NewfreemiumComponent,
    NewsComponent,
    CoordinatorComponent,
    ExposuresComponent,
    HistoryComponent,
    SubstitutionComponent,
    EditExposureComponent,
    AddExposureComponent,
    AddCoordinatorComponent,
    EditCoordinatorComponent,
    EditSubstitutionComponent,
    WorkstepSubstitutionComponent,
    HistoryArticleComponent,
    HistoryInventoryComponent,
    HistorySimplifiedComponent,
    ControlpanelcoordinatorComponent,
    KebnekaiseComponent,
    LawComponent,
    CatalogueComponent,
    CatalogueEditComponent,
    CustomeractionsComponent,
    ModalSelectSubstitutionProductComponent,
    RegisterComponent,
    AllowDecimalSeparatorDirective,
    CustomFilterPipe,
    DropdownDirective,
    TopHeaderComponent,
    DashboardAdminComponent,
    DashboardHistoryStatisticsComponent,
    DashboardYearlyReportComponent,
    ProductItemRiskComponent,
    OrganisationEditComponent,
    SareqSearchComponent,
    ModalSareqHistoryComponent,
    AssessmentPdfComponent,
    PdfSubstitutionComponent,
    SubstitutionProductsComponent,
    SareqDashboardComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    ClipboardModule,
    NgPipesModule,
    HighchartsChartModule,
    NgbModule,
    FontAwesomeModule,
    DataTablesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [
    TranslateModule,
    FilterPipe
  ],

  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent],
  schemas:
        [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
