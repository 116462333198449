<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
    <div class="loader"></div>
</div>

<div *ngIf="loadingData==false" class="flex flex-col px-4 py-2 mb-4">
    <div class="flex justify-between">
        <div class="flex flex-col md:flex-row mt-6">
            <p class="font-bold text-lg" *ngIf="projects">{{"PROJECTS" | translate}}: {{projects?.length}}</p>
            <p *ngIf="!projects">{{"PROJECTS" | translate}}</p>
        </div>
        <button *ngIf="currentUser&&!currentUser.is_readonly_user" (click)="addProject()" class="button-cdocblue mt-4">
            <span class="text-white font-bold">{{"CREATE_PROJECT" | translate}}</span>
        </button>
    </div>
</div>

<div *ngIf="!loadingData&&projects!=null&&projects.length>0" class="px-4 py-2 mb-40 md:mb-20">
    <div class="overflow-x-auto">
        <table class="table-auto w-full bg-white mb-4 border border-gray-400" st-table="assessments" st-safe-src="assessments">
            <thead class="bg-white">
                <tr>
                    <th class="table-row-header-no-border" colspan="12">
                        <div class="flex flex-row">
                            <div class="mt-4">
                                <input st-search="" type="search" [(ngModel)]="projectSearch.all" class="prompt"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 w-full" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                            </div>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th class="table-row-header-no-border" colspan="12">
                        <div class="flex flex-col md:flex-row">
                            <div class="mb-4 mr-1">
                                <input st-search="" type="search" [(ngModel)]="projectSearch.name"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 md:w-full" placeholder="{{'SEARCH_NAME' | translate}} ..." />
                            </div>
                            <div class="mb-4 mr-1">
                                <input st-search="" type="search" [(ngModel)]="projectSearch.number"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 md:w-full" placeholder="{{'SEARCH_NUMBER' | translate}} ..." />
                            </div>
                            <div class="mb-4 mr-1">
                                <input st-search="" type="search" [(ngModel)]="projectSearch.issuer"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 md:w-full" placeholder="{{'SEARCH_ISSUER' | translate}} ..." />
                            </div>
                            <div class="mb-4 mr-1">
                                <input st-search="" type="search" [(ngModel)]="projectSearch.mailRecipient"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 md:w-full" placeholder="{{'SEARCH' | translate}} {{'RECIPIENT' | translate}} ..." />
                            </div>
                            <div class="mb-4">
                                <input st-search="" type="search" [(ngModel)]="projectSearch.mailDate"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 md:w-full" placeholder="{{'SEARCH' | translate}} {{'LAST_SENT' | translate}} ..." />
                            </div>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th class="table-row-header sort-header ascending" id="materiallistListTable1THCol1" (click)="sortHeader('project_date')">{{"DATE" | translate}}</th>
                    <th class="table-row-header sort-header ascending" id="materiallistListTable1THCol2" (click)="sortHeader('name')">{{"NAME" | translate}}</th>
                    <th class="table-row-header sort-header" id="materiallistListTable1THCol3" st-sort="number">{{"PROJECT_NUMBER" | translate}}</th>
                    <th class="table-row-header sort-header" id="materiallistListTable1THCol4"st-sort="issuer">{{"ISSUER" | translate}}</th>
                    <th class="table-row-header sort-header" id="materiallistListTable1THCol5"st-sort="mailRecipient">{{"RECIPIENT" | translate}}</th>
                    <th class="table-row-header sort-header" id="materiallistListTable1THCol6" st-sort="mailDate">{{"LAST_SENT" | translate}}</th>
                    <th class="table-row-header sort-header"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let project of projects
                    | filter : projectSearch.all
                    | filterBy  : ['name'] : projectSearch.name
                    | filterBy  : ['number'] : projectSearch.number
                    | filterBy  : ['issuer'] : projectSearch.issuer
                    | filterBy  : ['mailRecipient'] : projectSearch.mailRecipient
                    | filterBy  : ['mailDate'] : projectSearch.mailDate
                    | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber } let i=index;">
                    <td (click)="editMaterialList(project)" class="table-row-column" id="{{'materiallistListTable1TDCol1row'+i}}" (click)="projectSelected(project)">{{project.project_date | date:'yyyy-MM-dd'}}</td>
                    <td (click)="editMaterialList(project)" class="table-row-column" id="{{'materiallistListTable1TDCol2row'+i}}" (click)="projectSelected(project)">{{project.name}}</td>
                    <td (click)="editMaterialList(project)" class="table-row-column" id="{{'materiallistListTable1TDCol3row'+i}}" (click)="projectSelected(project)">{{project.number}}</td>
                    <td (click)="editMaterialList(project)" class="table-row-column" id="{{'materiallistListTable1TDCol4row'+i}}" (click)="projectSelected(project)">{{project.issuer}}</td>
                    <td (click)="editMaterialList(project)" class="table-row-column" id="{{'materiallistListTable1TDCol5row'+i}}" (click)="projectSelected(project)">{{project.mailRecipient}}</td>
                    <td (click)="editMaterialList(project)" class="table-row-column" id="{{'materiallistListTable1TDCol6row'+i}}" (click)="projectSelected(project)">{{project.mailDate | date:'yyyy-MM-dd'}}</td>
                    <td (click)="editMaterialList(project)" class="table-row-column">
                        <div class="flex flex-col" (click)="copyMaterialList(project)" tooltips tooltip-smart="true" title="{{'COPY' | translate}} {{'REPORT_BOM' | translate}}">
                            <img class="w-6 h-6" title="{{'COPY' | translate}}" src="/images/icons/copy-svgrepo-com.svg" area-hidden="true" />
                        </div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td class="p-6" colspan="2">
                        <div class="static inline-block text-left" appDropdown>
                            <button type="button" class="button-gray">
                                <span class="text-sm">{{"PROJECTS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            <div id="dropdownMenu" class="hidden dropdown-menu-static bottom-10" #dropdownMenuInside>
                                <div class="py-1">
                                    <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                    <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                    <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                            previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>
