<div *ngIf="loadingData" class="flex justify-center items-center mt-4 mb-4">
  <div class="loader"></div>
</div>

<div class="flex flex-col px-4 max-w-full py-2 mb-4 border border-gray-200">
    <div class="flex flex-row">
        <button class="px-4 py-2" title="{{'BACK' | translate}}" (click)="gotoControlPanel()">
          <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
            <path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
            <path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
          </svg>
        </button>
    </div>
</div>

<div class="mt-4 px-4 py-2 mb-20">
  <div class="overflow-x-auto ">
      <table class="table-auto bg-white mb-4 w-full" st-table="displayedCompanys" st-safe-src="displayedCompanys">
      <thead>
          <tr>
          <th class="table-row-header-no-border" colspan="12">
            <div class="flex flex-row items-center">
                <div>
                    <input st-search="" type="search" [(ngModel)]="companySearch.all" class="prompt"
                        class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 w-full" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                </div>
            </div>
          </th>
          </tr>
          <tr>
            <th class="table-row-header sort" (click)="tableSort('register_time',0)" st-sort="register_time" >{{"REGISTERED" | translate}}</th>
            <th class="table-row-header sort" (click)="tableSort('company_name',1)" st-sort="company_name">{{"NAME" | translate}}</th>
            <th class="table-row-header sort" (click)="tableSort('contact_person_lastname',2)" >{{"CONTACT" | translate}}</th>
            <th class="table-row-header sort">{{"ACCEPT" | translate}}</th>
          </tr>
      </thead>
      <tbody>
        <tr *ngFor="let company of displayedCompanys
            | filter : companySearch.all
            | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };">
              <td class="table-row-column">{{company.register_time | date:'yyyy-MM-dd HH:mm'}}</td>
              <td class="table-row-column">{{company.company_name}}</td>
              <td class="table-row-column">{{company.contact_person_lastname}}<br>{{company.contact_person_email}}<br>{{company.contact_person_phone}}</td>
              <td class="table-row-column">
                <div class="flex flex-row">
                  <button (click)="$event.stopPropagation(); createNewCompany(company)" class="button button-with-green-border mr-1">
                    <img class="w-6 h-6" title="{{'CREATE_CUSTOMER' | translate}}" src="/images/icons/check-mark-svgrepo-com.svg"/>
                  </button>
                  <button *ngIf="!company.removeCompany" (click)="$event.stopPropagation(); removeNewCompany(company)" class="button button-with-red-border">
                    <img class="w-6 h-6" title="{{'REMOVE_CUSTOMER' | translate}}" src="/images/icons/delete-trash-svgrepo-com.svg"/>
                  </button>
                </div>
              </td>
          </tr>
      </tbody>
      <tfoot>
          <tr>
          <td class="p-6" colspan="4">
              <div class="flex flex-row items-center">
              <div class="static inline-block text-left mr-20" appDropdown>
                  <button type="button" class="button-gray">
                      <span class="text-sm">{{"COMPANY" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                      <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                  </button>
                  <div id="dropdownMenu" class="hidden dropdown-menu-static bottom-10" #dropdownMenuInside>
                      <div class="py-1">
                          <a (click)="itemsPerPage=5;pageNumber=1" class="dropdown-menu-item" tabindex="1">5</a>
                          <a (click)="itemsPerPage=10;pageNumber=1" class="dropdown-menu-item" tabindex="2">10</a>
                          <a (click)="itemsPerPage=25;pageNumber=1" class="dropdown-menu-item" tabindex="3">25</a>
                      </div>
                  </div>
              </div>
              <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                  previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
              </div>
          </td>
          </tr>
      </tfoot>
      </table>
  </div>
</div>
