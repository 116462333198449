<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
  <div class="loader"></div>
</div>

<div *ngIf="loadingData==false" class="flex flex-col px-4 max-w-full py-2">
  <div class="flex justify-between">
    <button (click)="newProductApplication()" class="button-cdocblue mt-4">
      <span class="text-white text-lg">{{"START_PRODUCT_APPLICATION" | translate}}</span>
    </button>
    <button (click)="historyProductApplication()" class="button-white mt-4">
        <span class="hidden md:block nav-home-text text-lg">{{"HISTORY" | translate}}</span>
    </button>
  </div>
</div>

<div *ngIf="(!loadingData && (!productApplications || productApplications.length == 0))" class="flex flex-col bg-gray-200 border border-gray-400 px-7 m-4 py-2">
  <div class="text-lg font-bold">
    {{"NO_PRODUCTAPPLICATION_HEADER" | translate}}
  </div>
  <div class="mt-4">
    {{"NO_PRODUCTAPPLICATION_MESSAGE_1" | translate}}<br/>
    {{"NO_PRODUCTAPPLICATION_MESSAGE_2" | translate}}
  </div>
</div>

<div class="overflow-auto">
  <div *ngIf="( !loadingData && productApplications ) " class="px-4 py-2 mb-20">
      <table class="table-auto w-full bg-white mb-4 border border-gray-400">
          <thead class="bg-white">
              <tr>
                  <th class="table-row-header-no-border" colspan="12">
                      <div class="flex flex-col md:flex-row md:items-center">
                          <div class="mt-4">
                              <input st-search="" type="search" [(ngModel)]="productapplicationSearch.all" class="prompt" (input)="filterSearchItems()"
                                  class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 md:w-full" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                          </div>
                          <div class="flex items-center space-x-2 mt-4 py-2 px-4">
                            <input title="{{'SHOW_SUBDEPARTMENTS' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" name="example" [ngModel]="showSubdepartments" (click)="onToggleShowSubDepartments()">
                            <span class="text-sm mr-1">{{"SHOW_SUBDEPARTMENTS" | translate}}:</span>
                          </div>
                      </div>
                  </th>
              </tr>
              <tr>
                <th class="table-row-header" (click)="sortProductsApplications('name',0)">{{"PRODUCT" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('department_id',1)">{{"DEPARTMENT" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('created_date',2)">{{"DATE" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('updated_date',3)">{{"LAST_UPDATED" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('supplier_name',5)">{{"SUPPLIER" | translate}}</th>
                <th class="table-row-header">{{"DOCUMENTS" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('username',7)">{{"ORIGINATOR" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('status',8)">{{"STATUS" | translate}}</th>
                <th class="table-row-header" (click)="sortProductsApplications('approval_date',4)">{{"APPROVAL_DATE" | translate}}</th>
              </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let productApplication of productApplications | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };let i = index">
              <tr>
                <td (click)="gotoProductApplicationItem(productApplication)" class="table-row-column" [ngStyle]="{'background-color':productApplication.in_organisation=='1' ? '#DAF7A6' : '' }">{{productApplication.name}}</td>
                <td (click)="gotoProductApplicationItem(productApplication)" class="table-row-column" *ngIf="showSubdepartments" title="{{productApplication.departmentsNames}}" [ngStyle]="{'background-color':productApplication.in_organisation=='1' ? '#DAF7A6' : '' }">{{productApplication.departmentsDisplayed}}</td>
                <td (click)="gotoProductApplicationItem(productApplication)" class="table-row-column" *ngIf="!showSubdepartments" [ngStyle]="{'background-color':productApplication.in_organisation=='1' ? '#DAF7A6' : '' }">{{selectedDepartment.name}}</td>
                <td (click)="gotoProductApplicationItem(productApplication)" class="table-row-column" [ngStyle]="{'background-color':productApplication.in_organisation=='1' ? '#DAF7A6' : '' }">{{productApplication.created_date | date:'yyyy-MM-dd'}}</td>
                <td (click)="gotoProductApplicationItem(productApplication)" class="table-row-column" [ngStyle]="{'background-color':productApplication.in_organisation=='1' ? '#DAF7A6' : '' }">{{productApplication.updated_date | date:'yyyy-MM-dd'}}</td>
                <td (click)="gotoProductApplicationItem(productApplication)" class="table-row-column" [ngStyle]="{'background-color':productApplication.in_organisation=='1' ? '#DAF7A6' : '' }">{{productApplication.supplier_name}}</td>
                <td class="table-row-column" *ngIf="productApplication.sareq_id!=null" [ngStyle]="{'background-color':productApplication.in_organisation=='1' ? '#DAF7A6' : '' }">
                  <div class="flex flex-row">
                    <img class="-mr-3" *ngIf="( productApplication.esdb_available==0 || ( productApplication.esdb_file_id!='' && productApplication.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_DATA_SHEET' | translate}}" id="SDBIcon" src="/images/icons/SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(productApplication)" />
                    <img class="-mr-3" *ngIf="( productApplication.esdb_available==1 && ( productApplication.esdb_file_id=='' || productApplication.esdb_file_id==null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openSdsInNewWindow(productApplication)" />
                    <img class="-mr-3" *ngIf="( productApplication.esdb_available==1 && ( productApplication.esdb_file_id!='' && productApplication.esdb_file_id!=null ))" tooltips tooltip-smart="true" title="{{'SAFETY_EXT_DATA_SHEET' | translate}}" id="EXTSDBICon" src="/images/icons/EXT_SDB.svg" area-hidden="true" (click)="openExtSdsInNewWindow(productApplication)" />
                    <img *ngIf="productApplication.risk_prio" tooltips tooltip-smart="true" title="{{'GENERAL_RISK_ASSESSMENT' | translate}}" id="GRBIcon" src="/images/icons/GRB.svg" area-hidden="true" (click)="openGrbInNewWindow(productApplication)" />
                  </div>
                </td>
                <td class="table-row-column" *ngIf="productApplication.sareq_id==null" [ngStyle]="{'background-color':productApplication.in_organisation=='1' ? '#DAF7A6' : '' }">-</td>
                <td class="table-row-column" [ngStyle]="{'background-color':productApplication.in_organisation=='1' ? '#DAF7A6' : '' }"
                    tooltips tooltip-smart="true"
                    title="{{productApplication.first_name+
                    ' '+productApplication.last_name+'\n'+
                    productApplication.email+'\n'+
                    productApplication.phone}}">
                    {{productApplication.username}}</td>
                <td class="table-row-column" [ngStyle]="{'background-color':productApplication.in_organisation=='1' ? '#DAF7A6' : '' }">{{getStatus(productApplication.status)}}</td>
                <td class="table-row-column" [ngStyle]="{'background-color':productApplication.in_organisation=='1' ? '#DAF7A6' : '' }">{{productApplication.approval_date | date:'yyyy-MM-dd'}}</td>
              </tr>
            </ng-container>
          </tbody>
          <tfoot>
              <tr>
                  <td class="p-6" colspan="2">
                      <div class="static inline-block text-left" appDropdown>
                          <button type="button" class="button-gray">
                              <span class="text-sm">{{"PRODUCTS" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                              <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                          </button>
                          <div id="dropdownMenu" class="hidden dropdown-menu-static bottom-10" #dropdownMenuInside>
                              <div class="py-1">
                                  <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                  <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                  <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                  <a (click)="itemsPerPage=100;pageNumber=1" id="productListItemsPerPage100" class="dropdown-menu-item" tabindex="4">100</a>
                              </div>
                          </div>
                      </div>
                  </td>
                  <td colspan="4">
                      <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                          previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                  </td>
              </tr>
          </tfoot>
      </table>
  </div>
</div>

