
<div *ngIf="loadingData==true" class="flex justify-center items-center mt-4">
    <div class="loader"></div>
</div>

<div [hidden]="loadingData" class="flex flex-col px-4 max-w-full py-2 mb-4 border border-gray-200">
    <div class="flex justify-between">
        <div class="flex mt-4">
            <p class="text-lg font-bold mt-4">{{"EXPOSURE_LIST" | translate}}: {{exposures?.length}}</p>
        </div>
        <button (click)="addExposure()" class="button-cdocblue mt-4">
            <svg class="h-7 w-7 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"/>
                <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
            <span class="text-white text-lg">{{"ADD" | translate}}</span>
        </button>
    </div>
</div>

<div *ngIf="loadingData==false" class="mt-4 px-4 py-2 mb-20">
    <div class="overflow-x-auto ">
        <table *ngIf="exposures.length > 0" class="table-auto w-full bg-white mb-4 border border-gray-400" id="exposuretable">
            <thead class="bg-white">
                <tr>
                    <th class="table-row-header-no-border mb-4" colspan="12">
                        <div class="flex flex-row items-center">
                            <div class="mt-4">
                                <input st-search="" type="search" [(ngModel)]="exposureSearch.all" class="prompt"
                                    class="flex items-center py-2 px-4 rounded-full prompt border border-gray-400 w-full" placeholder="{{'SEARCH_ALL' | translate}} ..." />
                            </div>
                            <div class="flex items-center space-x-2 mt-4 py-2 px-4">
                                <input title="{{'SHOW_SUBDEPARTMENTS' | translate}}" class="form-checkbox h-5 w-5 text-cdocblue" type="checkbox" id="checkboxProductList2checkbox" name="example" [ngModel]="includeSubDepartments" (click)="onToggleShowSubDepartments()">
                                <span class="text-sm mr-1">{{"SHOW_SUBDEPARTMENTS" | translate}}</span>
                            </div>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th class="table-row-header" (click)="sortHeader('name')">{{"NAME" | translate}}</th>
                    <th class="table-row-header" (click)="sortHeader('id_number')">{{"PERSONAL_CODE_NUMBER" | translate}}</th>
                    <th class="table-row-header" (click)="sortHeader('department')">{{"DEPARTMENT" | translate}}</th>
                    <th class="table-row-header" (click)="sortHeader('chem_name')">{{"CHEMICAL" | translate}}</th>
                    <th class="table-row-header" (click)="sortHeader('supplier_name')">{{"MANUFACTURER" | translate}}</th>
                    <th class="table-row-header" (click)="sortHeader('supplier_article_number')">{{"ARTICLE_NUMBER" | translate}}</th>
                    <th class="table-row-header">{{"TIME_PER_MOMENT" | translate}}</th>
                    <th class="table-row-header">{{"INTERVAL" | translate}}</th>
                    <th class="table-row-header"  (click)="sortHeader('start')">{{"START_DATE" | translate}}</th>
                    <th class="table-row-header">{{"END_DATE" | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let exposure of exposures
                    | filter : exposureSearch.all
                    | paginate: { 'itemsPerPage': itemsPerPage, 'currentPage': pageNumber };" (click)="exposureSelected(exposure)">
                    <td class="table-row-column">{{exposure.name}}</td>
                    <td class="table-row-column">{{exposure.id_number}}</td>
                    <td class="table-row-column">{{getLastDepartmentName(departFullName(exposure.department))}}</td>
                    <td class="table-row-column">{{exposure.chem_name}}</td>
                    <td class="table-row-column">{{exposure.supplier_name}}</td>
                    <td class="table-row-column">{{exposure.supplier_article_number}}</td>
                    <td class="table-row-column">{{exposure.exp_per_moment}}</td>
                    <td class="table-row-column">{{exposure.exp_times}}</td>
                    <td class="table-row-column">{{exposure.start | date:'yyyy-MM-dd'}}</td>
                    <td *ngIf="exposure.end_not_defined==1" class="table-row-column">{{"END_NOT_DEFINED" | translate}}</td>
                    <td *ngIf="exposure.end_not_defined!=1" class="table-row-column">{{exposure.end | date:'yyyy-MM-dd'}}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td class="p-6" colspan="3">
                        <div class="static inline-block text-left" appDropdown>
                            <button type="button" class="button-gray">
                                <span class="text-sm">{{"REGISTERED_EXPOSURES" | translate}} / {{"PAGE" | translate}}: {{itemsPerPage}}</span>
                                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            <div id="dropdownMenu" class="hidden dropdown-menu-static bottom-10" #dropdownMenuInside>
                                <div class="py-1">
                                    <a (click)="itemsPerPage=5;pageNumber=1" id="productListItemsPerPage1" class="dropdown-menu-item" tabindex="1">5</a>
                                    <a (click)="itemsPerPage=10;pageNumber=1" id="productListItemsPerPage10" class="dropdown-menu-item" tabindex="2">10</a>
                                    <a (click)="itemsPerPage=25;pageNumber=1" id="productListItemsPerPage25" class="dropdown-menu-item" tabindex="3">25</a>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <pagination-controls (pageBoundsCorrection)="pageNumber = 1" (pageChange)="pageNumber = $event" responsive="true"
                            previousLabel="{{'PREVIOUS' | translate}}" nextLabel="{{'NEXT' | translate}}"></pagination-controls>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>


